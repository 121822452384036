import Box from "@mui/material/Box";
import { useSelectorApp } from "../../lib/redux";
import CreditsCounter from "../Texts/CreditsCounter";
// import CoinsCounter from "../Texts/CoinsCounter";

const UsageCounter = () => {
  const subscriptionPlanInfo = useSelectorApp(
    (state) => state.payments.subscriptionPlanInfo
  );
  const usage = subscriptionPlanInfo?.usage || {};
  // const coins = usage?.coins || 0;
  const credits = usage?.credits || 0;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: 1.25,
        gap: 3,
      }}
    >
      <CreditsCounter
        credits={credits}
        variant="body1"
        heightIcon="18px"
        widthIcon="18px"
        sxTypography={{
          fontWeight: "bold",
        }}
        sxIcon={{ marginTop: 3 }}
      />
      {/* <CoinsCounter
        coins={coins}
        variant="body1"
        heightIcon="18px"
        widthIcon="18px"
        sxTypography={{
          fontWeight: "bold",
        }}
        sxIcon={{ marginTop: 4 }}
      /> */}
    </Box>
  );
};

export default UsageCounter;
