import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  EMPTY_RESULTS_SHOW,
  FIELD_REQUIRED,
  LOADING,
  TYPE_LEAST_CHARACTERS,
} from "../../i18n/keysTranslations";
import { Controller, useController } from "react-hook-form";
import i18next from "i18next";
import Typography from "@mui/material/Typography";
import { useDispatchApp } from "../../lib/redux";
import { useTranslationApp } from "../../lib/i18next";
import { useDebounced } from "../../hooks/useDebounce";
import { startGetAdsCampaignSegmentation } from "../../actions/adsCampaigns";
import {
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE,
  DELAY_DEBOUNCE,
  META,
} from "../../utils/constants";
import { getReactHookFormNestedError } from "../../utils/errors";
import Tooltip from "@mui/material/Tooltip";

const SearchSegmentationsField = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  groupBy,
  orderBy = "asc",
  disabled = false,
  placeholder,
  variant = "filled",
  fullWidth,
  helperText,
  type = "interests",
  mode = "fetching",
  platform,
  integrationID,
  adAccountID,
  objective,
  language,
  doChange = true,
  sx = {},
  actionsChange = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const error = getReactHookFormNestedError(errors, name);
  const {
    field: { value: currentValue, onChange: onChangeField },
  } = useController({ name, control });

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();

  // Get value by path
  const getValueByPath = (obj, path) => {
    return path.reduce((acc, key) => acc[key], obj);
  };

  // Sort by groupBy
  const sortByGroupBy = () => {
    const newOptions = [...options];
    return newOptions.sort((a, b) => {
      for (let path of groupBy) {
        const aValue = getValueByPath(a, Array.isArray(path) ? path : [path]);
        const bValue = getValueByPath(b, Array.isArray(path) ? path : [path]);

        if (aValue > bValue) {
          return orderBy === "asc" ? 1 : -1;
        }
        if (aValue < bValue) {
          return orderBy === "asc" ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const getTextExtended = (option) => {
    if (type === ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE) {
      return (
        `${option?.region ? `${option?.region},` : ""} ${
          option?.country_name
        }` || option?.region
      );
    }

    return option?.path?.[0];
  };

  const getAdsCampaignSegmentation = useDebounced(
    async (textQuery) => {
      if (!integrationID || !adAccountID) return;
      if (mode === "load" && options?.length > 0) return;
      setLoading(true);
      const response = await dispatch(
        startGetAdsCampaignSegmentation({
          keyWord: textQuery,
          type,
          platform,
          objective,
          language,
          integrationID,
          adAccountID,
        })
      );
      if (response) {
        setOptions((prevState) => {
          //filter out the options that are already in the state
          const newOptions = response.filter(
            (option) =>
              !prevState.some((prevOption) => prevOption.name === option.name)
          );

          return [...prevState, ...newOptions];
        });
      }
      setLoading(false);
    },
    [DELAY_DEBOUNCE]
  );

  useEffect(() => {
    if (disabled) return;
    // eslint-disable-next-line
  }, [mode, disabled]);

  useEffect(() => {
    if (!groupBy) return;

    const sortedOptions = sortByGroupBy();
    if (sortedOptions.length !== options.length) {
      setOptions(sortedOptions);
    }
    // eslint-disable-next-line
  }, [options, groupBy]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      rules={rules}
      render={({ field }) => {
        const { key: someKey, ...rest } = field;
        return (
          <Autocomplete
            {...rest}
            key={someKey}
            sx={sx}
            fullWidth={fullWidth}
            disabled={disabled}
            open={open}
            multiple={true}
            groupBy={(option) => {
              if (!groupBy) return;
              const hasMoreOneGroup = groupBy.length > 1;

              if (!hasMoreOneGroup) return option[groupBy[0]];

              return option[groupBy[0]][groupBy[1]];
            }}
            onOpen={() => {
              if (!currentValue || currentValue?.length === 0)
                onChangeField([]);
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(_, value) => {
              if (doChange) field.onChange(value);
              actionsChange(value);
            }}
            onInputChange={(_, value) => {
              if (value.length > 2) {
                getAdsCampaignSegmentation(value);
              }
            }}
            noOptionsText={t(
              options.length === 0 ? TYPE_LEAST_CHARACTERS : EMPTY_RESULTS_SHOW,
              {
                value: 3,
              }
            )}
            loadingText={t(LOADING)}
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name
            }
            getOptionLabel={(option) => `${option?.name}`}
            options={options}
            loading={loading}
            renderOption={(props, option) => {
              const { key: someKey, ...rest } = props;
              return (
                <Box
                  {...rest}
                  key={someKey}
                  component="li"
                  sx={{ display: "flex", gap: 1 }}
                >
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "fields.placeholder",
                      fontStyle: "italic",
                    }}
                  >
                    {getTextExtended(option)}
                  </Typography>
                </Box>
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                if (
                  platform === META &&
                  type === ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE
                ) {
                  return (
                    <Tooltip
                      key={key}
                      title={
                        option?.disambiguation_category ||
                        option?.topic ||
                        option?.description
                      }
                    >
                      <Chip label={option.name} {...tagProps} />
                    </Tooltip>
                  );
                }
                return <Chip key={key} label={option.name} {...tagProps} />;
              })
            }
            renderInput={(params) => {
              const { key: someKey, ...rest } = params;
              return (
                <TextField
                  {...rest}
                  key={someKey}
                  label={label}
                  variant={variant}
                  placeholder={field?.value?.length > 0 ? "" : placeholder}
                  error={!!error}
                  helperText={
                    <Box
                      component="span"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        variant="caption"
                        dangerouslySetInnerHTML={{
                          __html: !!error ? error?.message : helperText,
                        }}
                      ></Typography>
                    </Box>
                  }
                  sx={{
                    ".MuiFormHelperText-root": {
                      ml: "0 !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default SearchSegmentationsField;
