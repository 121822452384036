import ButtonLinkedinAction from "../../../components/Buttons/ButtonLinkedinAction";
import ContainerPaperSection from "../../../components/Containers/ContainerPaperSection";
import GoogleContainerIntegration from "../../../components/Google/GoogleContainerIntegration";
import MetaContainerIntegration from "../../../components/Meta/MetaContainerIntegration";
import TikTokBusinessContainerIntegration from "../../../components/TikTokBusiness/TikTokBusinessContainerIntegration";
import {
  COMING_SOON,
  PLATFORMS_CONNECTED_TITLE,
} from "../../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../../lib/i18next";

const ConnectedAccounts = () => {
  const { t } = useTranslationApp();
  return (
    <ContainerPaperSection
      title={PLATFORMS_CONNECTED_TITLE}
      showTopButton={false}
      showBottomButton={false}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="platforms-connect-section"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <MetaContainerIntegration
          sx={{
            width: 205,
          }}
        />
        <TikTokBusinessContainerIntegration
          sx={{
            width: 205,
          }}
        />
        <GoogleContainerIntegration
          sx={{
            width: 205,
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ButtonLinkedinAction
            sx={{
              width: 205,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: "GrayText",
              fontStyle: "italic",
            }}
          >
            {t(COMING_SOON)}
          </Typography>
        </Box>
      </Box>
    </ContainerPaperSection>
  );
};

export default ConnectedAccounts;
