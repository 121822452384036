import { useEffect, useState } from "react";
import { Controller, useController } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../i18n/keysTranslations";
import { getReactHookFormNestedError } from "../../utils/errors";
import { useSelectorApp } from "../../lib/redux";

const SelectPageFacebook = ({
  control,
  errors = {},
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled = false,
  placeholder,
  variant = "filled",
  fullWidth,
  helperText,
  integrationID,
  adAccountID,
  onChangeAction = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const error = getReactHookFormNestedError(errors, name);
  const {
    field: { value: fieldValue, onChange: fieldOnChange },
  } = useController({
    name,
    control,
  });
  const { isLoading, integrations } = useSelectorApp((state) => state.user);
  const metaIntegrations = integrations?.facebook;
  const integration = metaIntegrations?.[integrationID] || {};

  useEffect(() => {
    const { pages = {} } = integration || {};
    const pagesKeys = Object.keys(pages);
    const pagesAccount = pagesKeys.map((key) => ({
      ...pages[key],
      integrationID: integration.id,
    }));
    setOptions(pagesAccount);

    if (!fieldValue && pagesAccount?.length > 0) {
      fieldOnChange(pagesAccount[0].id);
      onChangeAction(pagesAccount[0]);
    }

    // eslint-disable-next-line
  }, [integration, integrationID, adAccountID]);

  if (isLoading) return null;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={options?.length > 0 ? field.value : ""}
          fullWidth={fullWidth}
          disabled={disabled}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value) => {
            field.onChange(value?.id);
            onChangeAction(value);
          }}
          isOptionEqualToValue={(option, value) => {
            if (value?.id) {
              return option?.id === value?.id;
            }
            return option?.id === value;
          }}
          getOptionLabel={(option) => {
            const optionValue = options.find((opt) => opt.id === option);
            if (optionValue) {
              return `${optionValue?.name}`;
            }
            if (typeof option === "string") {
              return option;
            }
            return `${option?.name}`;
          }}
          options={options}
          disableClearable={true}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                key={option.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Avatar
                  src={option.imageURL}
                  alt={option.name}
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
                <Typography variant="body1">{option.name}</Typography>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!error}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{!!error ? error?.message : helperText}</span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectPageFacebook;
