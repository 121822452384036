export const getMissingValuesBetweenArrays = ({
  currentArray = [],
  targetArray = [],
}) => {
  return targetArray.filter((item) => !currentArray.includes(item));
};

export const getDuplicateItemsArray = (array) => {
  const countMap = {};
  const duplicates = [];

  // Contamos las ocurrencias de cada elemento
  array.forEach((item) => {
    countMap[item] = (countMap[item] || 0) + 1;
  });

  // Filtramos los duplicados (elementos con más de una ocurrencia)
  for (const [key, count] of Object.entries(countMap)) {
    if (count > 1) {
      duplicates.push(key);
    }
  }

  return duplicates;
};
