import { useEffect, useRef } from "react";

const useResizeItemsWithSameHeight = () => {
  const refs = useRef([]);

  const setEqualHeights = () => {
    refs.current.forEach((el) => {
      if (el) el.style.height = "auto";
    });
    const maxHeight = Math.max(
      ...refs.current.map((el) => el?.offsetHeight || 0)
    );

    refs.current.forEach((el) => {
      if (el) el.style.height = `${maxHeight}px`;
    });
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setEqualHeights();
    });

    const container = document.querySelector("#root");
    if (container) observer.observe(container);

    return () => observer.disconnect();
  }, []);

  return refs;
};

export default useResizeItemsWithSameHeight;
