import Box from "@mui/material/Box";
const ContainerOverlay = ({ children, sx }) => {
  return (
    <Box
      className="overlay"
      sx={{
        position: "absolute",
        background: "rgba(0, 0, 0, 0.4)",
        width: "100%",
        opacity: 0,
        transition: ".3s",
        px: 1,
        py: 0.5,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerOverlay;
