import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  ACTIVE,
  AD,
  CALL_TO_ACTION_PLACEHOLDER,
  CARD,
  CONTENT,
  DEACTIVATED,
  DESCRIPTION_PLACEHOLDER,
  DESCRIPTIONS,
  DESTINATION_URL_PLACEHOLDER,
  FIELD_MAX_LENGTH,
  FIELD_MIN_CARDS_AD_CAROUSEL,
  FIELD_REQUIRED,
  FIELD_TEXT_ONLY_HAS_LETTERS_NUMBERS_DOTS_COMMAS,
  FIELD_TEXT_REPEATED,
  FIELD_URL_VALID,
  HEADLINE_PLACEHOLDER,
  HEADLINES,
  IS_CAROUSEL,
  NUMBER_ITEM,
  TITLE,
  TITLE_PLACEHOLDER,
  TITLES,
} from "../../../i18n/keysTranslations";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
  ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_TYPE_GALLERY_ADS_PLATFORMS_POSTS,
  GOOGLE,
  IMAGE_FORMATS,
  META,
  MIN_DIMENSIONS_CONTENT,
  TIKTOK,
  VIDEO_FORMATS,
} from "../../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp } from "../../../lib/redux";
import {
  generateAdsCampaignDescription,
  startDeleteAdMediaID,
} from "../../../actions/adsCampaigns";
import Accordion from "../../../components/Containers/Accordion";
import Switch from "../../../components/Form/Switch";
import SelectCallToActionAds from "../../../components/Form/SelectCallToActionAds";
import TextField from "../../../components/Form/TextField";
import Loader from "../../../components/Loaders/Loader";
import { getUniqueID } from "../../../utils/numbers";
import { getReactHookFormNestedError } from "../../../utils/errors";
import AdPreview from "./AdPreview";
import { getIsBlockedActions } from "../../../actions/getters";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  generalFormatMediaUrl,
  getAdsTextsFormatted,
  getIsCarouselAdIsAvailable,
  getIsSpecialAdWithOnlyTitles,
  getIsSpecialAdWithoutCallToAction,
} from "../../../services/adsCampaigns";
import ContentField from "../../../components/Form/ContentField";
import { REGEX_TITLE_GOOGLE, REGEX_URL } from "../../../utils/regex";

const maxTitlesPlatform = {
  [META]: 5,
  [TIKTOK]: 1,
  [GOOGLE]: 15,
};
const maxDescriptionsPlatform = {
  [META]: 5,
  [TIKTOK]: 0,
  [GOOGLE]: 4,
};
const maxHeadlinesPlatform = {
  [META]: 5,
  [TIKTOK]: 0,
  [GOOGLE]: 0,
};

const AdsAdForm = ({ data, platform }) => {
  const refPreview = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const {
    watch,
    control,
    getValues,
    setValue,
    onAddAd,
    onRemoveAd,
    isGeneralDisabledFields,
    getIsCampaignPlatformActive,
    getIsCampaignPlatformCreated,
    getShowActionsActionsPlatform,
    onChangeStatusCampaign,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const theme = useTheme();
  const breakColumnFormAndPreview = useMediaQuery(() => "(max-width: 1080px)");
  const adsGroups = watch("adsGroups");
  const indexAdGroup = adsGroups.findIndex(
    (adGroup) => adGroup.id === data.adGroupID
  );
  const ads = adsGroups?.[indexAdGroup]?.ads;
  const adsPlatform = ads.filter((ad) => ad.platform === platform);
  const hasOnlyOneAd = adsPlatform.length === 1;
  const indexAd = ads.findIndex((ad) => ad.id === data.id);
  const BASIC_PATH_AD_GROUP = `adsGroups.[${indexAdGroup}]`;
  const BASIC_PATH = `adsGroups.[${indexAdGroup}].ads.[${indexAd}]`;
  const objective = watch(`objective`);
  const isCarousel = watch(`${BASIC_PATH}.isCarousel`);
  const platformData = watch(`platformsData.${META}`) || {};

  const isCampaignPlatformActivated = getIsCampaignPlatformActive(platform);
  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
  const showActionsPlatform = getShowActionsActionsPlatform(platform);

  const blockMultipleTexts = platform === META;
  const isSpecialAdWithOnlyTitles = getIsSpecialAdWithOnlyTitles({
    platform,
    destinationType: platformData?.destinationType,
    objective,
  });
  const isSpecialAdWithoutCallToAction = getIsSpecialAdWithoutCallToAction({
    platform,
    destinationType: platformData?.destinationType,
    objective,
  });
  const isCarouselAdsAvailable = getIsCarouselAdIsAvailable({
    platform,
    destinationType: platformData?.destinationType,
  });

  const onGenerateText = async ({ type, index }) => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const BASIC_PATH_AD_GROUPS = `adsGroups.[${indexAdGroup}]`;
    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];

    const response = await dispatch(
      generateAdsCampaignDescription({
        objective,
        platform,
        websiteURL: getValues("websiteURL"),
        ageRange: getValues(`${BASIC_PATH_AD_GROUPS}.ageRange`),
        genders: getValues(`${BASIC_PATH_AD_GROUPS}.genders`),
        locations: getValues(`${BASIC_PATH_AD_GROUPS}.locations`),
        interests: getValues(`${BASIC_PATH_AD_GROUPS}.interests`),
        behaviors: getValues(`${BASIC_PATH_AD_GROUPS}.behaviors`),
        demographics: getValues(`${BASIC_PATH_AD_GROUPS}.demographics`),
        keywords: getValues(`${BASIC_PATH_AD_GROUPS}.keywords`),
        language: getValues(`language`),
        type,
        currentTexts: getAdsTextsFormatted({
          texts: currentTexts,
          format: "save",
        }),
      })
    );

    if (response) {
      onChangeText(`${BASIC_PATH}.${type}.[${index}].value`, response);
    }
  };

  const onAddText = async ({ type }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];
    setValue(`${BASIC_PATH}.${type}`, [
      ...currentTexts,
      {
        value: "",
        id: getUniqueID(),
      },
    ]);
  };
  const onRemoveText = async ({ type, index }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`);
    setValue(
      `${BASIC_PATH}.${type}`,
      currentTexts.filter((_, i) => i !== index)
    );
  };

  const onChangeIsActive = async (value) => {
    setIsSaving(true);
    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: "ad",
      platform,
      adGroupID: indexAdGroup,
      adID: indexAd,
    });
    setIsSaving(false);
  };
  const onChangeIsCarousel = async (value) => {
    const ads = getValues(`adsGroups.[${indexAdGroup}].ads`);
    if (value) {
      let mediaData = [];
      let mediaUrls = [];
      let mediaIDs = [];
      let destinationUrls = [];

      const destinationURL = getValues("destinationURL");

      ads.forEach((ad) => {
        ad.mediaUrls.forEach((mediaUrl, index) => {
          mediaUrls.push(mediaUrl);
          mediaData.push(ad?.mediaData?.[index] || { from: "upload" });
          mediaIDs.push(ad?.mediaIDs?.[index] || "");
          destinationUrls.push({ value: destinationURL, id: getUniqueID() });
        });
      });

      setValue(`${BASIC_PATH}.mediaUrls`, mediaUrls);
      setValue(`${BASIC_PATH}.mediaData`, mediaData);
      setValue(`${BASIC_PATH}.mediaIDs`, mediaIDs);
      setValue(`${BASIC_PATH}.destinationUrls`, destinationUrls);
    } else {
      const ad = ads.find((ad) => ad.id === data.id);
      if (ad) {
        setValue(`${BASIC_PATH}.mediaUrls`, [ad?.mediaUrls?.[0] || ""]);
        setValue(`${BASIC_PATH}.mediaData`, [
          ad?.mediaData?.[0] || { from: "upload" },
        ]);
        setValue(`${BASIC_PATH}.mediaIDs`, [ad?.mediaIDs?.[0] || ""]);
        setValue(`${BASIC_PATH}.destinationUrls`, []);
      } else {
        setValue(`${BASIC_PATH}.mediaUrls`, []);
        setValue(`${BASIC_PATH}.mediaData`, []);
        setValue(`${BASIC_PATH}.mediaIDs`, []);
        setValue(`${BASIC_PATH}.destinationUrls`, []);
      }
    }
    setValue(`${BASIC_PATH}.isCarousel`, value);
    return true;
  };
  const gridItems = {
    xs: 12,
    lg: 6,
  };

  const onGeneratePreview = () => {
    if (refPreview?.current) {
      refPreview.current.generatePreview();
    }
  };

  const onChangeText = (name, value) => {
    setValue(name, value);
    onGeneratePreview();
  };

  return (
    <Accordion
      id={data.id}
      title={`${t(AD)} ${t(NUMBER_ITEM, { value: indexAd + 1 })}`}
      variantTitle={"body1"}
      initialValue={true}
      sx={{
        border: `${theme.palette.border.grey} 1px solid`,
        borderRadius: `${4 * 4}px !important`,
      }}
      actionsComponent={
        isGeneralDisabledFields ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexGrow: 1,
              mr: { xs: 0, sm: 2 },
              pl: { xs: 2, sm: 0 },
            }}
          >
            {showActionsPlatform && (
              <Controller
                name={`${BASIC_PATH}.status`}
                control={control}
                render={({ field }) => (
                  <Switch
                    loading={isSaving}
                    isActive={field.value === ADS_CAMPAIGN_STATUS_ACTIVE}
                    labelActive={ACTIVE}
                    labelDeactivate={DEACTIVATED}
                    onChangeIsActive={(value) => onChangeIsActive(value)}
                    sx={{ mr: 1.5 }}
                  />
                )}
              />
            )}
            {isCarouselAdsAvailable && (
              <Controller
                name={`${BASIC_PATH}.isCarousel`}
                control={control}
                render={() => {
                  return (
                    <Switch
                      disabled={isCampaignPlatformCreated}
                      isActive={isCarousel}
                      labelActive={IS_CAROUSEL}
                      labelDeactivate={IS_CAROUSEL}
                      onChangeIsActive={(checked) =>
                        onChangeIsCarousel(checked)
                      }
                      sx={{ mr: 1.5 }}
                    />
                  );
                }}
              />
            )}
            {platform !== GOOGLE && (
              <IconButton
                type="button"
                onClick={(e) => {
                  e?.stopPropagation();
                  onAddAd({ adGroupID: data?.adGroupID, adID: data?.id });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            )}
            {!hasOnlyOneAd && (
              <IconButton
                onClick={(e) => {
                  e?.stopPropagation();
                  onRemoveAd({ adGroupID: data?.adGroupID, adID: data?.id });
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )
      }
    >
      {isCarousel && (
        <AdsAdCarouselForm
          data={data}
          platform={platform}
          BASIC_PATH_AD_GROUP={BASIC_PATH_AD_GROUP}
          BASIC_PATH={BASIC_PATH}
          gridItems={gridItems}
          onAddText={onAddText}
          onRemoveText={onRemoveText}
          onGenerateText={onGenerateText}
          onChangeText={onChangeText}
          isSpecialAdWithOnlyTitles={isSpecialAdWithOnlyTitles}
          isSpecialAdWithoutCallToAction={isSpecialAdWithoutCallToAction}
          blockMultipleTexts={blockMultipleTexts}
          isCampaignPlatformActivated={isCampaignPlatformActivated}
          isGeneralDisabledFields={isGeneralDisabledFields}
          errors={errors}
          control={control}
          refPreview={refPreview}
          breakColumnFormAndPreview={breakColumnFormAndPreview}
          platformData={platformData}
          objective={objective}
          onGeneratePreview={onGeneratePreview}
        />
      )}
      {!isCarousel && (
        <AdsAdNormalForm
          data={data}
          platform={platform}
          BASIC_PATH_AD_GROUP={BASIC_PATH_AD_GROUP}
          BASIC_PATH={BASIC_PATH}
          gridItems={gridItems}
          onAddText={onAddText}
          onRemoveText={onRemoveText}
          onGenerateText={onGenerateText}
          onChangeText={onChangeText}
          isSpecialAdWithOnlyTitles={isSpecialAdWithOnlyTitles}
          isSpecialAdWithoutCallToAction={isSpecialAdWithoutCallToAction}
          blockMultipleTexts={blockMultipleTexts}
          isCampaignPlatformActivated={isCampaignPlatformActivated}
          isGeneralDisabledFields={isGeneralDisabledFields}
          errors={errors}
          control={control}
          refPreview={refPreview}
          breakColumnFormAndPreview={breakColumnFormAndPreview}
          platformData={platformData}
          objective={objective}
        />
      )}
    </Accordion>
  );
};

const AdsAdCarouselForm = ({
  data,
  objective,
  platform,
  platformData,
  breakColumnFormAndPreview,
  BASIC_PATH_AD_GROUP,
  BASIC_PATH,
  gridItems,
  onAddText,
  onRemoveText,
  onGenerateText,
  onChangeText,
  isSpecialAdWithOnlyTitles,
  isSpecialAdWithoutCallToAction,
  blockMultipleTexts,
  isCampaignPlatformActivated,
  isGeneralDisabledFields,
  errors,
  control,
  refPreview,
  onGeneratePreview,
}) => {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const adID = data.id;

  const errorMediaUrl = getReactHookFormNestedError(
    errors,
    `${BASIC_PATH}.mediaUrls`
  );

  const maxLengthTitle = ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform];
  const maxLengthDescription =
    ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform];
  const maxLengthHeadline = ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[platform];

  const maxTitles = maxTitlesPlatform[platform];

  const destinationType = platformData?.destinationType;
  const websiteURL = watch("websiteURL");
  const socialNetworkURL = watch("socialNetworkURL");
  const productContext = watch("productContext");
  const targetContext = watch("targetContext");
  const language = watch("language");
  const ageRange = watch(`${BASIC_PATH_AD_GROUP}.ageRange`);
  const genders = watch(`${BASIC_PATH_AD_GROUP}.genders`);
  const mediaUrls = watch(`${BASIC_PATH}.mediaUrls`) || [""];
  const mediaData = watch(`${BASIC_PATH}.mediaData`) || [{}];
  const callToAction = watch(`${BASIC_PATH}.callToAction`);
  const titles = watch(`${BASIC_PATH}.titles`) || [{ value: "" }];
  const headlines = watch(`${BASIC_PATH}.headlines`) || [{ value: "" }];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [{ value: "" }];
  const destinationUrls = watch(`${BASIC_PATH}.destinationUrls`) || [
    { value: "" },
  ];

  useController({
    name: `${BASIC_PATH}.mediaUrls`,
    control,
    rules: {
      required: {
        value: true,
        message: t(FIELD_REQUIRED),
      },
      validate: (value) => {
        if (value.length < 2) {
          return t(FIELD_MIN_CARDS_AD_CAROUSEL, {
            value: 2,
          });
        }
      },
    },
  });

  const showDeleteCardIcon = mediaUrls?.length > 2;
  const showAddCardIcon = mediaUrls?.length < 10;

  const onDuplicateCard = async (index) => {
    const mediaUrlItem = mediaUrls[index];
    const mediaDataItem = mediaData[index];
    const headlineItem = headlines[index];
    const descriptionItem = descriptions[index];
    const destinationUrlItem = destinationUrls[index];

    setValue(`${BASIC_PATH}.mediaUrls`, [...mediaUrls, mediaUrlItem]);
    setValue(`${BASIC_PATH}.mediaData`, [...mediaData, mediaDataItem]);
    setValue(`${BASIC_PATH}.headlines`, [...headlines, headlineItem]);
    setValue(`${BASIC_PATH}.descriptions`, [...descriptions, descriptionItem]);
    setValue(`${BASIC_PATH}.destinationUrls`, [
      ...destinationUrls,
      destinationUrlItem,
    ]);
  };
  const onRemoveCard = async (index) => {
    setValue(
      `${BASIC_PATH}.mediaUrls`,
      mediaUrls.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.mediaData`,
      mediaData.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.headlines`,
      headlines.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.descriptions`,
      descriptions.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.destinationUrls`,
      destinationUrls.filter((_, i) => i !== index)
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakColumnFormAndPreview ? "column" : "row",
        gap: 5,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          height: "min-content",
        }}
      >
        <Grid
          item
          {...gridItems}
          lg={isSpecialAdWithOnlyTitles ? 12 : 6}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body1">
            {t(platform === META ? TITLES : TITLE)}
          </Typography>
          {titles?.map((title, index) => (
            <TextFieldWithActions
              key={title.id}
              type="titles"
              label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.titles.[${index}].value`}
              disabled={isCampaignPlatformActivated || isGeneralDisabledFields}
              showAddIcon={index === titles.length - 1 && index < maxTitles - 1}
              showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
              platform={platform}
              onChange={onChangeText}
              onAdd={() => onAddText({ type: "titles" })}
              onRemove={() => onRemoveText({ type: "titles", index })}
              onGenerate={async () => {
                await onGenerateText({ type: "titles", index });
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: (value) => {
                  if (value.length > maxLengthTitle) {
                    return t(FIELD_MAX_LENGTH, {
                      value: maxLengthTitle,
                    });
                  }
                  const allTitles = titles.map((item) =>
                    (item.value || "").trim().toLowerCase()
                  );
                  const timesRepeated = allTitles.filter(
                    (text) => text === value.trim().toLowerCase()
                  ).length;

                  if (timesRepeated > 1) {
                    return t(FIELD_TEXT_REPEATED);
                  }
                  return true;
                },
              }}
              sx={{
                ".MuiInputBase-root": {
                  pb: 4,
                },
              }}
            />
          ))}
        </Grid>

        {((platform === META && !isSpecialAdWithoutCallToAction) ||
          (platform === TIKTOK &&
            objective !== ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION)) && (
          <Grid item {...gridItems}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">
                {t(CALL_TO_ACTION_PLACEHOLDER)}
              </Typography>
              <SelectCallToActionAds
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                name={`${BASIC_PATH}.callToAction`}
                fullWidth
                objective={objective}
                platform={platform}
                destinationType={platformData?.destinationType}
                doTranslate={true}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Box>
          </Grid>
        )}

        {errorMediaUrl && (
          <Grid item xs={12}>
            <Typography
              name={`${BASIC_PATH}.mediaUrls`}
              variant="body1"
              color="error"
              sx={{
                mt: 2,
                mb: -1,
              }}
            >
              {errorMediaUrl.message}
            </Typography>
          </Grid>
        )}
        {mediaUrls?.map((media, index) => {
          const description = descriptions[index] || { value: "" };
          const headline = headlines[index] || { value: "" };
          const destinationUrl = destinationUrls[index] || { value: "" };
          const mediaDataItem = mediaData?.[index] || {};

          return (
            <Grid item xs={12} key={index}>
              <Accordion
                title={`${t(CARD)} ${index + 1}`}
                actionsComponent={
                  isGeneralDisabledFields ? null : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexGrow: 1,
                        mr: { xs: 0, sm: 2 },
                        pl: { xs: 2, sm: 0 },
                      }}
                    >
                      {showAddCardIcon && (
                        <IconButton
                          type="button"
                          onClick={(e) => {
                            e?.stopPropagation();
                            onDuplicateCard(index);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}
                      {showDeleteCardIcon && (
                        <IconButton
                          onClick={(e) => {
                            e?.stopPropagation();
                            onRemoveCard(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  )
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextFieldWithActions
                      key={destinationUrl.id}
                      type="destinationUrl"
                      label={`${t(DESTINATION_URL_PLACEHOLDER)}`}
                      name={`${BASIC_PATH}.destinationUrls.[${index}].value`}
                      disabled={
                        isCampaignPlatformActivated || isGeneralDisabledFields
                      }
                      platform={platform}
                      showAddIcon={false}
                      showDeleteIcon={false}
                      onChange={onChangeText}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (!REGEX_URL.test(encodeURI(value))) {
                            return t(FIELD_URL_VALID);
                          }
                        },
                      }}
                      sx={{
                        ".MuiInputBase-root": {
                          pb: 4,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldWithActions
                      key={headline.id}
                      type="headlines"
                      label={`${t(HEADLINE_PLACEHOLDER)}`}
                      name={`${BASIC_PATH}.headlines.[${index}].value`}
                      disabled={
                        isCampaignPlatformActivated || isGeneralDisabledFields
                      }
                      platform={platform}
                      showAddIcon={false}
                      showDeleteIcon={false}
                      onChange={onChangeText}
                      onAdd={() => onAddText({ type: "headlines" })}
                      onRemove={() =>
                        onRemoveText({ type: "headlines", index })
                      }
                      onGenerate={async () => {
                        await onGenerateText({ type: "headlines", index });
                      }}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (value.length > maxLengthHeadline) {
                            return t(FIELD_MAX_LENGTH, {
                              value: maxLengthHeadline,
                            });
                          }

                          const allHeadlines = headlines
                            .map((item) =>
                              (item.value || "").trim().toLowerCase()
                            )
                            .slice(0, mediaUrls.length);
                          const timesRepeated = allHeadlines.filter(
                            (text) => text === value.trim().toLowerCase()
                          ).length;
                          if (timesRepeated > 1) {
                            return t(FIELD_TEXT_REPEATED);
                          }
                          return true;
                        },
                      }}
                      sx={{
                        ".MuiInputBase-root": {
                          pb: 4,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithActions
                      key={description.id}
                      type="descriptions"
                      label={`${t(DESCRIPTION_PLACEHOLDER)}`}
                      name={`${BASIC_PATH}.descriptions.[${index}].value`}
                      disabled={
                        isCampaignPlatformActivated || isGeneralDisabledFields
                      }
                      platform={platform}
                      showAddIcon={false}
                      showDeleteIcon={false}
                      onChange={onChangeText}
                      onAdd={() => onAddText({ type: "descriptions" })}
                      onRemove={() =>
                        onRemoveText({ type: "descriptions", index })
                      }
                      onGenerate={async () => {
                        await onGenerateText({ type: "descriptions", index });
                      }}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (value.length > maxLengthDescription) {
                            return t(FIELD_MAX_LENGTH, {
                              value: maxLengthDescription,
                            });
                          }

                          const allDescriptions = descriptions
                            .map((item) =>
                              (item.value || "").trim().toLowerCase()
                            )
                            .slice(0, mediaUrls.length);
                          const timesRepeated = allDescriptions.filter(
                            (text) => text === value.trim().toLowerCase()
                          ).length;
                          if (timesRepeated > 1) {
                            return t(FIELD_TEXT_REPEATED);
                          }
                          return true;
                        },
                      }}
                      sx={{
                        ".MuiInputBase-root": {
                          pb: 4,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ContentField
                      propsSelectContentComponent={{
                        acceptedFormats: VIDEO_FORMATS.concat(IMAGE_FORMATS),
                        showButtonUpload: !isCampaignPlatformActivated,
                        showGenerateWithAIOption:
                          destinationType !==
                          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
                        showUploadDeviceOption:
                          destinationType !==
                          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
                        showGalleryOption:
                          destinationType !==
                          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
                        propsGalleryAdsPlatforms: {
                          integrationID: platformData?.integrationID,
                          adAccountID: platformData?.adAccountID,
                          pageID: platformData?.pageID,
                          socialNetworkURL,
                          from:
                            destinationType ===
                            ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
                              ? ADS_CAMPAIGN_TYPE_GALLERY_ADS_PLATFORMS_POSTS
                              : ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
                        },
                        propsGenerateAI: {
                          videoData: mediaDataItem?.videoID
                            ? { ...mediaDataItem, id: mediaDataItem?.videoID }
                            : null,
                          websiteURL,
                          socialNetworkURL,
                          language,
                          integrationID: platformData?.integrationID,
                          adAccountID: platformData?.adAccountID,
                          campaignContext: {
                            objective,
                            productContext,
                            targetContext,
                            genders,
                            ageRange,
                          },
                        },
                      }}
                      minDimensions={MIN_DIMENSIONS_CONTENT}
                      onCallbackSave={({ from, content }) => {
                        const contentFormatted = generalFormatMediaUrl({
                          content,
                          from,
                        });
                        dispatch(
                          startDeleteAdMediaID({
                            adID,
                            index,
                          })
                        );
                        setValue(`${BASIC_PATH}.mediaIDs.[${index}]`, "");
                        setValue(
                          `${BASIC_PATH}.mediaUrls.[${index}]`,
                          contentFormatted.url
                        );
                        setValue(
                          `${BASIC_PATH}.mediaData.[${index}]`,
                          contentFormatted.mediaData
                        );
                        onGeneratePreview();
                      }}
                      label={t(CONTENT)}
                      name={`${BASIC_PATH}.mediaUrls.[${index}]`}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
      <AdPreview
        ref={refPreview}
        adID={data.id}
        mediaUrls={mediaUrls}
        mediaData={mediaData}
        callToAction={callToAction}
        titles={titles}
        headlines={headlines}
        descriptions={descriptions}
        destinationUrls={destinationUrls}
        platform={platform}
        basicPath={BASIC_PATH}
        basicPathAdGroup={BASIC_PATH_AD_GROUP}
      />
    </Box>
  );
};

const AdsAdNormalForm = ({
  data,
  objective,
  platform,
  platformData,
  breakColumnFormAndPreview,
  BASIC_PATH_AD_GROUP,
  BASIC_PATH,
  gridItems,
  onAddText,
  onRemoveText,
  onGenerateText,
  onChangeText,
  isSpecialAdWithOnlyTitles,
  isSpecialAdWithoutCallToAction,
  blockMultipleTexts,
  isCampaignPlatformActivated,
  isGeneralDisabledFields,
  errors,
  control,
  refPreview,
}) => {
  const { watch } = useFormContext();
  const { t } = useTranslationApp();

  const maxLengthTitle = ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform];
  const maxLengthDescription =
    ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform];
  const maxLengthHeadline = ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[platform];

  const maxTitles = maxTitlesPlatform[platform];
  const maxDescriptions = maxDescriptionsPlatform[platform];
  const maxHeadlines = maxHeadlinesPlatform[platform];

  const mediaUrls = watch(`${BASIC_PATH}.mediaUrls`);
  const mediaData = watch(`${BASIC_PATH}.mediaData`);
  const callToAction = watch(`${BASIC_PATH}.callToAction`);
  const titles = watch(`${BASIC_PATH}.titles`) || [{ value: "" }];
  const headlines = watch(`${BASIC_PATH}.headlines`) || [{ value: "" }];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [{ value: "" }];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakColumnFormAndPreview ? "column" : "row",
        gap: 5,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          height: "min-content",
        }}
      >
        <Grid
          item
          {...gridItems}
          lg={isSpecialAdWithOnlyTitles ? 12 : 6}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body1">
            {t(platform === META ? TITLES : TITLE)}
          </Typography>
          {titles?.map((title, index) => (
            <TextFieldWithActions
              key={title.id}
              type="titles"
              label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.titles.[${index}].value`}
              disabled={isCampaignPlatformActivated || isGeneralDisabledFields}
              showAddIcon={index === titles.length - 1 && index < maxTitles - 1}
              showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
              platform={platform}
              onChange={onChangeText}
              onAdd={() => onAddText({ type: "titles" })}
              onRemove={() => onRemoveText({ type: "titles", index })}
              onGenerate={async () => {
                await onGenerateText({ type: "titles", index });
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: (value) => {
                  if (value.length > maxLengthTitle) {
                    return t(FIELD_MAX_LENGTH, {
                      value: maxLengthTitle,
                    });
                  }
                  if (platform === GOOGLE && !REGEX_TITLE_GOOGLE.test(value)) {
                    return t(FIELD_TEXT_ONLY_HAS_LETTERS_NUMBERS_DOTS_COMMAS);
                  }

                  const allTitles = titles.map((item) =>
                    (item.value || "").trim().toLowerCase()
                  );
                  const timesRepeated = allTitles.filter(
                    (text) => text === value.trim().toLowerCase()
                  ).length;
                  if (timesRepeated > 1) {
                    return t(FIELD_TEXT_REPEATED);
                  }
                  return true;
                },
              }}
              sx={{
                ".MuiInputBase-root": {
                  pb: 4,
                },
              }}
            />
          ))}
        </Grid>
        {(platform === META || platform === GOOGLE) &&
          !isSpecialAdWithOnlyTitles && (
            <Grid
              item
              {...gridItems}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">{t(DESCRIPTIONS)}</Typography>
              {descriptions?.map((description, index) => (
                <TextFieldWithActions
                  key={description.id}
                  type="descriptions"
                  label={`${t(DESCRIPTION_PLACEHOLDER)} ${index + 1}`}
                  name={`${BASIC_PATH}.descriptions.[${index}].value`}
                  disabled={
                    isCampaignPlatformActivated || isGeneralDisabledFields
                  }
                  platform={platform}
                  showAddIcon={
                    index === descriptions.length - 1 &&
                    index < maxDescriptions - 1
                  }
                  showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
                  onChange={onChangeText}
                  onAdd={() => onAddText({ type: "descriptions" })}
                  onRemove={() => onRemoveText({ type: "descriptions", index })}
                  onGenerate={async () => {
                    await onGenerateText({ type: "descriptions", index });
                  }}
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                    validate: (value) => {
                      if (value.length > maxLengthDescription) {
                        return t(FIELD_MAX_LENGTH, {
                          value: maxLengthDescription,
                        });
                      }
                      const allDescriptions = descriptions.map((item) =>
                        (item.value || "").trim().toLowerCase()
                      );
                      const timesRepeated = allDescriptions.filter(
                        (text) => text === value.trim().toLowerCase()
                      ).length;
                      if (timesRepeated > 1) {
                        return t(FIELD_TEXT_REPEATED);
                      }
                      return true;
                    },
                  }}
                  sx={{
                    ".MuiInputBase-root": {
                      pb: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          )}
        {platform === META && !isSpecialAdWithOnlyTitles && (
          <Grid
            item
            {...gridItems}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1">{t(HEADLINES)}</Typography>
            {headlines?.map((headline, index) => (
              <TextFieldWithActions
                key={headline.id}
                type="headlines"
                label={`${t(HEADLINE_PLACEHOLDER)} ${index + 1}`}
                name={`${BASIC_PATH}.headlines.[${index}].value`}
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                platform={platform}
                showAddIcon={
                  index === headlines.length - 1 && index < maxHeadlines - 1
                }
                showDeleteIcon={blockMultipleTexts ? index > 1 : index !== 0}
                onChange={onChangeText}
                onAdd={() => onAddText({ type: "headlines" })}
                onRemove={() => onRemoveText({ type: "headlines", index })}
                onGenerate={async () => {
                  await onGenerateText({ type: "headlines", index });
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (value.length > maxLengthHeadline) {
                      return t(FIELD_MAX_LENGTH, {
                        value: maxLengthHeadline,
                      });
                    }
                    const allHeadlines = headlines.map((item) =>
                      (item.value || "").trim().toLowerCase()
                    );
                    const timesRepeated = allHeadlines.filter(
                      (text) => text === value.trim().toLowerCase()
                    ).length;
                    if (timesRepeated > 1) {
                      return t(FIELD_TEXT_REPEATED);
                    }
                    return true;
                  },
                }}
                sx={{
                  ".MuiInputBase-root": {
                    pb: 4,
                  },
                }}
              />
            ))}
          </Grid>
        )}
        {((platform === META && !isSpecialAdWithoutCallToAction) ||
          (platform === TIKTOK &&
            objective !== ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION)) && (
          <Grid item {...gridItems}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">
                {t(CALL_TO_ACTION_PLACEHOLDER)}
              </Typography>
              <SelectCallToActionAds
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                name={`${BASIC_PATH}.callToAction`}
                fullWidth
                objective={objective}
                platform={platform}
                destinationType={platformData?.destinationType}
                doTranslate={true}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {platform !== GOOGLE && (
        <AdPreview
          ref={refPreview}
          adID={data.id}
          mediaUrls={mediaUrls}
          mediaData={mediaData}
          callToAction={callToAction}
          titles={titles}
          headlines={headlines}
          descriptions={descriptions}
          platform={platform}
          basicPath={BASIC_PATH}
          basicPathAdGroup={BASIC_PATH_AD_GROUP}
        />
      )}
    </Box>
  );
};

const TextFieldWithActions = ({
  name,
  label,
  disabled,
  control,
  errors,
  rules,
  sx,
  showAddIcon = true,
  showDeleteIcon = true,
  onChange,
  onAdd = () => {},
  onRemove = () => {},
  onGenerate = () => {},
  type = "titles",
  platform,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const getRows = () => {
    if (type === "headlines" || type === "destinationUrl") {
      return { min: 1, max: 2 };
    }
    if (type === "titles" && platform === GOOGLE) {
      return { min: 1, max: 1 };
    }
    return { min: 3, max: 3 };
  };

  const rows = getRows();
  const error = getReactHookFormNestedError(errors, name);

  const isMultiple = platform === META || platform === GOOGLE;

  const isDisabled = disabled || isGenerating;
  const showGenerateIcon = type !== "destinationUrl";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <TextField
        label={label}
        name={name}
        variant="filled"
        multiline
        minRows={rows.min}
        maxRows={rows.max}
        fullWidth
        disabled={disabled}
        doOnChange={false}
        actionsChange={(value) => onChange(name, value)}
        control={control}
        errors={errors}
        rules={rules}
        sx={sx}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          position: "absolute",
          mb: 1,
          mr: 1,
          right: 0,
          height: "100%",
          mt: error ? -5 : 0,
        }}
      >
        {showDeleteIcon && isMultiple && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onRemove()}
            sx={{
              p: 0.4,
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {showGenerateIcon && (
          <>
            {isGenerating ? (
              <Loader
                size={20}
                hasMarginTop={false}
                sx={{
                  mb: 0.75,
                }}
              />
            ) : (
              <IconButton
                disabled={isDisabled}
                size={"small"}
                onClick={async () => {
                  setIsGenerating(true);
                  await onGenerate();
                  setIsGenerating(false);
                }}
                sx={{
                  p: 0.4,
                }}
              >
                <AutoAwesomeIcon />
              </IconButton>
            )}
          </>
        )}
        {showAddIcon && isMultiple && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onAdd()}
            sx={{
              p: 0.4,
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
export default AdsAdForm;
