import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BORDER_RADIUS } from "../../utils/constants";
import { useTheme } from "@emotion/react";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "./chart";
import {
  CartesianGrid,
  LabelList,
  Bar,
  BarChart as BarChartLib,
  XAxis,
} from "recharts";
import { useTranslationApp } from "../../lib/i18next";
import { VALUE_NUMBER } from "../../i18n/keysTranslations";
import { forwardRef } from "react";

const BarChart = forwardRef((props, ref) => {
  const {
    chartData = {},
    chartConfig = {},
    xAxisKey = "label",
    title,
    subtitle,
    description,
    hasTooltip = true,
    hasLegend = true,
    hasCartesianGrid = true,
    valueFormat,
  } = props;

  const theme = useTheme();
  const { t } = useTranslationApp();

  const hasMoreSix = chartData.length > 6;

  const chartConfigKeys = Object.keys(chartConfig);
  const getValueFormatted = (value) => {
    if (valueFormat) {
      return t(valueFormat.translation, {
        [valueFormat.key]: value,
        ...valueFormat.options,
      });
    }
    return t(VALUE_NUMBER, {
      value,
    });
  };
  return (
    <Box
      ref={ref}
      sx={{
        padding: 2,
        borderRadius: BORDER_RADIUS,
        border: `1px solid ${theme.palette.border.grey}`,
      }}
    >
      {title && <Typography variant="body1">{title}</Typography>}
      {subtitle && (
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      )}
      <ChartContainer config={chartConfig}>
        <BarChartLib
          accessibilityLayer
          data={chartData}
          margin={{
            top: 20,
          }}
        >
          {hasCartesianGrid && <CartesianGrid vertical={false} />}
          {hasTooltip && (
            <ChartTooltip
              cursor={false}
              className="bg-black"
              content={<ChartTooltipContent />}
              formatter={(value, name) => (
                <>
                  <div
                    className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                    style={{
                      "--color-bg": `var(--color-${name})`,
                    }}
                  />
                  {chartConfig[name]?.label || name}
                  <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                    {getValueFormatted(value)}
                  </div>
                </>
              )}
            />
          )}
          {hasLegend && (
            <ChartLegend content={<ChartLegendContent hideLabel />} />
          )}
          <XAxis
            dataKey={xAxisKey}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            interval="preserveStartEnd"
          />
          {chartConfigKeys.map((key) => (
            <Bar
              key={key}
              dataKey={key}
              fill={`var(--color-${key})`}
              stackId="a"
              radius={8}
            >
              {chartConfig[key].hasLabelList && !hasMoreSix && (
                <LabelList
                  position="top"
                  offset={3}
                  className="fill-foreground"
                  fontSize={12}
                  formatter={(value) => getValueFormatted(value)}
                />
              )}
            </Bar>
          ))}
        </BarChartLib>
      </ChartContainer>
      {description && (
        <Typography
          variant="body2"
          sx={{
            mt: 2,
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
});

BarChart.displayName = "BarChart";
export default BarChart;
