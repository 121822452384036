import { useSelectorApp } from "../../lib/redux";
import { Navigate, useLocation } from "react-router-dom";
import { ROLES_ALLOWED } from "../../utils/constants";

export const PrivateRoute = ({ children, isLogged, verifyTrial = true }) => {
  const location = useLocation();
  const { user } = useSelectorApp((state) => state.auth);
  const isLoading = useSelectorApp((state) => state.payments.isLoading);
  const subscriptionPlanInfo = useSelectorApp(
    (state) => state.payments.subscriptionPlanInfo
  );
  const subscriptionPlan = useSelectorApp(
    (state) => state.payments.subscriptionPlan
  );

  const finishTrialDate = subscriptionPlanInfo?.finishTrialDate;
  const isTrialExpired = finishTrialDate
    ? new Date(finishTrialDate).getTime() < Date.now()
    : false;

  if (!isLogged || !ROLES_ALLOWED.includes(user?.role)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isLoading && verifyTrial) {
    return null;
  }

  if (isTrialExpired && !subscriptionPlan && verifyTrial) {
    return <Navigate to="/paywall" state={{ from: location }} replace />;
  }

  return children;
};
