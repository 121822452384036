import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import {
  BUTTON_PUBLISH_CAMPAIGN,
  BUTTON_EDIT_BUDGET,
  BUTTON_GENERATE_AGAIN,
  READY,
  WHY_THESE_RECOMMENDED_SETTINGS,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_PLACEHOLDER,
  FIELD_REQUIRED,
  FIELD_MIN_LENGTH,
  FIELD_MAX_LENGTH,
  EDIT_INSTRUCTIONS,
  PRODUCT_OR_SERVICE_CONTEXT,
  BUTTON_SAVE_CHANGES,
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  GETTING_REQUIRED_INFORMATION,
  VERIFYING_PLATFORMS_INFORMATION,
  CREATING_CAMPAIGN,
  CREATING_AD_GROUPS,
  UPLOADING_CONTENTS,
  CREATING_ADS,
  SAVING_INFORMATION,
  ERROR_PROCESSING_CAMPAIGNS,
  ERROR,
  CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION_AND_CREATE_CAMPAIGN,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION,
  CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_PLACEHOLDER,
  TARGET_AUDIENCE_CONTEXT,
} from "../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
  ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
  ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
  ADS_CAMPAIGN_MODE_EDIT,
  TIME_LOADER_GENERATING_CAMPAIGN_QUICK,
  ADS_CAMPAIGN_CREATION_MODE_ADVANCED,
  ADS_CAMPAIGN_MEDIA_FROM_CREATIFY,
  VIDEO_DONE_STATUS_CREATIFY,
  ALERT_ICON_TYPE_ERROR,
  VIDEO_IN_QUEUE_STATUS_CREATIFY,
  AFTER_RENDER_ACTION_PUBLISH,
  AFTER_RENDER_ACTION_DOWNLOAD,
  ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
  ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
  TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED,
  GOOGLE,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
} from "../../../utils/constants";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  startCreateAdsCampaign,
  startGetRecommendedSettingsAdsCampaign,
  startSaveAdsCampaignDraft,
} from "../../../actions/adsCampaigns";
import { ConfirmAlert, SimpleAlert } from "../../../components/Alerts/Alerts";
import Accordion from "../../../components/Containers/Accordion";
import LinearLoaderWithTime from "../../../components/Loaders/LinearLoaderWithTime";
import TypingText from "../../../components/Texts/TypingText";
import ButtonGenerateAI from "../../../components/Buttons/ButtonGenerateAI";
import TextField from "../../../components/Form/TextField";
import { useFormContext } from "react-hook-form";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import EditIcon from "@mui/icons-material/Edit";
import {
  getIntegrationKey,
  getIsBlockedActions,
} from "../../../actions/getters";
import { getPathDatabaseByRole } from "../../../actions/auth";
import {
  getAdsWithRenderingMedia,
  getAdsWithUnRenderedAndFailedRenderedMedia,
  getCampaignUpdatedByStatus,
} from "../../../services/adsCampaigns";
import { setNestedValue } from "../../../utils/forms";
import ModalConnectAccounts from "../../../components/Modal/ModalConnectAccounts";
import ModalErrorsCampaigns from "./ModalErrorsCampaigns";
import addMinutes from "date-fns/addMinutes";
import addDays from "date-fns/addDays";
import { createPortal } from "react-dom";
import {
  startGetAwsDownloadUrl,
  startRenderVideoFromCreatify,
} from "../../../actions/user";
import BackdropLoaderRenderMediaCreatify from "../../../components/Loaders/BackdropLoaderRenderMediaCreatify";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContainerBudgetAlert from "../../../components/Containers/ContainerBudgetAlert";

const getInformationBudget = (data) => {
  let budget = 0;
  let typeBudget;
  let startDate = null;
  let endDate = null;

  const platforms = data.platforms || [];

  if (platforms.includes(GOOGLE)) {
    const googlePlatformData = data.platformsData?.[GOOGLE] || {};
    const budgetGoogle = googlePlatformData.budget || 0;
    if (budgetGoogle) budget += Number(budgetGoogle);
    if (
      !typeBudget ||
      googlePlatformData.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
    )
      typeBudget = googlePlatformData.typeBudget;
  }

  const adsGroups = data.adsGroups;

  Object.keys(adsGroups).forEach((key) => {
    const adsGroup = adsGroups[key];

    if (
      !typeBudget ||
      adsGroup.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
    )
      typeBudget = adsGroup.typeBudget;
    if (!startDate) startDate = adsGroup.startDate;
    if (!endDate) endDate = adsGroup.endDate;

    const currentBudget = adsGroup.budget || 0;
    if (currentBudget) budget += Number(currentBudget);
  });

  if (!startDate) {
    startDate = addMinutes(new Date(), 10);
  }
  if (!endDate) {
    endDate = addDays(new Date(), 1);
  }

  return {
    budget,
    typeBudget,
    startDate,
    endDate,
  };
};

const AdsCampaignReasoningContainer = ({
  campaignID,
  getCurrentData = () => {},
  onChangeData = () => {},
  onCallbackFinishGenerateAgain = () => {},
  onCallbackFinishReasoning = () => {},
  onChangeMode = () => {},
  validateFields,
  reasoning,
  control,
  errors,
}) => {
  const {
    isGeneralCampaignCreated,
    isGeneralCampaignActivated,
    isGeneralDisabledFields,
    hasSomePlatformDraft,
    currency,
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const [isSaving, setIsSaving] = useState(false);
  const [modalConnectAccountsOpen, setModalConnectAccountsOpen] =
    useState(false);
  const [modalErrorsCampaignsOpen, setModalErrorsCampaignsOpen] =
    useState(false);
  const [dataModalErrorsCampaigns, setDataModalErrorsCampaigns] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const renderMediaDataRef = useRef([]);
  const [isValidatingIfNeedRenderMedia, setIsValidatingIfNeedRenderMedia] =
    useState(false);

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const afterRenderAction = params.get("afterRenderAction");
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const integrations = useSelectorApp((state) => state.user.integrations);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const { userID } = dispatch(getPathDatabaseByRole());
  const platforms = watch("platforms");
  const mode = watch("mode");

  const isRenderMedia = renderMediaDataRef?.current?.length > 0;
  const isAdvancedMode = mode === ADS_CAMPAIGN_CREATION_MODE_ADVANCED;

  const onSaveCampaign = async (e, withAlertSave = true) => {
    const objectUpdate = getCampaignUpdatedByStatus({
      campaign: getValues(),
      status: getValues("states.general"),
      target: "general",
    });

    setIsSaving(true);
    const response = await dispatch(
      startSaveAdsCampaignDraft({
        campaign: objectUpdate,
        campaignID,
      })
    );
    setIsSaving(false);
    if (response) {
      setNestedValue({
        data: objectUpdate,
        setValue,
      });
      if (withAlertSave) {
        SimpleAlert({
          title: t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    }
  };

  const onGenerateAgain = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_CAMPAIGN_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const data = getCurrentData();

    const isValidPlatforms = await validateFields("platforms");
    const isValidLanguage = await validateFields("language");
    const isValidObjective = await validateFields("objective");
    const isValidTrackingID = await validateFields("trackingIDs");
    const isValidProductContext = await validateFields("productContext");
    const isValidTargetContext = await validateFields("targetContext");
    const isValidDestinationUrl = await validateFields("destinationURL");

    let isValidMediaUrls = true;

    const adsGroups = data.adsGroups || {};
    for (const adGroupKey of Object.keys(adsGroups)) {
      const adsGroup = adsGroups[adGroupKey];
      const ads = adsGroup.ads || {};

      for (const adKey of Object.keys(ads)) {
        if (!isValidMediaUrls) {
          break;
        }

        isValidMediaUrls = await validateFields(
          `adsGroups.[${adGroupKey}].ads.[${adKey}].mediaUrls`
        );
      }
      if (!isValidMediaUrls) {
        break;
      }
    }

    if (
      !isValidPlatforms ||
      !isValidObjective ||
      !isValidTrackingID ||
      !isValidProductContext ||
      !isValidTargetContext ||
      !isValidDestinationUrl ||
      !isValidMediaUrls ||
      !isValidLanguage
    ) {
      return;
    }

    setIsGenerating(true);

    let mediaUrls = [];

    const { typeBudget, budget, startDate, endDate } =
      getInformationBudget(data);

    const platformDataGoogle = data?.platformsData?.[GOOGLE] || {};
    const platforms = data.platforms || [];

    if (
      platforms?.includes(GOOGLE) &&
      platformDataGoogle?.mediaUrls?.length > 0
    ) {
      platformDataGoogle?.mediaUrls.forEach((mediaUrl, index) => {
        const mediaDataItem = platformDataGoogle?.mediaData?.[index] || {};

        if (
          mediaUrl ||
          (mediaDataItem?.preview &&
            mediaDataItem?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY)
        ) {
          mediaUrls.push({
            url: mediaUrl,
            mediaData: mediaDataItem,
          });
        }
      });
    }

    Object.keys(data.adsGroups).forEach((key) => {
      const adsGroup = data.adsGroups[key] || {};
      const ads = adsGroup.ads || {};

      Object.keys(ads).forEach((adKey) => {
        const ad = ads[adKey] || {};

        if (!ad?.mediaUrls || ad?.mediaUrls?.length === 0) {
          return;
        }

        ad.mediaUrls.forEach((mediaUrl, index) => {
          const mediaDataItem = ad?.mediaData?.[index] || {};
          if (
            mediaUrl ||
            (mediaDataItem?.preview &&
              mediaDataItem?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY)
          ) {
            mediaUrls.push({
              url: mediaUrl,
              mediaData: mediaDataItem,
            });
          }
        });
      });
    });

    setIsGenerating(true);

    const response = await dispatch(
      startGetRecommendedSettingsAdsCampaign({
        name: data.name,
        platforms: data.platforms,
        websiteURL: data.websiteURL,
        objective: data.objective,
        productContext: data.productContext,
        targetContext: data.targetContext,
        destinationURL: data.destinationURL,
        typeBudget,
        budget,
        startDate,
        endDate,
        mediaUrls,
        campaignID,
        language: data.language,
        platformsData: data.platformsData,
        mode: data.mode || ADS_CAMPAIGN_CREATION_MODE_ADVANCED,
        brandContext: data.brandContext || null,
        socialNetworkURL: data.socialNetworkURL || null,
        locations: data.locations || null,
      })
    );
    setIsGenerating(false);

    if (response.ok) {
      const newCampaign = response.data;
      setIsTyping(true);
      onChangeData(newCampaign);
      onCallbackFinishGenerateAgain();
    }
  };

  const onValidateAdsWithRenderedMedia = async () => {
    const data = getCurrentData();

    const { adsWithUnRenderedMedia, adsWithRenderFailedMedia } =
      getAdsWithUnRenderedAndFailedRenderedMedia(data);

    if (adsWithUnRenderedMedia.length > 0) {
      const numberOfVideosToRender = adsWithUnRenderedMedia.length;

      const isBlocked = await dispatch(
        getIsBlockedActions({
          action: {
            ...ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
            cost:
              numberOfVideosToRender * ADS_CAMPAIGN_RENDERED_VIDEO_ACTION.cost,
          },
        })
      );
      if (isBlocked) {
        return;
      }

      const promises = adsWithUnRenderedMedia.map((unRenderedItem) => {
        return dispatch(
          startRenderVideoFromCreatify({
            adIDs: unRenderedItem.adIDs,
            videoID: unRenderedItem.videoID,
          })
        );
      });

      await Promise.allSettled(promises);

      const allAdIDs = adsWithUnRenderedMedia.map((item) => item.adIDs).flat();
      const data = getCurrentData();

      const adsGroups = data.adsGroups;
      adsGroups.forEach((adsGroup, indexAdGroup) => {
        const ads = adsGroup.ads;

        ads.forEach((ad, indexAd) => {
          if (allAdIDs.includes(ad.id)) {
            setValue(
              `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaData.[0].status`,
              VIDEO_IN_QUEUE_STATUS_CREATIFY
            );
          }
        });
      });

      onSaveCampaign(null, false);
      renderMediaDataRef.current = adsWithUnRenderedMedia;
      navigate(
        `/ads-campaign?campaignID=${campaignID}&afterRenderAction=${AFTER_RENDER_ACTION_PUBLISH}`
      );
    }

    return {
      hasRenderFailedMedia: adsWithRenderFailedMedia.length > 0,
      hasUnRenderedMedia: adsWithUnRenderedMedia.length > 0,
    };
  };
  const onValidateAdsWithRenderingMedia = async () => {
    const data = getCurrentData();

    const { adsWithRenderingMedia } = getAdsWithRenderingMedia(data);

    if (adsWithRenderingMedia.length > 0) {
      renderMediaDataRef.current = adsWithRenderingMedia;
    }
    return adsWithRenderingMedia.length > 0;
  };

  const onUpdateProgress = async (renderItemData) => {
    const adIDs = renderItemData.adIDs;
    const videoData = renderItemData.videoData;
    const videoID = videoData.id;
    const newStatus = videoData.status;
    const videoOutput = videoData.videoOutput;

    const data = getCurrentData();
    const adsGroups = data.adsGroups;
    adsGroups.forEach((adsGroup, indexAdGroup) => {
      const ads = adsGroup.ads;

      ads.forEach((ad, indexAd) => {
        if (!adIDs.includes(ad.id)) return;

        ad.mediaData.forEach((mediaDataItem, indexMediaData) => {
          if (mediaDataItem.videoID !== videoID) return;
          setValue(
            `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaData.[${indexMediaData}].status`,
            newStatus
          );
          if (newStatus === VIDEO_DONE_STATUS_CREATIFY) {
            setValue(
              `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaUrls.[${indexMediaData}]`,
              videoOutput || ""
            );
          }
        });
      });
    });

    const currentRenderMe = [...renderMediaDataRef.current];
    currentRenderMe.splice(renderItemData.index, 1);
    if (afterRenderAction === AFTER_RENDER_ACTION_DOWNLOAD && videoOutput) {
      await dispatch(startGetAwsDownloadUrl(videoOutput));
    }
    if (currentRenderMe.length > 0) {
      renderMediaDataRef.current = currentRenderMe;
      return;
    }
    if (afterRenderAction === AFTER_RENDER_ACTION_PUBLISH) {
      onCreateCampaign(null, false);
    }
    renderMediaDataRef.current = [];
  };

  const onCreateCampaign = async (e, withAlertBudget = true) => {
    let showModalConnectAccounts = false;

    platforms.forEach((platform) => {
      const integrationKey = getIntegrationKey(platform, true);

      if (!integrations[integrationKey]) {
        showModalConnectAccounts = true;
      }
    });

    if (showModalConnectAccounts) {
      setModalConnectAccountsOpen(true);
      return;
    }

    const isValidGlobal = await validateFields();
    if (!isValidGlobal) {
      return;
    }
    const data = getCurrentData();

    if (withAlertBudget) {
      const result = await ConfirmAlert({
        confirmButtonText: t(BUTTON_PUBLISH_CAMPAIGN),
        cancelButtonText: t(BUTTON_EDIT_BUDGET),
        html: <ContainerBudgetAlert campaign={data} currency={currency} />,
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    let statusCampaign = ADS_CAMPAIGN_STATUS_ACTIVE;
    // if (!statusToCreateCampaign) {
    //   const resultStatus = await ConfirmAlert({
    //     title: t(ALERT_PUBLISH_ACTIVE_OR_DEACTIVATE_CAMPAIGN_TITLE),
    //     html: (
    //       <Switch
    //         id="switch-status-campaign"
    //         size="medium"
    //         sxLabel={{
    //           ".MuiFormControlLabel-label": {
    //             fontSize: "1.1rem",
    //             fontWeight: 600,
    //           },
    //         }}
    //         labelActive={CREATE_ACTIVATED}
    //         labelDeactivate={CREATE_DEACTIVATED}
    //       />
    //     ),
    //     confirmButtonText: t(BUTTON_CONTINUE),
    //     preConfirm: () => {
    //       const switchElement = document.getElementById(
    //         "switch-status-campaign"
    //       );
    //       return String(switchElement?.checked);
    //     },
    //   });

    //   if (!resultStatus.isConfirmed) {
    //     return;
    //   }

    //   const isActive = resultStatus.value === "true";
    //   statusCampaign = isActive
    //     ? ADS_CAMPAIGN_STATUS_ACTIVE
    //     : ADS_CAMPAIGN_STATUS_DEACTIVATED;
    //   setStatusToCreateCampaign(statusCampaign);
    // }

    setIsValidatingIfNeedRenderMedia(true);
    const responseValidateRenderedMedia =
      await onValidateAdsWithRenderedMedia();
    setIsValidatingIfNeedRenderMedia(false);

    if (!responseValidateRenderedMedia) {
      return;
    }

    const { hasRenderFailedMedia, hasUnRenderedMedia } =
      responseValidateRenderedMedia;

    if (hasRenderFailedMedia) {
      SimpleAlert({
        title: t(ERROR),
        text: t(CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return;
    }
    if (hasUnRenderedMedia) {
      return;
    }

    setIsCreating(true);
    const campaign = {
      ...data,
      status: statusCampaign,
    };

    let response = await dispatch(
      startCreateAdsCampaign({ ...campaign, campaignID })
    );

    setIsCreating(false);

    if (response.ok) {
      onChangeMode(ADS_CAMPAIGN_MODE_EDIT);
      onChangeData(response?.campaign);
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      setDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      setModalErrorsCampaignsOpen(true);
      onChangeData(response?.campaign);
      if (response?.createdCampaigns?.length > 0) {
        onChangeMode(ADS_CAMPAIGN_MODE_EDIT);
      }
    }
  };

  useEffect(() => {
    onValidateAdsWithRenderingMedia();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errors.productContext || errors.targetContext) {
      const element = document?.getElementById("edit-instructions");
      element?.getElementsByClassName("MuiButtonBase-root")?.[0]?.click();
    }
  }, [errors?.productContext, errors?.targetContext]);

  const commonSxAccordion = {
    border: `1px solid ${theme.palette.background.paperSecondary}`,
    borderRadius: BORDER_RADIUS,
    "::before": {
      backgroundColor: "transparent",
    },
    m: "0 !important",
    mb: "16px !important",
  };

  const commonSxMainButtons = {
    fontSize: "1.09rem",
    width: { xs: "100%", sm: 174 },
    minHeight: 46,
  };

  return (
    <>
      {!isGeneralCampaignCreated && (
        <Accordion
          initialValue={true}
          title={t(WHY_THESE_RECOMMENDED_SETTINGS)}
          variantTitle={isMobile ? "body1" : "h6"}
          sx={commonSxAccordion}
          sxTitle={{
            fontWeight: 900,
          }}
          sxContainerTitle={{
            ".MuiAccordionSummary-content": {
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {},
            pt: 0,
          }}
          minHeight={64}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              height: "auto",
            }}
          >
            {isGenerating ? (
              <LinearLoaderWithTime
                time={
                  isAdvancedMode
                    ? TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED
                    : TIME_LOADER_GENERATING_CAMPAIGN_QUICK
                }
                labels={[
                  t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
                  t(CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION),
                  t(CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS),
                  t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
                ]}
              />
            ) : reasoning ? (
              <TypingText
                text={reasoning}
                onCallbackFinish={() => {
                  setIsTyping(false);
                  onCallbackFinishReasoning();
                }}
                sx={{
                  height: 200,
                }}
              />
            ) : null}
          </Box>
        </Accordion>
      )}
      {!isGeneralCampaignCreated && !isGenerating && (
        <Accordion
          id="edit-instructions"
          initialValue={false}
          title={t(EDIT_INSTRUCTIONS)}
          iconTitle={<EditIcon />}
          variantTitle={isMobile ? "body1" : "h6"}
          minHeight={64}
          sx={{
            ...commonSxAccordion,
            mt: 1,
          }}
          sxContainerTitle={{
            ".MuiAccordionSummary-content": {
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {},
            pt: 0,
          }}
          sxTitle={{
            fontWeight: 900,
          }}
        >
          <Typography variant={"body1"} sx={{ mb: 0.5 }}>
            {t(PRODUCT_OR_SERVICE_CONTEXT)}
          </Typography>
          <TextField
            placeholder={t(CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_PLACEHOLDER)}
            name="productContext"
            variant="filled"
            multiline
            minRows={3}
            maxRows={4}
            fullWidth
            disabled={isGeneralCampaignActivated || isGeneralDisabledFields}
            control={control}
            errors={errors}
            sx={{
              ".MuiInputBase-root": {
                pt: 2,
                px: { xs: 1, sm: 2 },
                pb: 1.5,
                ".MuiInputBase-input": {
                  pr: 1,
                },
              },
            }}
            rules={{
              required: {
                value: isAdvancedMode,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (
                  value?.length < ADS_CAMPAIGN_CONTEXT_MIN_LENGTH &&
                  isAdvancedMode
                ) {
                  return t(FIELD_MIN_LENGTH, {
                    value: ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
                  });
                }
                if (
                  value?.length > ADS_CAMPAIGN_CONTEXT_MAX_LENGTH &&
                  isAdvancedMode
                ) {
                  return t(FIELD_MAX_LENGTH, {
                    value: ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
                  });
                }
              },
            }}
          />
          <Typography
            variant={"body1"}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
          >
            {t(TARGET_AUDIENCE_CONTEXT)}
          </Typography>
          <TextField
            placeholder={t(CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_PLACEHOLDER)}
            name="targetContext"
            variant="filled"
            multiline
            minRows={3}
            maxRows={4}
            fullWidth
            disabled={isGeneralCampaignActivated || isGeneralDisabledFields}
            control={control}
            errors={errors}
            sx={{
              ".MuiInputBase-root": {
                pt: 2,
                px: { xs: 1, sm: 2 },
                pb: 1.5,
                ".MuiInputBase-input": {
                  pr: 1,
                },
              },
            }}
            rules={{
              required: {
                value: isAdvancedMode,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (
                  value?.length < ADS_CAMPAIGN_CONTEXT_MIN_LENGTH &&
                  isAdvancedMode
                ) {
                  return t(FIELD_MIN_LENGTH, {
                    value: ADS_CAMPAIGN_CONTEXT_MIN_LENGTH,
                  });
                }
                if (
                  value?.length > ADS_CAMPAIGN_CONTEXT_MAX_LENGTH &&
                  isAdvancedMode
                ) {
                  return t(FIELD_MAX_LENGTH, {
                    value: ADS_CAMPAIGN_CONTEXT_MAX_LENGTH,
                  });
                }
              },
            }}
          />
          {!isGenerating && !isTyping && !isGeneralDisabledFields && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: { xs: "100%", sm: "auto" },
                gap: 1,
                mt: 3,
                mb: 2,
              }}
            >
              <ButtonGenerateAI
                useGradient={false}
                color="white"
                size="large"
                type="button"
                disabled={isCreating}
                textButton={BUTTON_GENERATE_AGAIN}
                onCallback={onGenerateAgain}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              />
            </Box>
          )}
        </Accordion>
      )}
      {(!isGenerating || (hasSomePlatformDraft && !isGenerating)) &&
        !isGeneralDisabledFields && (
          <Box
            sx={{
              position: "fixed",
              bottom: 25,
              right: 20,
              zIndex: 1100,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 1,
              p: 2.5,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(8px)",
              borderRadius: BORDER_RADIUS,
            }}
          >
            <LoadingButton
              type="button"
              disabled={
                isGenerating || isCreating || isValidatingIfNeedRenderMedia
              }
              loading={isSaving}
              onClick={onSaveCampaign}
              sx={commonSxMainButtons}
            >
              {t(BUTTON_SAVE_CHANGES)}
            </LoadingButton>
            <LoadingButton
              type="button"
              disabled={isGenerating || isSaving}
              loading={isCreating || isValidatingIfNeedRenderMedia}
              onClick={onCreateCampaign}
              sx={{
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                ...commonSxMainButtons,
              }}
            >
              {t(BUTTON_PUBLISH_CAMPAIGN)}
            </LoadingButton>
          </Box>
        )}

      {isCreating &&
        createPortal(
          <BackDropLoaderCreatingCampaign
            open={isCreating}
            progressID={`processProgress/${userID}/${campaignID}/`}
          />,
          document.body
        )}

      {isRenderMedia && (
        <BackdropLoaderRenderMediaCreatify
          open={isRenderMedia}
          data={renderMediaDataRef.current}
          onRenderMediaFinished={onUpdateProgress}
          mainLabel={
            afterRenderAction === AFTER_RENDER_ACTION_PUBLISH
              ? CREATIFY_VIDEO_RENDERING_CLARIFICATION_AND_CREATE_CAMPAIGN
              : CREATIFY_VIDEO_RENDERING_CLARIFICATION
          }
          sx={{
            maxWidth: 720,
            width: "100%",
          }}
        />
      )}

      {modalConnectAccountsOpen && (
        <ModalConnectAccounts
          modalOpen={modalConnectAccountsOpen}
          onCloseModal={() => setModalConnectAccountsOpen(false)}
          platforms={platforms}
          onCallbackChangeIntegration={({
            platform,
            integrationID,
            adAccountID,
          }) => {
            setValue(`platformsData.${platform}.integrationID`, integrationID);
            setValue(`platformsData.${platform}.adAccountID`, adAccountID);
          }}
        />
      )}

      {modalErrorsCampaignsOpen && (
        <ModalErrorsCampaigns
          modalOpen={modalErrorsCampaignsOpen}
          onCloseModal={() => setModalErrorsCampaignsOpen(false)}
          campaign={getValues()}
          successCampaigns={dataModalErrorsCampaigns.successCampaigns}
          failedCampaigns={dataModalErrorsCampaigns.failedCampaigns}
          errors={dataModalErrorsCampaigns.errors}
        />
      )}
    </>
  );
};

const BackDropLoaderCreatingCampaign = ({ open, progressID }) => {
  const { t } = useTranslationApp();

  return (
    <Backdrop
      sx={(theme) => ({
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,

        backgroundColor: "rgba(0, 0, 0, 0.8)",
      })}
      open={open}
    >
      <Box
        sx={{
          maxWidth: 720,
          width: "100%",
        }}
      >
        <LinearLoaderWithTime
          progressID={progressID}
          preAddProgress={5}
          labels={[
            t(GETTING_REQUIRED_INFORMATION),
            t(VERIFYING_PLATFORMS_INFORMATION),
            t(CREATING_CAMPAIGN),
            t(CREATING_AD_GROUPS),
            t(UPLOADING_CONTENTS),
            t(CREATING_ADS),
            t(SAVING_INFORMATION),
          ]}
        />
      </Box>
    </Backdrop>
  );
};

export default AdsCampaignReasoningContainer;
