import ButtonGoogleAction from "../components/Buttons/ButtonGoogleAction";
import ButtonLinkedinAction from "../components/Buttons/ButtonLinkedinAction";
import ButtonMetaAction from "../components/Buttons/ButtonMetaAction";
import ButtonTikTokBusinessAction from "../components/Buttons/ButtonTikTokBusinessAction";
import { GOOGLE, LINKEDIN, META, TIKTOK } from "../utils/constants";

const useButtonActionPlatform = () => {
  const BUTTON_ACTIONS_COMPONENTS = {
    [META]: <ButtonMetaAction />,
    [TIKTOK]: <ButtonTikTokBusinessAction />,
    [GOOGLE]: <ButtonGoogleAction />,
    [LINKEDIN]: <ButtonLinkedinAction />,
  };

  return BUTTON_ACTIONS_COMPONENTS;
};

export default useButtonActionPlatform;
