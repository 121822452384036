import i18next from "i18next";
import React, { useEffect, useState } from "react";
import {
  BUTTON_ADD_CONVERSION_ACTION,
  FIELD_REQUIRED,
  INACTIVE,
  LOADING,
} from "../../i18n/keysTranslations";
import { Controller } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslationApp } from "../../lib/i18next";
import { getReactHookFormNestedError } from "../../utils/errors";
import { useDispatchApp } from "../../lib/redux";
import { startGetTrackingCustomEvents } from "../../actions/adsCampaigns";
import { ADS_CAMPAIGN_STATUS_ACTIVE, GOOGLE } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import { openLink } from "../../utils/string";
import { getCreateConversionUrlGoogleAds } from "../../actions/getters";
import _ from "lodash";

const filter = createFilterOptions();
const ID_ADD = "Create Identity";

const SelectConversionActionsGoogle = ({
  control,
  errors,
  rules = {
    required: {
      value: true,
      message: i18next.t(FIELD_REQUIRED),
    },
  },
  name,
  label,
  disabled,
  integrationID,
  adAccountID,
  fullWidth,
  variant = "filled",
  placeholder,
  helperText,
  onChangeAction = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const error = getReactHookFormNestedError(errors, name);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    if (!integrationID || !adAccountID) {
      setOptions([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    let basicOptions = [];

    const response = await dispatch(
      startGetTrackingCustomEvents({
        adAccountID,
        integrationID,
        platform: GOOGLE,
      })
    );

    if (response?.ok && response?.data?.length > 0) {
      const data = response.data;
      basicOptions = data;
    }

    setOptions(basicOptions);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [adAccountID, integrationID]);

  const getLabelOption = (option) => {
    return option.name;
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          loadingText={t(LOADING)}
          loading={loading}
          open={open}
          multiple={true}
          onOpen={() => {
            if (!field.value || _.isEmpty(field.value)) field.onChange([]);

            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(_, value, __, optionSelected) => {
            if (optionSelected.option?.id === ID_ADD) {
              openLink(dispatch(getCreateConversionUrlGoogleAds()));
            } else {
              field.onChange(value);
              onChangeAction(value);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id;
          }}
          getOptionLabel={(option) => {
            const optionValue = options.find((opt) => opt.id === option.id);
            if (optionValue) {
              return `${getLabelOption(optionValue)}`;
            }
            if (typeof option === "string") {
              return option ? t(option) : "";
            }
            return `${getLabelOption(option)}`;
          }}
          options={options}
          disableClearable={true}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            filtered.unshift({
              id: ID_ADD,
              name: t(BUTTON_ADD_CONVERSION_ACTION),
            });

            return filtered;
          }}
          renderOption={(props, option) => {
            const isDisabled =
              option.status !== ADS_CAMPAIGN_STATUS_ACTIVE &&
              option.id !== ID_ADD;
            return (
              <Box
                {...props}
                onClick={(e) => {
                  if (isDisabled) return;
                  props.onClick(e);
                }}
                onMouseOver={(e) => {
                  if (isDisabled) return;
                  props.onMouseOver(e);
                }}
                onTouchStart={(e) => {
                  if (isDisabled) return;
                  props.onTouchStart(e);
                }}
                component="li"
                key={option.value}
                disabled={isDisabled}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  cursor: isDisabled ? "default !important" : "pointer",
                }}
              >
                {option.id === ID_ADD && <AddIcon />}
                <Typography
                  variant="body1"
                  sx={{
                    color: isDisabled ? "text.disabled" : "inherit",
                  }}
                >
                  {getLabelOption(option)}
                </Typography>
                {isDisabled && <Chip label={t(INACTIVE)} size="small" />}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              error={!!error}
              helperText={
                <Box
                  component="span"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{!!error ? error?.message : helperText}</span>
                </Box>
              }
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default SelectConversionActionsGoogle;
