import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import {
  SELECT_A_OPTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION,
  CREATE_ADS_CAMPAIGN_START_END_DATE_QUESTION,
  BUTTON_SELECT_CONTENT,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION,
  CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  BUTTON_NEXT,
  CREATE_ADS_CAMPAIGN_WEBSITE_URL_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION,
  CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION,
  VALUE_EXPRESSED_IN,
  CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  CREATE_ADS_LANGUAGE_QUESTION,
  CREATE_ADS_PLATFORMS_QUESTION,
  ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION,
  CREATE_ADS_CAMPAIGN_TRACKINGS_QUESTION,
  TRACKING_META_ID_PLACEHOLDER,
  TRACKING_TIKTOK_ID_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_TRACKING_QUESTION,
  CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_NAME_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
  PAGE_FACEBOOK_PLACEHOLDER,
  INSTAGRAM_ACCOUNT_PLACEHOLDER,
  IDENTITY_TIK_TOK_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
  CREATE_ADS_CAMPAIGN_MODE_QUESTION,
  CREATE_ADS_CAMPAIGN_BRAND_CONTEXT_QUESTION,
  CREATE_ADS_CAMPAIGN_BRAND_CONTEXT_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_BRAND_CONTEXT_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_SOCIAL_NETWORK_QUESTION,
  CREATE_ADS_CAMPAIGN_SOCIAL_NETWORK_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION,
  CREATE_ADS_CAMPAIGN_LOCATIONS_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_DESTINATION_TYPE_QUESTION,
  CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_QUESTION,
  CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_QUESTION,
  CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { Controller } from "react-hook-form";
import { useMediaQuery } from "@mui/material";
import { useTranslationApp } from "../../../lib/i18next";
import AnswerContainer from "../../../components/TypeForm/AnswerContainer";
import AnswerTitle from "../../../components/TypeForm/AnswerTitle";
import AnswerSubTitle from "../../../components/TypeForm/AnswerSubtitle";
import {
  ADS_CAMPAIGN_CREATION_MODE_ADVANCED,
  ADS_CAMPAIGN_CREATION_MODE_QUICK,
  ADS_CAMPAIGN_MEDIA_FROM_CREATIFY,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  IMAGE_FORMATS,
  META,
  TIKTOK,
  GOOGLE,
  TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED,
  TIME_LOADER_GENERATING_CAMPAIGN_QUICK,
  VIDEO_FORMATS,
  GOOGLE_IMAGE_FORMATS,
  MIN_DIMENSIONS_CONTENT,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
} from "../../../utils/constants";
import { useDispatchApp } from "../../../lib/redux";
import TextField from "../../../components/Form/TextField";
import ActionsButtons from "../../../components/TypeForm/ActionsButtons";
import Select from "../../../components/Form/Select";
import {
  optionsAdsCampaignObjectives,
  optionsAdsCampaignTypeBudgets,
  optionsLanguage,
} from "../../../utils/options";
import IconButtonAddContent from "../../../components/Buttons/IconButtonAddContent";
import LinearLoaderWithTime from "../../../components/Loaders/LinearLoaderWithTime";
import SelectTrackingIDField from "../../../components/Form/SelectTrackingIDField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ModalMedia from "../../../components/Modal/ModalMedia";
import NumberField from "../../../components/Form/NumberField";
import DateField from "../../../components/Form/DateField";
import addDays from "date-fns/addDays";
import CardMedia from "../../../components/Media/CardMedia";
import { getPathDatabaseByRole } from "../../../actions/auth";
import ToggleSelectPlatforms from "./ToggleSelectPlatforms";
import TooltipModal from "../../../components/Tooltip/TooltipModal";
import ContentCommon from "../../../components/Tooltip/ContentCommon";
import AnswerContainerTitleAndField from "../../../components/TypeForm/AnswerContainerTitleAndField";
import SelectTikTokIdentityCustom from "../../../components/Form/SelectTikTokIdentityCustom";
import SelectPageFacebook from "../../../components/Meta/SelectPageFacebook";
import SelectInstagramAccount from "../../../components/Meta/SelectInstagramAccount";
import ToggleSelectCampaignMode from "./ToggleSelectCampaignMode";
import {
  formatMediaUrlAdFromCreatify,
  formatMediaUrl,
  getPlatformsWithTrackingID,
} from "../../../services/adsCampaigns";
import SelectDestinationTypeMeta from "../../../components/Meta/SelectDestinationTypeMeta";
const TRACKING_PLATFORMS_LABELS = {
  [TIKTOK]: TRACKING_TIKTOK_ID_PLACEHOLDER,
  [META]: TRACKING_META_ID_PLACEHOLDER,
  [GOOGLE]: TRACKING_META_ID_PLACEHOLDER,
};

const getFormatsByPlatforms = (platforms) => {
  if (platforms.length === 0) return VIDEO_FORMATS.concat(IMAGE_FORMATS);
  const formats = [];

  if (platforms.includes(META)) return VIDEO_FORMATS.concat(IMAGE_FORMATS);

  if (platforms.includes(TIKTOK)) {
    formats.push(...VIDEO_FORMATS);
  }

  if (platforms.includes(GOOGLE) && platforms.length === 1) {
    formats.push(...GOOGLE_IMAGE_FORMATS);
  }
  if (platforms.includes(GOOGLE) && platforms.length > 1) {
    formats.push(...IMAGE_FORMATS);
  }
  return formats;
};

const CreateAdsCampaignForm = ({
  campaignID,
  currentAnswer,
  stepVisible,
  setValue,
  error,
  onNextStep,
  onPreviousStep,
  control,
  getValues,
  watch,
  isCreating,
  onChangePixels,
  showBrandContextQuestion,
}) => {
  const [mediaUrlSelected, setMediaUrlSelected] = useState(null);
  const [modalMediaOpen, setModalMediaOpen] = useState(false);

  const mode = watch("mode");
  const platformsData = watch("platformsData");
  const mediaUrls = watch("mediaUrls");
  const platforms = watch("platforms");
  const websiteURL = watch("websiteURL");
  const socialNetworkURL = watch("socialNetworkURL");
  const objective = watch("objective");
  const language = watch("language");
  const productContext = watch("productContext");
  const targetContext = watch("targetContext");
  const isMultiPlatform = platforms.length > 1;
  const typeBudget = watch("typeBudget");
  const startDate = watch("startDate");
  const { t } = useTranslationApp();

  const dispatch = useDispatchApp();
  const { userID } = dispatch(getPathDatabaseByRole());

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isAnswerQuickMode = mode === ADS_CAMPAIGN_CREATION_MODE_QUICK;
  const isAnswerAdvancedMode = mode === ADS_CAMPAIGN_CREATION_MODE_ADVANCED;

  const platformsWithRequiredPixels = getPlatformsWithTrackingID({
    platforms,
    platformsData,
    objective,
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnswerContainer
        stepPosition={"mode"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerContainerTitleAndField>
          <AnswerTitle sx={{ mb: 1 }}>
            {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_MODE_QUESTION)}`}
          </AnswerTitle>
          {!!error && error.step === "mode" && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error.text}
            </Alert>
          )}
          <ToggleSelectCampaignMode
            onClickMode={(mode) => {
              setValue("mode", mode);
              onNextStep("mode", mode);
            }}
          />
        </AnswerContainerTitleAndField>
      </AnswerContainer>
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"name"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_NAME_QUESTION)}`}
            </AnswerTitle>
            {!!error && error.step === "name" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}

            <TextField
              control={control}
              name="name"
              fullWidth
              placeholder={t(CREATE_ADS_CAMPAIGN_NAME_PLACEHOLDER)}
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            showPreviousButton={true}
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("name", getValues("name"))}
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"platforms"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(CREATE_ADS_PLATFORMS_QUESTION)}`}
            </AnswerTitle>
            {!!error && error.step === "platforms" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <ToggleSelectPlatforms
              name="platforms"
              control={control}
              rules={{}}
              onChangeIntegrationID={({ platform, integrationID }) => {
                setValue(
                  `platformsData.${platform}.integrationID`,
                  integrationID
                );
              }}
              onChangeAdAccount={({ platform, adAccountID }) => {
                setValue(`platformsData.${platform}.adAccountID`, adAccountID);
                setValue(`platformsData.${platform}.trackingID`, "");
                setValue(`platformsData.${platform}.trackingCustomEventID`, "");
                if (platform === META) {
                  setValue(`platformsData.${platform}.pageID`, "");
                  setValue(`platformsData.${platform}.instagramID`, "");
                }
                if (platform === TIKTOK) {
                  setValue(`platformsData.${platform}.identityTikTokID`, "");
                }
              }}
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("platforms", getValues("platforms"))}
          />
        </AnswerContainer>
      )}

      <AnswerContainer
        stepPosition={"objective"}
        step={currentAnswer}
        sx={{ display: "flex" }}
      >
        <AnswerContainerTitleAndField>
          <AnswerTitle sx={{ mb: 1 }}>
            {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION)}`}
          </AnswerTitle>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t(CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION)}
          </Typography>
          {!!error && error.step === "objective" && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error.text}
            </Alert>
          )}
          <Select
            options={optionsAdsCampaignObjectives}
            size="small"
            name="objective"
            displayEmpty={true}
            showEmptyOption={true}
            fullWidth
            placeholder={t(SELECT_A_OPTION)}
            doTranslate={true}
            control={control}
            actionsChange={() => {
              platforms.forEach((platform) => {
                setValue(`platformsData.${platform}.trackingCustomEventID`, "");
                setValue(
                  `destinationType`,
                  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
                );
                if (platform === META) {
                  setValue(
                    `platformsData.${platform}.destinationType`,
                    ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
                  );
                }
              });
            }}
          />
        </AnswerContainerTitleAndField>
        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onNextStep={() => onNextStep("objective", getValues("objective"))}
        />
      </AnswerContainer>

      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"typeBudget"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_TYPE_BUDGET_QUESTION)}`}
            </AnswerTitle>
            {!!error && error.step === "typeBudget" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <Select
              name="typeBudget"
              fullWidth
              variant="standard"
              placeholder={t(SELECT_A_OPTION)}
              displayEmpty={true}
              showEmptyOption={true}
              doTranslate={true}
              options={optionsAdsCampaignTypeBudgets}
              control={control}
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("typeBudget", getValues("typeBudget"))}
          />
        </AnswerContainer>
      )}
      {(isAnswerAdvancedMode || isAnswerQuickMode) && (
        <AnswerContainer
          stepPosition={"budget"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
                  ? CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION
                  : CREATE_ADS_CAMPAIGN_BUDGET_TOTAL_QUESTION
              )}`}
            </AnswerTitle>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {t(CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION)}
            </Typography>
            {!!error && error.step === "budget" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              name="budget"
              fullWidth
              placeholder={t(CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER)}
              variant="standard"
              InputProps={{
                inputComponent: NumberField,
              }}
              helperText={t(VALUE_EXPRESSED_IN, {
                currency: "USD",
              })}
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("budget", getValues("budget"))}
          />
        </AnswerContainer>
      )}
      {(isAnswerAdvancedMode || isAnswerQuickMode) && (
        <AnswerContainer
          stepPosition={"locations"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_LOCATIONS_QUESTION)}`}
            </AnswerTitle>
            {!!error && error.step === "locations" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              name="locations"
              fullWidth
              placeholder={t(CREATE_ADS_CAMPAIGN_LOCATIONS_PLACEHOLDER)}
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("locations", getValues("locations"))}
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"dates"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_START_END_DATE_QUESTION
              )}`}
            </AnswerTitle>
            {!!error && error.step === "dates" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <DateField
              variant="standard"
              minDate={new Date()}
              name="startDate"
              label={t(START_DATE_PLACEHOLDER)}
              control={control}
              sx={{ mb: 2 }}
            />
            <DateField
              variant="standard"
              name="endDate"
              minDate={addDays(startDate || new Date(), 1)}
              label={t(END_DATE_PLACEHOLDER)}
              control={control}
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("dates", {
                startDate: getValues("startDate"),
                endDate: getValues("endDate"),
              })
            }
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"identities"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION
              )}`}
            </AnswerTitle>
            {!!error && error.step === "identities" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: 1.5,
              }}
            >
              {platforms.includes(META) && (
                <>
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="body1">
                      {t(PAGE_FACEBOOK_PLACEHOLDER)}
                    </Typography>
                    <SelectPageFacebook
                      variant="standard"
                      control={control}
                      integrationID={platformsData[META]?.integrationID}
                      adAccountID={platformsData[META]?.adAccountID}
                      name={`platformsData.${META}.pageID`}
                      placeholder={t(SELECT_A_OPTION)}
                      fullWidth={true}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="body1">
                      {t(INSTAGRAM_ACCOUNT_PLACEHOLDER)}
                    </Typography>
                    <SelectInstagramAccount
                      variant="standard"
                      control={control}
                      integrationID={platformsData[META]?.integrationID}
                      adAccountID={platformsData[META]?.adAccountID}
                      pageID={platformsData[META]?.pageID}
                      name={`platformsData.${META}.instagramID`}
                      placeholder={t(SELECT_A_OPTION)}
                      fullWidth={true}
                    />
                  </Box>
                </>
              )}
              {platforms.includes(TIKTOK) && (
                <Box sx={{ width: "100%" }}>
                  <Typography variant="body1">
                    {t(IDENTITY_TIK_TOK_PLACEHOLDER)}
                  </Typography>
                  <SelectTikTokIdentityCustom
                    variant="standard"
                    control={control}
                    integrationID={platformsData[TIKTOK]?.integrationID}
                    adAccountID={platformsData[TIKTOK]?.adAccountID}
                    name={`platformsData.${TIKTOK}.identityTikTokID`}
                    placeholder={t(SELECT_A_OPTION)}
                    fullWidth={true}
                  />
                </Box>
              )}
            </Box>
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("identities", getValues("platformsData"))
            }
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"trackingIDs"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                isMultiPlatform
                  ? CREATE_ADS_CAMPAIGN_TRACKINGS_QUESTION
                  : CREATE_ADS_CAMPAIGN_TRACKING_QUESTION
              )}`}
            </AnswerTitle>
            {!!error && error.step === "trackingIDs" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: 1.5,
              }}
            >
              {platformsWithRequiredPixels.map((platform) => (
                <Box
                  key={platform}
                  sx={{
                    width: "100%",
                    mb: 1,
                  }}
                >
                  <Typography variant="body1">
                    {t(TRACKING_PLATFORMS_LABELS[platform])}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <SelectTrackingIDField
                      control={control}
                      name={`platformsData.${platform}.trackingID`}
                      placeholder={t(SELECT_A_OPTION)}
                      fullWidth
                      variant="standard"
                      platform={platform}
                      integrationID={platformsData[platform]?.integrationID}
                      adAccountID={platformsData[platform]?.adAccountID}
                      onLoadOptions={(newPixels) =>
                        onChangePixels((currentPixels) => {
                          let data = { ...currentPixels };

                          if (!data[platform]) {
                            data[platform] = [];
                          }
                          data[platform] = [...newPixels, ...data[platform]];

                          return data;
                        })
                      }
                    />
                    <TooltipModal
                      sx={{
                        mt: 0.5,
                      }}
                      title={t(ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION)}
                      content={
                        <ContentCommon
                          text={ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION}
                        />
                      }
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("trackingIDs", getValues("platformsData"))
            }
          />
        </AnswerContainer>
      )}
      <AnswerContainer stepPosition={"mediaUrls"} step={currentAnswer}>
        <AnswerContainerTitleAndField>
          <AnswerTitle sx={{ mb: mediaUrls?.length > 0 || error ? 1 : -2 }}>
            {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_MEDIA_URL_QUESTION)}`}
          </AnswerTitle>
          {!!error && error.step === "mediaUrls" && (
            <Alert severity="error">{error.text}</Alert>
          )}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              overflowX: "auto",
            }}
          >
            {mediaUrls.length > 0 &&
              mediaUrls.map((mediaUrl, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 1,
                    mb: 1,
                    minHeight: 160,
                    minWidth: 110,
                    height: 160,
                    width: 110,
                    position: "relative",
                  }}
                >
                  {mediaUrl?.mediaData?.from ===
                  ADS_CAMPAIGN_MEDIA_FROM_CREATIFY ? (
                    <iframe
                      src={mediaUrl?.mediaData?.preview}
                      style={{
                        display: "block",
                        border: "none",
                        overflow: "hidden",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <CardMedia
                      url={mediaUrl.url}
                      propsType={{
                        controls: false,
                        autoplay: true,
                      }}
                    />
                  )}
                  <IconButton
                    onClick={() => {
                      setValue(
                        "mediaUrls",
                        mediaUrls.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      p: 0,
                      mt: error ? 2 : 0,
                    }}
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </Box>
              ))}
          </Box>
        </AnswerContainerTitleAndField>
        {isMobile && mediaUrls.length > 0 ? (
          <Controller
            name="mediaUrls"
            control={control}
            render={({ field }) => (
              <IconButtonAddContent
                mode="button"
                color="secondary"
                size="large"
                minDimensions={MIN_DIMENSIONS_CONTENT}
                pathStorage={`adsCampaigns/${userID}/`}
                pathRTDB={`adsContentsUploaded/${userID}/${campaignID}`}
                modeUpload="async"
                onAddContent={(contents) => {
                  field.onChange([...mediaUrls, formatMediaUrl(contents[0])]);
                }}
                onCallbackSaveAI={(videoData) => {
                  field.onChange([
                    ...mediaUrls,
                    formatMediaUrlAdFromCreatify(videoData),
                  ]);
                }}
                multiple={false}
                acceptedFormats={getFormatsByPlatforms(platforms)}
                textButton={BUTTON_SELECT_CONTENT}
                sx={{
                  mt: 2,
                  alignItems: "flex-start",
                  width: "100%",
                }}
                sxButton={{
                  height: 40,
                  fontSize: "0.875rem",
                  width: "100%",
                }}
                propsGalleryAdsPlatforms={{
                  integrationID: platformsData[META]?.integrationID,
                  adAccountID: platformsData[META]?.adAccountID,
                  socialNetworkURL,
                }}
                propsGenerateAI={{
                  websiteURL,
                  socialNetworkURL,
                  language,
                  campaignContext: {
                    objective,
                    productContext,
                    targetContext,
                  },
                  integrationID: platformsData[META]?.integrationID,
                  adAccountID: platformsData[META]?.adAccountID,
                }}
              />
            )}
          />
        ) : null}

        <ActionsButtons
          onPreviousStep={onPreviousStep}
          onFinish={() => onNextStep("mediaUrls", getValues("mediaUrls"), true)}
          saveButtonText={BUTTON_NEXT}
          showSaveButton={mediaUrls.length > 0}
          showNextButton={false}
          showLoadingComponent={true}
          loading={isCreating}
          customComponentLoading={
            <LoaderComponent
              userID={userID}
              campaignID={campaignID}
              mode={mode}
            />
          }
          customComponentPreviousNext={
            !isMobile || (isMobile && mediaUrls.length === 0) ? (
              <Controller
                name="mediaUrls"
                control={control}
                render={({ field }) => (
                  <IconButtonAddContent
                    mode="button"
                    color="secondary"
                    size="large"
                    minDimensions={MIN_DIMENSIONS_CONTENT}
                    pathStorage={`adsCampaigns/${userID}/`}
                    pathRTDB={`adsContentsUploaded/${userID}/${campaignID}`}
                    modeUpload="async"
                    onAddContent={(contents) => {
                      field.onChange([
                        ...mediaUrls,
                        formatMediaUrl(contents[0]),
                      ]);
                    }}
                    onCallbackSaveAI={(videoData) => {
                      field.onChange([
                        ...mediaUrls,
                        formatMediaUrlAdFromCreatify(videoData),
                      ]);
                    }}
                    multiple={false}
                    acceptedFormats={getFormatsByPlatforms(platforms)}
                    textButton={BUTTON_SELECT_CONTENT}
                    sx={{
                      mt: 2,
                      alignItems: "flex-start",
                      width: isMobile ? "100%" : "auto",
                    }}
                    sxButton={{
                      height: 40,
                      fontSize: "0.875rem",
                      width: isMobile ? "100%" : "auto",
                    }}
                    propsGalleryAdsPlatforms={{
                      integrationID: platformsData[META]?.integrationID,
                      adAccountID: platformsData[META]?.adAccountID,
                      socialNetworkURL,
                    }}
                    propsGenerateAI={{
                      websiteURL,
                      socialNetworkURL,
                      language,
                      campaignContext: {
                        objective,
                        productContext,
                        targetContext,
                      },
                      integrationID: platformsData[META]?.integrationID,
                      adAccountID: platformsData[META]?.adAccountID,
                    }}
                  />
                )}
              />
            ) : null
          }
        />
      </AnswerContainer>

      {(isAnswerAdvancedMode || isAnswerQuickMode) && (
        <AnswerContainer
          stepPosition={"websiteURL"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(CREATE_ADS_CAMPAIGN_WEBSITE_URL_QUESTION)}`}
            </AnswerTitle>
            {!!error && error.step === "websiteURL" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              name="websiteURL"
              fullWidth
              placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            showPreviousButton={true}
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("websiteURL", getValues("websiteURL"))}
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"destinationType"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_DESTINATION_TYPE_QUESTION
              )}`}
            </AnswerTitle>
            {!!error && error.step === "destinationType" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <SelectDestinationTypeMeta
              control={control}
              name="destinationType"
              fullWidth
              variant="standard"
              placeholder={SELECT_A_OPTION}
              objective={objective}
              actionsChange={(value) => {
                setValue(`platformsData.${META}.destinationType`, value);
              }}
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("destinationType", getValues("destinationType"))
            }
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"destinationURL"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION
              )}`}
            </AnswerTitle>
            {!!error && error.step === "destinationURL" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              name="destinationURL"
              fullWidth
              placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("destinationURL", getValues("destinationURL"))
            }
          />
        </AnswerContainer>
      )}
      {isAnswerAdvancedMode && (
        <AnswerContainer
          stepPosition={"language"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(CREATE_ADS_LANGUAGE_QUESTION)}`}
            </AnswerTitle>
            {!!error && error.step === "language" && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error.text}
              </Alert>
            )}
            <Select
              name="language"
              fullWidth
              variant="standard"
              placeholder={t(SELECT_A_OPTION)}
              displayEmpty={true}
              showEmptyOption={true}
              doTranslate={true}
              control={control}
              options={optionsLanguage}
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() => onNextStep("language", getValues("language"))}
          />
        </AnswerContainer>
      )}
      {(isAnswerAdvancedMode || isAnswerQuickMode) && (
        <AnswerContainer
          stepPosition={"productContext"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_QUESTION
              )}`}
            </AnswerTitle>
            <AnswerSubTitle>
              {t(CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_DESCRIPTION)}
            </AnswerSubTitle>
            {!!error && error.step === "productContext" && (
              <Alert severity="error" sx={{ my: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              multiline
              minRows={isMobile ? 2 : 4}
              maxRows={isMobile ? 3 : 5}
              name="productContext"
              placeholder={t(CREATE_ADS_CAMPAIGN_PRODUCT_CONTEXT_PLACEHOLDER)}
              fullWidth
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("productContext", getValues("productContext"))
            }
          />
        </AnswerContainer>
      )}
      {(isAnswerAdvancedMode || isAnswerQuickMode) && (
        <AnswerContainer
          stepPosition={"targetContext"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_QUESTION
              )}`}
            </AnswerTitle>
            <AnswerSubTitle>
              {t(CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_DESCRIPTION)}
            </AnswerSubTitle>
            {!!error && error.step === "targetContext" && (
              <Alert severity="error" sx={{ my: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              multiline
              minRows={isMobile ? 2 : 4}
              maxRows={isMobile ? 3 : 5}
              name="targetContext"
              placeholder={t(CREATE_ADS_CAMPAIGN_TARGET_CONTEXT_PLACEHOLDER)}
              fullWidth
              variant="standard"
            />
          </AnswerContainerTitleAndField>

          <ActionsButtons
            saveButtonText={BUTTON_NEXT}
            showNextButton={true}
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("targetContext", getValues("targetContext"))
            }
          />
        </AnswerContainer>
      )}
      {(isAnswerAdvancedMode || isAnswerQuickMode) && (
        <AnswerContainer
          stepPosition={"socialNetworkURL"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_SOCIAL_NETWORK_QUESTION
              )}`}
            </AnswerTitle>
            {!!error && error.step === "socialNetworkURL" && (
              <Alert severity="error" sx={{ my: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              name="socialNetworkURL"
              placeholder={t(CREATE_ADS_CAMPAIGN_SOCIAL_NETWORK_PLACEHOLDER)}
              fullWidth
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("socialNetworkURL", getValues("socialNetworkURL"))
            }
          />
        </AnswerContainer>
      )}
      {showBrandContextQuestion && isAnswerQuickMode && (
        <AnswerContainer
          stepPosition={"brandContext"}
          step={currentAnswer}
          sx={{ display: "flex" }}
        >
          <AnswerContainerTitleAndField>
            <AnswerTitle sx={{ mb: 1 }}>
              {`${stepVisible}. ${t(
                CREATE_ADS_CAMPAIGN_BRAND_CONTEXT_QUESTION
              )}`}
            </AnswerTitle>
            <AnswerSubTitle>
              {t(CREATE_ADS_CAMPAIGN_BRAND_CONTEXT_DESCRIPTION)}
            </AnswerSubTitle>
            {!!error && error.step === "brandContext" && (
              <Alert severity="error" sx={{ my: 1 }}>
                {error.text}
              </Alert>
            )}
            <TextField
              control={control}
              multiline
              minRows={isMobile ? 2 : 4}
              maxRows={isMobile ? 3 : 5}
              name="brandContext"
              placeholder={t(CREATE_ADS_CAMPAIGN_BRAND_CONTEXT_PLACEHOLDER)}
              fullWidth
              variant="standard"
            />
          </AnswerContainerTitleAndField>
          <ActionsButtons
            onPreviousStep={onPreviousStep}
            onNextStep={() =>
              onNextStep("brandContext", getValues("brandContext"))
            }
            saveButtonText={BUTTON_NEXT}
          />
        </AnswerContainer>
      )}
      {modalMediaOpen && (
        <ModalMedia
          url={mediaUrlSelected.url}
          modalOpen={modalMediaOpen}
          onCloseModal={(e) => {
            e?.stopPropagation();
            setMediaUrlSelected(null);
            setModalMediaOpen(false);
          }}
          disabledActions={true}
        />
      )}
    </Box>
  );
};

const LoaderComponent = ({ mode }) => {
  const { t } = useTranslationApp();
  return (
    <LinearLoaderWithTime
      time={
        mode === ADS_CAMPAIGN_CREATION_MODE_QUICK
          ? TIME_LOADER_GENERATING_CAMPAIGN_QUICK
          : TIME_LOADER_GENERATING_CAMPAIGN_ADVANCED
      }
      labels={[
        t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
        t(CREATE_ADS_CAMPAIGN_LOADING_ANALYZING_INFORMATION),
        t(CREATE_ADS_CAMPAIGN_LOADING_GENERATING_RECOMMENDATIONS),
        t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
      ]}
      sx={{ mt: 1.5 }}
    />
  );
};

export default CreateAdsCampaignForm;
