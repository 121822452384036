import { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "../Form/TextField";
import {
  FIELD_REQUIRED,
  GOOGLE,
  GOOGLE_KEYWORDS_EXPLANATION,
  SEARCH_KEYWORDS_PLACEHOLDER,
} from "../../i18n/keysTranslations";
import { useController, useForm } from "react-hook-form";
import SearchSegmentationsField from "../Form/SearchSegmentationsField";
import { useTranslationApp } from "../../lib/i18next";
import { ADS_CAMPAIGN_SEARCH_SEGMENTATION_KEYWORDS_TYPE } from "../../utils/constants";
import { useDebounced } from "../../hooks/useDebounce";
import Typography from "@mui/material/Typography";
import TooltipModal from "../Tooltip/TooltipModal";
import ContentCommon from "../Tooltip/ContentCommon";
import { getReactHookFormNestedError } from "../../utils/errors";

const KeywordsGoogleContainer = ({
  label,
  name,
  variant,
  disabled = false,
  fullWidth,
  adAccountID,
  integrationID,
  language,
  control,
  errors,
  rules,
}) => {
  const { t } = useTranslationApp();
  const { control: controlKeywords, setValue } = useForm({
    defaultValues: {
      keywordsInput: "",
      keywordsSearch: [],
    },
  });

  const {
    field: { value: keywords, onChange: keywordsOnChange },
  } = useController({
    name,
    control,
    rules,
  });

  const error = getReactHookFormNestedError(errors, name);

  useEffect(() => {
    onUpdateKeywordsInput(keywords);
    // eslint-disable-next-line
  }, [keywords]);

  const onUpdateKeywordsInput = (value) => {
    const text = (value || []).map((keyword) => keyword).join("\n");
    setValue("keywordsInput", text);
  };
  const onUpdateKeywords = useDebounced(
    (value) => {
      let keywordsProcessed = value
        .split(/[\n,]+/)
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword.length > 0);

      keywordsOnChange(keywordsProcessed);
    },
    [1000]
  );

  const onChangeKeywordsInput = (value) => {
    setValue("keywordsInput", value);
    onUpdateKeywords(value);
  };
  const onChangeSearchKeywords = (value) => {
    const keyWord = value?.[0]?.id;

    if (!keyWord) return;

    const founded = keywords.find((keyword) => keyword === keyWord);
    if (founded) return;

    keywordsOnChange([...keywords, keyWord]);
    onUpdateKeywordsInput([...keywords, keyWord]);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Typography variant="body1" gutterBottom>
          {label}
        </Typography>
        <TooltipModal
          title={label}
          content={<ContentCommon text={GOOGLE_KEYWORDS_EXPLANATION} />}
        />
      </Box>
      <TextField
        name={"keywordsInput"}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        multiline
        minRows={10}
        maxRows={10}
        doOnChange={false}
        actionsChange={onChangeKeywordsInput}
        control={controlKeywords}
      />
      {error?.message && (
        <Typography variant="caption" color="error" name={name}>
          {error.message}
        </Typography>
      )}
      <SearchSegmentationsField
        label={t(SEARCH_KEYWORDS_PLACEHOLDER)}
        name={`keywordsSearch`}
        type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_KEYWORDS_TYPE}
        mode={"fetching"}
        platform={GOOGLE}
        adAccountID={adAccountID}
        integrationID={integrationID}
        language={language}
        fullWidth
        variant="filled"
        doChange={false}
        actionsChange={onChangeSearchKeywords}
        disabled={disabled}
        sx={{
          ".MuiFormControl-root": {
            ".MuiInputBase-root": {
              height: "60px",
            },
          },
        }}
        control={controlKeywords}
        errors={errors}
        rules={{
          required: {
            value: false,
            message: t(FIELD_REQUIRED),
          },
        }}
      />
    </Box>
  );
};

export default KeywordsGoogleContainer;
