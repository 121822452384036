import i18next from "i18next";
import {
  AUTH_EMAIL_ALREADY_EXISTS,
  AUTH_INVALID_PASSWORD,
  AUTH_MANY_REQUESTS,
  AUTH_USER_NOT_FOUND,
  AUTH_WRONG_PASSWORD,
  ERROR_INTERNAL,
  FIELD_EMAIL_VALID,
  STRIPE_CARD_DECLINED,
  STRIPE_EXPIRED_CARD,
  STRIPE_INCORRECT_CVC,
  STRIPE_PROCESSING_ERROR,
  STRIPE_INCORRECT_NUMBER,
  STRIPE_GENERIC_DECLINE,
  STRIPE_INSUFFICIENT_FUNDS,
  STRIPE_LOST_CARD,
  STRIPE_STOLEN_CARD,
  STRIPE_PROFILE_NOT_FOUND,
  STRIPE_INTENT_AUTH_FAILURE,
  NIT_VALID,
  BUSINESS_NAME_VALID,
  BRAND_NAME_VALID,
  ADDRESS_VALID,
  CATEGORY_VALID,
  SUBCATEGORIES_VALID,
  SHORT_DESCRIPTION_VALID,
  ORDERS_METHODS_VALID,
  LOGO_IMG_VALID,
  COVER_IMG_VALID,
  SCHEDULE_VALID,
  STORES_VALID,
  IS_PLAN_ACTIVE_VALID,
  MENUS_VALID,
  DEFAULT_PAYMENT_METHOD_VALID,
  HAS_DEBT_VALID,
  SUBSCRIPTION_PLAN_VALID,
  PROFILE_INFLUENCER_VALID,
  MISSING_PARAMETERS,
  PASSWORD_INVALID,
  USER_ID_REQUIRED,
  SHOP_ID_REQUIRED,
  LAST_USER_NOT_DELETED,
  PLAN_TRIAL_USER_USED,
  ORDERS_NOT_FOUND_IN_INTERVAL,
  TYPE_REPORT_NOT_FOUND,
  SHOP_NOT_FOUND,
  PAYMENT_MODEL_INVALID,
  SHOP_NOT_HAS_SOCIAL_NETWORK,
  NO_VALID_PLATFORM_FOUND,
  GETTING_STATISTICS,
  SHOP_BUSINESS_NAME_NOT_FOUND,
  INFLUENCERS_SUGGESTED_NOT_FOUND,
  REFERENCE_PROFILES_REQUIRED,
  INFLUENCERS_SIMILAR_NOT_FOUND,
  DECRYPTING_PASSWORD,
  USER_ID_AND_PAYMENT_ID,
  MESSAGES_REQUIRED,
  PLAN_IS_NO_ACTIVE,
  USER_BANNED,
  GETTING_LOCALES,
  GETTING_PROFILE,
  PRODUCTS_NOT_SENT,
  INVITATION_NOT_FOUND,
  TOTAL_PRICE_EXCEEDED_VALUE_INVITATION,
  INFLUENCER_NOT_FOUND,
  ACCOUNT_NOT_FOUND,
  COLLECTING_INFORMATION_RETRY_AFTER,
  ERROR_OCCURRED,
  PLATFORM_NOT_SENT,
  VERIFICATION_CODE_NOT_FOUND,
  ORDER_NOT_FOUND,
  INVITE_ID_NO_SENT,
  INVITE_NOT_FOUND,
  INVITE_CLOUD_TASK_ID_NOT_FOUND,
  TYPE_OR_PATH_NOT_SENT,
  ID_OR_PATH_NOT_SENT,
  IDS_NOT_SENT,
  ANALYTIC_URL_NOT_SENT,
  ANALYTIC_URL_INVALID,
  EXTRACTING_ANALYTICS,
  CREDENTIALS_INVALID,
  COLLABORATION_PRICE_NOT_BE_NEGATIVE,
  CREDITS_NOT_BE_NEGATIVE,
  PACKAGES_PRICES_NOT_BE_NEGATIVE,
  ROL_REQUIRED,
  SHOP_ID_AND_STORE_ID_REQUIRED,
  PASSWORD_MUST_BE_6_CHARACTERS,
  ALL_USERS_NOT_DELETE,
  SHOP_APPLICANT_NOT_FOUND,
  USER_PASSWORD_NOT_FOUND,
  PLAN_NOT_FOUND,
  SUBSCRIPTION_NOT_CREATED,
  ID_SUBSCRIPTION_NOT_FOUND,
  SUBSCRIPTION_REACTIVATE,
  CUSTOMER_ID_NOT_FOUND,
  APPLICANT_NOT_FOUND,
  SHOP_COUNTRY_NOT_FOUND,
  FINAL_PRICE_NOT_FOUND,
  PAYMENT_FAILED,
  CREDITS_MUST_BE_GREATER_THAN_ZERO,
  AMBASSADOR_NOT_FOUND,
  CSRF_INVALID,
  INTEGRATION_INFORMATION_NOT_FOUND,
  GETTING_VIDEO,
  UPLOAD_URL_NOT_FOUND,
  UPLOADING_VIDEO,
  STORE_NOT_FOUND,
  NAME_REQUIRED,
  PRICE_REQUIRED,
  CURRENCY_REQUIRED,
  RECURRING_REQUIRED,
  CREATING_PRODUCT,
  CREATING_PRICE,
  CREATING_PAYMENT_LINK,
  GETTING_PAYMENT_LINK,
  TOKEN_INVALID,
  TOKEN_EXPIRED,
  IG_ACCOUNT_NOT_FOUND,
  IG_DATA_NOT_FOUND,
  REVIEW_NOT_FOUND,
  REVIEW_IS_NOT_ONE_START,
  DISSATISFACTION_CREDIT_ALREADY_EXISTS,
  AUTOMATIC_INVITATIONS_VALID,
  META_INTEGRATION_UNLINKED_PAGES_ERROR,
  PAYMENT_METHOD_NOT_FOUND,
  HAS_PENDING_PAYMENTS,
  NO_IMAGES_FOUND_ON_PAGE,
  NO_CREATE_DISCOUNT_SHOPIFY,
  NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY,
  NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY,
  NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY,
  GETTING_INFORMATION_FROM_SHOPIFY_URL,
  RATE_DAILY_LIMIT_AI,
  PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META,
  ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS,
  FACEBOOK_PAGE_ID_NOT_FOUND,
  META_BUSINESS_ACCOUNT_NOT_FOUND,
  IG_BUSINESS_ACCOUNT_NOT_FOUND,
  ACCOUNT_TIKTOK_AUTH_NOT_MATCH,
  ACCOUNT_TOKEN_META_NOT_FOUND,
  ACCOUNT_ID_BUSINESS_META_NOT_FOUND,
  OBJECTIVE_CAMPAIGN_META_INVALID,
  SPECIAL_CATEGORY_CAMPAIGN_META_INVALID,
  CAMPAIGN_META_NOT_DELETED,
  CAMPAIGN_META_NOT_CREATED,
  ADS_CAMPAIGN_NOT_FOUND,
  NO_PIXELS_FOUND,
  NO_PIXELS_ACTIVE,
  CAMPAIGN_META_NOT_FOUND,
  CAMPAIGN_META_NOT_UPDATED,
  CAMPAIGN_AD_SET_META_NOT_CREATED,
  CAMPAIGN_AD_SET_META_NOT_UPDATED,
  CAMPAIGN_MEDIA_META_NOT_CREATED,
  CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND,
  CAMPAIGN_CREATIVE_AD_META_NOT_CREATED,
  CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED,
  CAMPAIGN_CREATIVE_AD_META_NOT_FOUND,
  CAMPAIGN_AD_META_NOT_CREATED,
  CAMPAIGN_AD_META_NOT_UPDATED,
  CAMPAIGN_METRICS_META_NOT_FOUND,
  CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  CAMPAIGN_AUDIENCE_IS_TOO_NARROW,
  CAMPAIGN_BUDGET_IS_TOO_LOW,
  IDENTITY_NOT_FOUND,
  VIDEO_ERROR_LOW_RESOLUTION,
  VIDEO_ERROR_NO_BACKGROUND_AUDIO,
  VIDEO_ERROR_ASPECT_RATIO,
  VIDEO_ERROR_BLACK_EDGE,
  VIDEO_ERROR_ILLEGAL_DURATION,
  AUTH_INVALID_VERIFICATION_CODE,
  ERROR_GETTING_URL_INFORMATION_BY_PROTECTED,
  ERROR,
  AUTH_INVALID_APP_CREDENTIAL,
  META_AD_ACCOUNT_IS_NOT_ACTIVE,
  META_AD_ACCOUNT_HAVE_NOT_PAYMENT_METHOD,
  ADVERTISER_ID_NOT_FOUND,
  ADVERTISER_CURRENCY_NOT_FOUND,
  ACCOUNT_ROLE_INVALID,
  ERROR_GETTING_URL_META_DATA_AND_IMAGES,
  ADVERTISER_ID_TO_CHANGE_NO_PERMISSION,
  SCRIPT_IS_TOO_SHORT,
  WEBSITE_AND_SOCIAL_NETWORK_CANT_ANALYZED,
  EMAIL_PROVIDER_IS_NOT_EQUAL_TO_EMAIL_FORM,
  OAUTH_IN_WINDOW_NO_COMPLETED,
  ERROR_PROCESSING_REQUEST,
  NOT_HAVE_ENOUGH_USAGE_VALUE,
  ERROR_NETWORK_CONNECTION,
  REQUEST_TIME_OUT,
  FACEBOOK_PAGE_NEED_CONNECTED_WHATSAPP_NUMBER,
  YOUR_FACEBOOK_PAGE_MUST_BE_IN_BUSINESS_PORTFOLIO_OWN_WHATSAPP,
  ERROR_DESCRIPTION_GENERIC,
  META_ERROR_GETTING_USER_INFORMATION,
  META_ERROR_GETTING_BUSINESS_ACCOUNTS_INFORMATION,
  META_ERROR_GETTING_ACCOUNTS_INFORMATION,
  ERROR_WITH_MOBILE_PROVIDER,
} from "../i18n/keysTranslations";
import { getObjectError } from "./string";
import { SimpleAlert } from "../components/Alerts/Alerts";
import { ALERT_ICON_TYPE_ERROR, FONT_FAMILY } from "./constants";
import Typography from "@mui/material/Typography";
import { setShowModalSelectSubscription } from "../actions/ui";
const errorsObject = {
  "auth/invalid-email": FIELD_EMAIL_VALID,
  "auth/internal-error": ERROR_INTERNAL,
  "auth/invalid-password": AUTH_INVALID_PASSWORD,
  "auth/wrong-password": AUTH_WRONG_PASSWORD,
  "auth/too-many-requests": AUTH_MANY_REQUESTS,
  "auth/user-not-found": AUTH_USER_NOT_FOUND,
  "auth/email-already-exists": AUTH_EMAIL_ALREADY_EXISTS,
  "auth/invalid-verification-code": AUTH_INVALID_VERIFICATION_CODE,
  "auth/invalid-app-credential": AUTH_INVALID_APP_CREDENTIAL,
  "auth/popup-closed-by-user": OAUTH_IN_WINDOW_NO_COMPLETED,
  "auth/network-request-failed": ERROR_NETWORK_CONNECTION,
  "auth/quota-exceeded": REQUEST_TIME_OUT,
  "auth/error-code:-39": ERROR_WITH_MOBILE_PROVIDER,
  "auth/operation-not-allowed": ERROR_WITH_MOBILE_PROVIDER,
  ERR_NETWORK: ERROR_NETWORK_CONNECTION,
  card_declined: STRIPE_CARD_DECLINED,
  expired_card: STRIPE_EXPIRED_CARD,
  incorrect_cvc: STRIPE_INCORRECT_CVC,
  processing_error: STRIPE_PROCESSING_ERROR,
  incorrect_number: STRIPE_INCORRECT_NUMBER,
  generic_decline: STRIPE_GENERIC_DECLINE,
  insufficient_funds: STRIPE_INSUFFICIENT_FUNDS,
  lost_card: STRIPE_LOST_CARD,
  stolen_card: STRIPE_STOLEN_CARD,
  profile_not_found: STRIPE_PROFILE_NOT_FOUND,
  payment_intent_authentication_failure: STRIPE_INTENT_AUTH_FAILURE,
  [MISSING_PARAMETERS]: MISSING_PARAMETERS,
  [PASSWORD_INVALID]: PASSWORD_INVALID,
  [USER_ID_REQUIRED]: USER_ID_REQUIRED,
  [SHOP_ID_REQUIRED]: SHOP_ID_REQUIRED,
  [LAST_USER_NOT_DELETED]: LAST_USER_NOT_DELETED,
  [PLAN_TRIAL_USER_USED]: PLAN_TRIAL_USER_USED,
  [ORDERS_NOT_FOUND_IN_INTERVAL]: ORDERS_NOT_FOUND_IN_INTERVAL,
  [TYPE_REPORT_NOT_FOUND]: TYPE_REPORT_NOT_FOUND,
  [SHOP_NOT_FOUND]: SHOP_NOT_FOUND,
  [PAYMENT_MODEL_INVALID]: PAYMENT_MODEL_INVALID,
  [SHOP_NOT_HAS_SOCIAL_NETWORK]: SHOP_NOT_HAS_SOCIAL_NETWORK,
  [NO_VALID_PLATFORM_FOUND]: NO_VALID_PLATFORM_FOUND,
  [GETTING_STATISTICS]: GETTING_STATISTICS,
  [SHOP_BUSINESS_NAME_NOT_FOUND]: SHOP_BUSINESS_NAME_NOT_FOUND,
  [INFLUENCERS_SUGGESTED_NOT_FOUND]: INFLUENCERS_SUGGESTED_NOT_FOUND,
  [REFERENCE_PROFILES_REQUIRED]: REFERENCE_PROFILES_REQUIRED,
  [INFLUENCERS_SIMILAR_NOT_FOUND]: INFLUENCERS_SIMILAR_NOT_FOUND,
  [DECRYPTING_PASSWORD]: DECRYPTING_PASSWORD,
  [USER_ID_AND_PAYMENT_ID]: USER_ID_AND_PAYMENT_ID,
  [MESSAGES_REQUIRED]: MESSAGES_REQUIRED,
  [PLAN_IS_NO_ACTIVE]: PLAN_IS_NO_ACTIVE,
  [USER_BANNED]: USER_BANNED,
  [GETTING_LOCALES]: GETTING_LOCALES,
  [GETTING_PROFILE]: GETTING_PROFILE,
  [PRODUCTS_NOT_SENT]: PRODUCTS_NOT_SENT,
  [INVITATION_NOT_FOUND]: INVITATION_NOT_FOUND,
  [TOTAL_PRICE_EXCEEDED_VALUE_INVITATION]:
    TOTAL_PRICE_EXCEEDED_VALUE_INVITATION,
  [INFLUENCER_NOT_FOUND]: INFLUENCER_NOT_FOUND,
  [ACCOUNT_NOT_FOUND]: ACCOUNT_NOT_FOUND,
  [COLLECTING_INFORMATION_RETRY_AFTER]: COLLECTING_INFORMATION_RETRY_AFTER,
  [ERROR_OCCURRED]: ERROR_OCCURRED,
  [PLATFORM_NOT_SENT]: PLATFORM_NOT_SENT,
  [VERIFICATION_CODE_NOT_FOUND]: VERIFICATION_CODE_NOT_FOUND,
  [ORDER_NOT_FOUND]: ORDER_NOT_FOUND,
  [INVITE_ID_NO_SENT]: INVITE_ID_NO_SENT,
  [INVITE_NOT_FOUND]: INVITE_NOT_FOUND,
  [INVITE_CLOUD_TASK_ID_NOT_FOUND]: INVITE_CLOUD_TASK_ID_NOT_FOUND,
  [TYPE_OR_PATH_NOT_SENT]: TYPE_OR_PATH_NOT_SENT,
  [ID_OR_PATH_NOT_SENT]: ID_OR_PATH_NOT_SENT,
  [IDS_NOT_SENT]: IDS_NOT_SENT,
  [ANALYTIC_URL_NOT_SENT]: ANALYTIC_URL_NOT_SENT,
  [ANALYTIC_URL_INVALID]: ANALYTIC_URL_INVALID,
  [EXTRACTING_ANALYTICS]: EXTRACTING_ANALYTICS,
  [CREDENTIALS_INVALID]: CREDENTIALS_INVALID,
  [COLLABORATION_PRICE_NOT_BE_NEGATIVE]: COLLABORATION_PRICE_NOT_BE_NEGATIVE,
  [CREDITS_NOT_BE_NEGATIVE]: CREDITS_NOT_BE_NEGATIVE,
  [PACKAGES_PRICES_NOT_BE_NEGATIVE]: PACKAGES_PRICES_NOT_BE_NEGATIVE,
  [ROL_REQUIRED]: ROL_REQUIRED,
  [SHOP_ID_AND_STORE_ID_REQUIRED]: SHOP_ID_AND_STORE_ID_REQUIRED,
  [PASSWORD_MUST_BE_6_CHARACTERS]: PASSWORD_MUST_BE_6_CHARACTERS,
  [ALL_USERS_NOT_DELETE]: ALL_USERS_NOT_DELETE,
  [SHOP_APPLICANT_NOT_FOUND]: SHOP_APPLICANT_NOT_FOUND,
  [USER_PASSWORD_NOT_FOUND]: USER_PASSWORD_NOT_FOUND,
  [PLAN_NOT_FOUND]: PLAN_NOT_FOUND,
  [SUBSCRIPTION_NOT_CREATED]: SUBSCRIPTION_NOT_CREATED,
  [ID_SUBSCRIPTION_NOT_FOUND]: ID_SUBSCRIPTION_NOT_FOUND,
  [SUBSCRIPTION_REACTIVATE]: SUBSCRIPTION_REACTIVATE,
  [CUSTOMER_ID_NOT_FOUND]: CUSTOMER_ID_NOT_FOUND,
  [APPLICANT_NOT_FOUND]: APPLICANT_NOT_FOUND,
  [SHOP_COUNTRY_NOT_FOUND]: SHOP_COUNTRY_NOT_FOUND,
  [FINAL_PRICE_NOT_FOUND]: FINAL_PRICE_NOT_FOUND,
  [PAYMENT_FAILED]: PAYMENT_FAILED,
  [CREDITS_MUST_BE_GREATER_THAN_ZERO]: CREDITS_MUST_BE_GREATER_THAN_ZERO,
  [AMBASSADOR_NOT_FOUND]: AMBASSADOR_NOT_FOUND,
  [CSRF_INVALID]: CSRF_INVALID,
  [INTEGRATION_INFORMATION_NOT_FOUND]: INTEGRATION_INFORMATION_NOT_FOUND,
  [GETTING_VIDEO]: GETTING_VIDEO,
  [UPLOAD_URL_NOT_FOUND]: UPLOAD_URL_NOT_FOUND,
  [UPLOADING_VIDEO]: UPLOADING_VIDEO,
  [STORE_NOT_FOUND]: STORE_NOT_FOUND,
  [NAME_REQUIRED]: NAME_REQUIRED,
  [PRICE_REQUIRED]: PRICE_REQUIRED,
  [CURRENCY_REQUIRED]: CURRENCY_REQUIRED,
  [RECURRING_REQUIRED]: RECURRING_REQUIRED,
  [CREATING_PRODUCT]: CREATING_PRODUCT,
  [CREATING_PRICE]: CREATING_PRICE,
  [CREATING_PAYMENT_LINK]: CREATING_PAYMENT_LINK,
  [GETTING_PAYMENT_LINK]: GETTING_PAYMENT_LINK,
  [TOKEN_INVALID]: TOKEN_INVALID,
  [TOKEN_EXPIRED]: TOKEN_EXPIRED,
  [IG_ACCOUNT_NOT_FOUND]: IG_ACCOUNT_NOT_FOUND,
  [IG_DATA_NOT_FOUND]: IG_DATA_NOT_FOUND,
  [REVIEW_NOT_FOUND]: REVIEW_NOT_FOUND,
  [REVIEW_IS_NOT_ONE_START]: REVIEW_IS_NOT_ONE_START,
  [DISSATISFACTION_CREDIT_ALREADY_EXISTS]:
    DISSATISFACTION_CREDIT_ALREADY_EXISTS,
  [META_INTEGRATION_UNLINKED_PAGES_ERROR]:
    META_INTEGRATION_UNLINKED_PAGES_ERROR,
  [PAYMENT_METHOD_NOT_FOUND]: PAYMENT_METHOD_NOT_FOUND,
  [HAS_PENDING_PAYMENTS]: HAS_PENDING_PAYMENTS,
  [NO_IMAGES_FOUND_ON_PAGE]: NO_IMAGES_FOUND_ON_PAGE,
  [NO_CREATE_DISCOUNT_SHOPIFY]: NO_CREATE_DISCOUNT_SHOPIFY,
  [NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY]:
    NO_CREATE_WEBHOOK_ORDER_CREATED_SHOPIFY,
  [NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY]:
    NO_CREATE_WEBHOOK_FULFILLMENT_CREATED_SHOPIFY,
  [NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY]:
    NO_CREATE_WEBHOOK_FULFILLMENT_UPDATED_SHOPIFY,
  [GETTING_INFORMATION_FROM_SHOPIFY_URL]: GETTING_INFORMATION_FROM_SHOPIFY_URL,
  [RATE_DAILY_LIMIT_AI]: RATE_DAILY_LIMIT_AI,
  [PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META]:
    PERMISSIONS_OR_CONFIGURATION_ACCOUNT_CHANGE_META,
  [ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS]:
    ACCOUNT_IS_NOT_TYPE_BUSINESS_OR_NO_ACCEPTED_ALL_PERMISSIONS,
  [FACEBOOK_PAGE_ID_NOT_FOUND]: FACEBOOK_PAGE_ID_NOT_FOUND,
  [META_BUSINESS_ACCOUNT_NOT_FOUND]: META_BUSINESS_ACCOUNT_NOT_FOUND,
  [IG_BUSINESS_ACCOUNT_NOT_FOUND]: IG_BUSINESS_ACCOUNT_NOT_FOUND,
  [ACCOUNT_TIKTOK_AUTH_NOT_MATCH]: ACCOUNT_TIKTOK_AUTH_NOT_MATCH,
  [ACCOUNT_TOKEN_META_NOT_FOUND]: ACCOUNT_TOKEN_META_NOT_FOUND,
  [ACCOUNT_ID_BUSINESS_META_NOT_FOUND]: ACCOUNT_ID_BUSINESS_META_NOT_FOUND,
  [OBJECTIVE_CAMPAIGN_META_INVALID]: OBJECTIVE_CAMPAIGN_META_INVALID,
  [SPECIAL_CATEGORY_CAMPAIGN_META_INVALID]:
    SPECIAL_CATEGORY_CAMPAIGN_META_INVALID,
  [CAMPAIGN_META_NOT_DELETED]: CAMPAIGN_META_NOT_DELETED,
  [CAMPAIGN_META_NOT_CREATED]: CAMPAIGN_META_NOT_CREATED,
  [ADS_CAMPAIGN_NOT_FOUND]: ADS_CAMPAIGN_NOT_FOUND,
  [NO_PIXELS_FOUND]: NO_PIXELS_FOUND,
  [NO_PIXELS_ACTIVE]: NO_PIXELS_ACTIVE,
  [CAMPAIGN_META_NOT_FOUND]: CAMPAIGN_META_NOT_FOUND,
  [CAMPAIGN_META_NOT_UPDATED]: CAMPAIGN_META_NOT_UPDATED,
  [CAMPAIGN_AD_SET_META_NOT_CREATED]: CAMPAIGN_AD_SET_META_NOT_CREATED,
  [CAMPAIGN_AD_SET_META_NOT_UPDATED]: CAMPAIGN_AD_SET_META_NOT_UPDATED,
  [CAMPAIGN_MEDIA_META_NOT_CREATED]: CAMPAIGN_MEDIA_META_NOT_CREATED,
  [CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND]:
    CAMPAIGN_MEDIA_THUMBNAIL_META_NOT_FOUND,
  [CAMPAIGN_CREATIVE_AD_META_NOT_CREATED]:
    CAMPAIGN_CREATIVE_AD_META_NOT_CREATED,
  [CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED]:
    CAMPAIGN_CREATIVE_AD_META_NOT_UPDATED,
  [CAMPAIGN_CREATIVE_AD_META_NOT_FOUND]: CAMPAIGN_CREATIVE_AD_META_NOT_FOUND,
  [CAMPAIGN_AD_META_NOT_CREATED]: CAMPAIGN_AD_META_NOT_CREATED,
  [CAMPAIGN_AD_META_NOT_UPDATED]: CAMPAIGN_AD_META_NOT_UPDATED,
  [CAMPAIGN_METRICS_META_NOT_FOUND]: CAMPAIGN_METRICS_META_NOT_FOUND,
  [ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE]:
    ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  [CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE]:
    CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  [CAMPAIGN_AUDIENCE_IS_TOO_NARROW]: CAMPAIGN_AUDIENCE_IS_TOO_NARROW,
  [CAMPAIGN_BUDGET_IS_TOO_LOW]: CAMPAIGN_BUDGET_IS_TOO_LOW,
  [IDENTITY_NOT_FOUND]: IDENTITY_NOT_FOUND,
  [VIDEO_ERROR_LOW_RESOLUTION]: VIDEO_ERROR_LOW_RESOLUTION,
  [VIDEO_ERROR_NO_BACKGROUND_AUDIO]: VIDEO_ERROR_NO_BACKGROUND_AUDIO,
  [VIDEO_ERROR_ASPECT_RATIO]: VIDEO_ERROR_ASPECT_RATIO,
  [VIDEO_ERROR_BLACK_EDGE]: VIDEO_ERROR_BLACK_EDGE,
  [VIDEO_ERROR_ILLEGAL_DURATION]: VIDEO_ERROR_ILLEGAL_DURATION,
  [ERROR_GETTING_URL_INFORMATION_BY_PROTECTED]:
    ERROR_GETTING_URL_INFORMATION_BY_PROTECTED,
  [META_AD_ACCOUNT_IS_NOT_ACTIVE]: META_AD_ACCOUNT_IS_NOT_ACTIVE,
  [META_AD_ACCOUNT_HAVE_NOT_PAYMENT_METHOD]:
    META_AD_ACCOUNT_HAVE_NOT_PAYMENT_METHOD,
  [ADVERTISER_ID_NOT_FOUND]: ADVERTISER_ID_NOT_FOUND,
  [ADVERTISER_CURRENCY_NOT_FOUND]: ADVERTISER_CURRENCY_NOT_FOUND,
  [ACCOUNT_ROLE_INVALID]: ACCOUNT_ROLE_INVALID,
  [ERROR_GETTING_URL_META_DATA_AND_IMAGES]:
    ERROR_GETTING_URL_META_DATA_AND_IMAGES,
  [ADVERTISER_ID_TO_CHANGE_NO_PERMISSION]:
    ADVERTISER_ID_TO_CHANGE_NO_PERMISSION,
  [SCRIPT_IS_TOO_SHORT]: SCRIPT_IS_TOO_SHORT,
  [WEBSITE_AND_SOCIAL_NETWORK_CANT_ANALYZED]:
    WEBSITE_AND_SOCIAL_NETWORK_CANT_ANALYZED,
  [EMAIL_PROVIDER_IS_NOT_EQUAL_TO_EMAIL_FORM]:
    EMAIL_PROVIDER_IS_NOT_EQUAL_TO_EMAIL_FORM,
  [ERROR_PROCESSING_REQUEST]: ERROR_PROCESSING_REQUEST,
  [ERROR_NETWORK_CONNECTION]: ERROR_NETWORK_CONNECTION,
  [REQUEST_TIME_OUT]: REQUEST_TIME_OUT,
  [FACEBOOK_PAGE_NEED_CONNECTED_WHATSAPP_NUMBER]:
    FACEBOOK_PAGE_NEED_CONNECTED_WHATSAPP_NUMBER,
  [YOUR_FACEBOOK_PAGE_MUST_BE_IN_BUSINESS_PORTFOLIO_OWN_WHATSAPP]:
    YOUR_FACEBOOK_PAGE_MUST_BE_IN_BUSINESS_PORTFOLIO_OWN_WHATSAPP,
  [META_ERROR_GETTING_USER_INFORMATION]: META_ERROR_GETTING_USER_INFORMATION,
  [META_ERROR_GETTING_BUSINESS_ACCOUNTS_INFORMATION]:
    META_ERROR_GETTING_BUSINESS_ACCOUNTS_INFORMATION,
  [META_ERROR_GETTING_ACCOUNTS_INFORMATION]:
    META_ERROR_GETTING_ACCOUNTS_INFORMATION,
};

export const errorCodeToText = (errorCode, interpolation) => {
  if (errorsObject[errorCode]) {
    return i18next.t(errorsObject[errorCode], interpolation);
  } else if (errorsActivateShop[errorCode]) {
    return i18next.t(errorsActivateShop[errorCode], interpolation);
  } else {
    return errorCode;
  }
};

export const errorsActivateShop = {
  nit: NIT_VALID,
  businessName: BUSINESS_NAME_VALID,
  brandName: BRAND_NAME_VALID,
  address: ADDRESS_VALID,
  category: CATEGORY_VALID,
  subCategories: SUBCATEGORIES_VALID,
  shortDescription: SHORT_DESCRIPTION_VALID,
  ordersMethods: ORDERS_METHODS_VALID,
  logoImg: LOGO_IMG_VALID,
  coverImg: COVER_IMG_VALID,
  schedule: SCHEDULE_VALID,
  stores: STORES_VALID,
  isPlanActive: IS_PLAN_ACTIVE_VALID,
  menus: MENUS_VALID,
  defaultPaymentMethod: DEFAULT_PAYMENT_METHOD_VALID,
  hasDebt: HAS_DEBT_VALID,
  subscriptionPlan: SUBSCRIPTION_PLAN_VALID,
  profileInfluencer: PROFILE_INFLUENCER_VALID,
  automaticInvitations: AUTOMATIC_INVITATIONS_VALID,
};

export const verifyIsErrorPermissionDenied = (error) => (_, getState) => {
  const userID = getState()?.auth?.user?.uid;
  if (
    !userID &&
    (error.code === "permission-denied" ||
      error.message === "Permission denied" ||
      error.code === "PERMISSION_DENIED" ||
      error.message === "PERMISSION_DENIED: Permission denied")
  ) {
    return true;
  }
  return false;
};

export const getReactHookFormNestedError = (errors, path) => {
  const normalizedPath = path.replace(/\[(\d+)\]/g, ".$1");
  const parts = normalizedPath.split(".").filter(Boolean);
  return parts.reduce((acc, part) => acc && acc[part], errors);
};

export const ERRORS_BILLING = [
  CREATE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  ACTIVE_ADS_CAMPAIGN_ACCOUNT_NO_HAVE_BALANCE,
  META_AD_ACCOUNT_HAVE_NOT_PAYMENT_METHOD,
];

export const LaunchError =
  (error, showAlert = true, ignoreAlertByErrors = []) =>
  (dispatch, getState) => {
    const errorFormatted = getObjectError(error);
    const userID = getState()?.auth?.user?.uid;
    console.log(errorFormatted);
    if (
      !userID &&
      (error.code === "permission-denied" ||
        error.message === "Permission denied" ||
        error.code === "PERMISSION_DENIED" ||
        error.message === "PERMISSION_DENIED: Permission denied")
    ) {
      return {
        ok: false,
        code: error.code,
        message: error.message,
      };
    }

    if (errorFormatted?.code === NOT_HAVE_ENOUGH_USAGE_VALUE) {
      dispatch(setShowModalSelectSubscription(true));
    }

    if (!errorFormatted?.message)
      return {
        ...errorFormatted,
        ok: false,
      };

    if (showAlert && !ignoreAlertByErrors.includes(errorFormatted.code)) {
      SimpleAlert({
        title: i18next.t(ERROR),
        html: (
          <Typography
            variant="body1"
            sx={{
              fontFamily: FONT_FAMILY,
              "& *": {
                p: "revert",
                m: "revert",
                listStyle: "revert",
                textDecoration: "revert",
              },
            }}
            dangerouslySetInnerHTML={{
              __html:
                errorFormatted.message ||
                i18next.t(ERROR_DESCRIPTION_GENERIC, {
                  message: errorFormatted.message,
                  code: errorFormatted.code,
                }),
            }}
          />
        ),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }

    return {
      ...errorFormatted,
      ok: false,
    };
  };
