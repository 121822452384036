import ModalBasicLayout from "./ModalBasicLayout";
import { useTranslationApp } from "../../lib/i18next";
import {
  MODAL_CONNECT_ACCOUNTS_DESCRIPTION,
  MODAL_CONNECT_ACCOUNTS_TITLE,
} from "../../i18n/keysTranslations";
import Box from "@mui/material/Box";
import MetaContainerIntegration from "../Meta/MetaContainerIntegration";
import TikTokBusinessContainerIntegration from "../TikTokBusiness/TikTokBusinessContainerIntegration";
import { GOOGLE, META, TIKTOK } from "../../utils/constants";
import GoogleContainerIntegration from "../Google/GoogleContainerIntegration";

const ModalConnectAccounts = ({
  modalOpen,
  onCloseModal,
  platforms = [],
  onCallbackChangeIntegration = () => {},
}) => {
  const { t } = useTranslationApp();
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xs"
      scroll="paper"
      title={t(MODAL_CONNECT_ACCOUNTS_TITLE)}
      description={t(MODAL_CONNECT_ACCOUNTS_DESCRIPTION)}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        {platforms.includes(META) && (
          <MetaContainerIntegration
            onChangeIntegration={({ integrationID, adAccountID }) => {
              onCallbackChangeIntegration({
                platform: META,
                integrationID,
                adAccountID,
              });
            }}
          />
        )}
        {platforms.includes(TIKTOK) && (
          <TikTokBusinessContainerIntegration
            onChangeIntegration={({ integrationID, adAccountID }) => {
              onCallbackChangeIntegration({
                platform: TIKTOK,
                integrationID,
                adAccountID,
              });
            }}
          />
        )}
        {platforms.includes(GOOGLE) && (
          <GoogleContainerIntegration
            onChangeIntegration={({ integrationID, adAccountID }) => {
              onCallbackChangeIntegration({
                platform: GOOGLE,
                integrationID,
                adAccountID,
              });
            }}
          />
        )}
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalConnectAccounts;
