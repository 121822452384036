import { useEffect, useState } from "react";
import ContainerModalActions from "../Containers/ContainerModalActions";
import {
  BUTTON_CREATE,
  BUTTON_GO_BACK,
  BUTTON_SELECT,
  BUTTON_UPDATE,
  LOADING_INFORMATION,
  MODAL_INSTANT_FORMS_LIST_DESCRIPTION,
  MODAL_INSTANT_FORMS_LIST_TITLE,
  UPDATING_INFORMATION,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import ModalBasicLayout from "../Modal/ModalBasicLayout";
import LoadingButton from "../Buttons/LoadingButton";
import Loader from "../Loaders/Loader";
import Box from "@mui/material/Box";
import Button from "../Buttons/Button";
import CachedIcon from "@mui/icons-material/Cached";
import DialogActions from "@mui/material/DialogActions";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { usePagination } from "../../hooks/usePagination";
import useNearScreen from "../../hooks/useNearScreen";
import { startGetInstantForm } from "../../actions/adsCampaigns";
import { InstantFormsList } from "./InstantFormsList";
import _ from "lodash";
import { openLink } from "../../utils/string";

const ModalInstantFormsList = ({
  modalOpen,
  integrationID,
  pageID,
  onCloseModal = () => {},
  onCallbackSelect = () => {},
}) => {
  const [instantForms, setInstantForms] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const metaIntegrations = useSelectorApp(
    (state) => state?.user?.integrations?.facebook
  );

  const onGetCustomAudiences = async () => {
    if (loadLastItem) return;

    const response = await dispatch(
      startGetInstantForm({
        integrationID,
        pageID,
        lastKey,
        onChangeLastKey,
        onChangeLoadLastItem,
      })
    );

    if (response.ok) {
      const data = response.data;

      setInstantForms((prev) => _.uniqBy([...prev, ...data], "id"));
    }

    if (isLoading) setIsLoading(false);

    return response.ok;
  };

  const { lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: 10,
    onCallBackNextPage: onGetCustomAudiences,
  });

  const { fromRef, loadLastItem, onChangeLoadLastItem, isNearScreen } =
    useNearScreen({
      distance: "100px",
    });

  const onUpdateInstantForms = async () => {
    setSelectedItem(null);
    setIsUpdating(true);
    setIsLoading(true);

    const response = await dispatch(
      startGetInstantForm({
        integrationID,
        pageID,
        onChangeLastKey,
        onChangeLoadLastItem,
      })
    );

    if (response.ok) {
      const data = response.data;

      setInstantForms(data);
    }

    setIsLoading(false);
    setIsUpdating(false);

    return response.ok;
  };

  useEffect(() => {
    onGetCustomAudiences();
    // eslint-disable-next-line
  }, [isNearScreen]);

  const onOpenCreatePage = () => {
    const integration = metaIntegrations[integrationID];
    const page = integration?.pages?.[pageID];
    const businessID = page?.businessID;

    let redirectUrl = `https://business.facebook.com/latest/instant_forms/forms?page_id=${pageID}&asset_id=${pageID}`;

    if (businessID) {
      redirectUrl += `&business_id=${businessID}`;
    }

    openLink(redirectUrl);
  };

  const onSendSelectedItem = () => {
    const instantFormSelected = instantForms.find(
      (instantForm) => instantForm.id === selectedItem
    );
    onCallbackSelect(instantFormSelected);
    onCloseModal();
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
      title={t(MODAL_INSTANT_FORMS_LIST_TITLE)}
      description={t(MODAL_INSTANT_FORMS_LIST_DESCRIPTION)}
    >
      {isLoading && (
        <Loader
          hasMessage={true}
          message={t(isUpdating ? UPDATING_INFORMATION : LOADING_INFORMATION)}
        />
      )}
      {!isLoading && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: 1,
              justifyContent: "flex-end",
              width: "100%",
              mb: 2,
            }}
          >
            <LoadingButton
              disabled={isLoading}
              color="white"
              startIcon={<CachedIcon />}
              onClick={onUpdateInstantForms}
              sx={{
                minWidth: 150,
              }}
            >
              {t(BUTTON_UPDATE)}
            </LoadingButton>
            <Button
              type="button"
              onClick={onOpenCreatePage}
              sx={{
                minWidth: 150,
              }}
            >
              {t(BUTTON_CREATE)}
            </Button>
          </Box>
          <InstantFormsList
            selectedItem={selectedItem}
            onChangeSelectedItem={setSelectedItem}
            instantForms={instantForms}
            lastItemRef={fromRef}
            loadLastItem={loadLastItem}
          />
        </>
      )}
      <DialogActions
        sx={{
          p: 0,
        }}
      >
        <ContainerModalActions
          hasPadding={true}
          propsSecondaryButton={{
            showButton: true,
            onClick: onCloseModal,
            text: BUTTON_GO_BACK,
          }}
          propsPrimaryButton={{
            showButton: true,
            disabled: !selectedItem,
            text: BUTTON_SELECT,
            type: "submit",
            onClick: onSendSelectedItem,
          }}
        />
      </DialogActions>
    </ModalBasicLayout>
  );
};

export default ModalInstantFormsList;
