import {
  equalTo,
  get,
  limitToFirst,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  set,
  startAfter,
  update,
} from "firebase/database";
import types from "../types";
import { db } from "../lib/firebase";
import {
  ADS_CAMPAIGN_CHAT_BOT_ACTION_ID,
  ALERT_ICON_TYPE_ERROR,
} from "../utils/constants";
import i18next from "i18next";
import { ERROR, ERROR_DESCRIPTION_GENERIC } from "../i18n/keysTranslations";
import { LaunchError, verifyIsErrorPermissionDenied } from "../utils/errors";
import { getObjectError } from "../utils/string";
import { SimpleAlert } from "../components/Alerts/Alerts";
import { getPathDatabaseByRole, renewToken } from "./auth";
import axios from "../lib/axios";
import { startVerifyAndSetAdsUsage } from "./adsCampaigns";

const url = import.meta.env.VITE_CLOUD_FUNCTIONS_URL;

export const getPushKeyChat = () => (dispatch) => {
  const { userID } = dispatch(getPathDatabaseByRole());
  return push(ref(db, `chats/${userID}`)).key;
};

export const startGetChatsByIDS =
  ({ chatsIDS = [], onEmptyChats = () => {} }) =>
  async (dispatch) => {
    try {
      const { userID: userIDPlatform } = dispatch(getPathDatabaseByRole());

      chatsIDS = chatsIDS.filter((item, index) => {
        return chatsIDS.indexOf(item) === index;
      });
      const queriesChats = [];
      chatsIDS.forEach((chatID) => {
        queriesChats.push(get(ref(db, `chats/${chatID}`)));
      });
      const chatsSnapshot = await Promise.all(queriesChats);
      let chats = {};

      chatsSnapshot.forEach((snap) => {
        if (!snap.exists()) return;
        const chat = snap.val();
        if (chat.from !== "help") return;
        const userID = chat?.users?.filter((item) => item !== userIDPlatform);
        chats[snap.key] = {
          ...chat,
          userID: userID[0],
        };
      });

      if (Object.keys(chats).length === 0) {
        onEmptyChats();
        return;
      }

      dispatch(getAllChats(chats));

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAllChats = (onReferenceAvailable) => async (dispatch) => {
  try {
    const { userID: userIDPlatform } = dispatch(getPathDatabaseByRole());

    const dbRef = query(
      ref(db, `chatsIDS/${userIDPlatform}`),
      orderByChild("lastMessageDate"),
      limitToFirst(20)
    );
    onValue(dbRef, async (snapshot) => {
      const data = snapshot.val() ?? {};
      const chatsIDS = Object.keys(data);
      await dispatch(startGetChatsByIDS({ chatsIDS }));
    });
    onReferenceAvailable(dbRef);
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getAllChats = (data) => ({
  type: types.SET_CHATS,
  payload: data,
});

export const startGetNextChats =
  ({ limit, onChangeLastKey = () => {}, lastKey, loadLastItem }) =>
  async (dispatch, getState) => {
    try {
      const { userID } = dispatch(getPathDatabaseByRole());

      if (!lastKey) {
        const chats = getState().chat.chats;

        const chatsSorted = Object.keys(chats ?? {}).sort(
          (chatID1, chatID2) =>
            chats[chatID2]?.lastMessageDate - chats[chatID1]?.lastMessageDate
        );

        const newLastKey =
          chats[chatsSorted[chatsSorted.length - 1]]?.lastMessageDate * -1; // -1 negative time for order DESC

        onChangeLastKey(newLastKey);
        lastKey = newLastKey;
      }

      let q = lastKey
        ? query(
            ref(db, `chatsIDS/${userID}`),
            orderByChild("lastMessageDate"),
            startAfter(lastKey),
            limitToFirst(limit)
          )
        : query(
            ref(db, `chatsIDS/${userID}`),
            orderByChild("lastMessageDate"),
            limitToFirst(limit)
          );

      const snapshot = await get(q);

      if (!snapshot.exists()) return loadLastItem();

      const chats = snapshot.val();
      const chatsIDS = Object.keys(chats ?? {});

      onChangeLastKey(chats[chatsIDS[0]]?.lastMessageDate);

      await dispatch(
        startGetChatsByIDS({ chatsIDS, onEmptyChats: loadLastItem })
      );

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const setNewChat = (data) => ({
  type: types.SET_NEW_CHAT,
  payload: data,
});

export const startGetMessagesCurrentChat =
  ({ chatID = "", onReferenceAvailable }) =>
  async (dispatch, getState) => {
    try {
      if (!chatID) return false;
      const dbRef = ref(db, `chatsMessages/${chatID}`);
      onValue(dbRef, (snapshot) => {
        if (!snapshot.exists()) {
          dispatch(
            setCurrentChatMessages({
              messages: {},
              chatID,
            })
          );
        } else {
          const messages = snapshot.val();
          dispatch(
            setCurrentChatMessages({
              messages,
              chatID,
            })
          );
        }
      });
      const chats = getState().chat.chats;
      dispatch(setCurrentChat(chats[chatID]));
      onReferenceAvailable(dbRef);
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const setCurrentChat = (currentChat) => (dispatch) => {
  dispatch(clearTemporaryChats());
  dispatch({
    type: types.SET_CURRENT_CHAT,
    payload: currentChat,
  });
};

export const setCurrentChatMessages = (currentMessages) => ({
  type: types.SET_CURRENT_CHAT_MESSAGES,
  payload: currentMessages,
});

export const startSendMessageChat =
  ({ chatID, message, userID, addToCurrentChat = true }) =>
  async (dispatch, getState) => {
    try {
      const { userID: userIDPlatform, chatFrom } = dispatch(
        getPathDatabaseByRole()
      );

      if (!chatID) {
        chatID = await dispatch(startGetChatByUserID(userID));
      }

      const isFirstMessage = !chatID;

      const chatData = getState().chat.currentChat;

      const now = new Date().getTime();

      const updates = {};

      if (isFirstMessage) {
        chatID = push(ref(db, "chats")).key;
        const path = `chats/${chatID}`;
        const pathData = {
          chatID: chatID,
          users: [userIDPlatform, userID],
          from: chatData.from || chatFrom,
        };
        updates[path] = pathData;
      }

      const messageID = push(ref(db, `chatsMessages/${chatID}`)).key;

      const pathMessage = `chatsMessages/${chatID}/${messageID}`;
      const messageObject = {
        ...message,
        message: message.message,
        creationTime: now,
      };

      updates[pathMessage] = messageObject;

      const chat = {
        userID,
        chatID: chatID,
        lastMessage: messageObject.message,
        lastSender: messageObject.userID,
      };

      await update(ref(db), updates);

      if (isFirstMessage) {
        dispatch(
          setNewChat({
            chatID: chatID,
            chat,
          })
        );
      }
      dispatch(
        addMessageChat({
          message: messageObject,
          messageID,
          chatID,
          addToCurrentChat,
        })
      );

      return {
        [messageID]: messageObject,
      };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startSendBroadcastMessageChat =
  ({ message, chatsIDs, file }) =>
  async (dispatch) => {
    try {
      const { userID: senderID } = dispatch(getPathDatabaseByRole());

      const now = new Date().getTime();

      const updates = {};

      const messagesIDs = [];

      chatsIDs.forEach((chatID) => {
        const messageID = push(ref(db, `chatsMessages/${chatID}`)).key;

        const messageObject = {
          creationTime: now,
          message: message,
          userID: senderID,
          messageType: file ? "media" : "text",
          mediaURL: file,
        };

        updates[`chatsMessages/${chatID}/${messageID}`] = messageObject;

        messagesIDs.push({
          chatID,
          messageID,
          messageObject,
        });
      });

      await update(ref(db), updates);

      messagesIDs.forEach((messageData) => {
        dispatch(
          addMessagesChat({
            message: messageData.messageObject,
            messageID: messageData.messageID,
            chatID: messageData.chatID,
          })
        );
      });
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const addMessageChat = (data) => ({
  type: types.ADD_MESSAGE_CHAT,
  payload: data,
});
export const addMessagesChat = (data) => ({
  type: types.ADD_MESSAGES_CHAT,
  payload: data,
});
export const addMessageChatInList = (data) => ({
  type: types.ADD_MESSAGE_CHAT_IN_LIST,
  payload: data,
});

export const startGetChatByUserID = (userID) => async (dispatch, getState) => {
  try {
    const chats = getState().chat.chats;

    const chatIDFound = Object.keys(chats).find((chatID) => {
      const chat = chats[chatID];
      return chat?.users?.includes(userID);
    });

    if (chatIDFound) {
      return chatIDFound;
    }

    const { userID: userIDPlatform } = dispatch(getPathDatabaseByRole());

    const dbRef = query(
      ref(db, `chatsIDS/${userIDPlatform}`),
      orderByChild("receiver"),
      equalTo(userID)
    );

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) return false;

    const data = snapshot.val();
    const chatID = Object.keys(data)[0];

    const snapshotChat = await get(ref(db, `chats/${chatID}`));

    const chatInfo = snapshotChat.val();

    const chat = {
      ...chatInfo,
      userID,
    };

    dispatch(
      getAllChats({
        [chatID]: chat,
      })
    );

    return chatID;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startGetChatByChatID = (chatID) => async (dispatch, getState) => {
  try {
    const chatRedux = getState().chat.chats[chatID];

    if (chatRedux) {
      return chatRedux;
    }

    const snapshotChat = await get(ref(db, `chats/${chatID}`));

    const chatInfo = snapshotChat.val();

    const chat = chatInfo;

    dispatch(
      getAllChats({
        [chatID]: chat,
      })
    );

    return chatID;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startMarkReadChat = (chatID) => async (dispatch, getState) => {
  try {
    const chat = getState().chat.chats[chatID];
    const { userID: userIDPlatform } = dispatch(getPathDatabaseByRole());

    if (chat.lastSender === userIDPlatform) {
      return true;
    }

    const dbRef = ref(db, `chats/${chatID}/isRead`);

    await set(dbRef, true);

    dispatch(markReadChat(chatID));
  } catch (error) {
    const errorFormatted = getObjectError(error);
    if (dispatch(verifyIsErrorPermissionDenied(errorFormatted))) {
      return false;
    }

    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const markReadChat = (chatID) => ({
  type: types.MARK_READ_CHAT,
  payload: chatID,
});

export const startMarkDisabledChat = (chatID) => async (dispatch) => {
  try {
    const updates = {};

    updates[`chats/${chatID}/isDisabled`] = true;
    updates[`chats/${chatID}/from`] = "influencers";

    await update(ref(db), updates);

    dispatch(markDisabledChat(chatID));
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const markDisabledChat = (chatID) => ({
  type: types.MARK_DISABLED_CHAT,
  payload: chatID,
});

export const startSendMessageChatBot =
  ({ messages, chatConfigurationID, chatContext }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/sendMessageChatBot`,
        data: {
          messages,
          chatConfigurationID,
          chatContext,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (chatConfigurationID) {
        await dispatch(
          startVerifyAndSetAdsUsage({
            actionID: ADS_CAMPAIGN_CHAT_BOT_ACTION_ID,
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(LaunchError(error, false));
      return false;
    }
  };

const clearTemporaryChats = () => ({
  type: types.CLEAR_TEMPORARY_CHATS,
});

export const changeIsTypingBot = () => ({
  type: types.SET_IS_TYPING_BOT,
});

export const openChat = () => ({
  type: types.OPEN_CHAT,
});

export const setCurrentWriteMessage = (message) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_WRITE_MESSAGE,
    payload: message,
  });
};
export const closeChat = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_CHAT,
  });
};

export const uploadingFile = () => (dispatch) => {
  dispatch({
    type: types.SET_UPLOADING_FILE,
  });
};
