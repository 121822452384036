import ModalBasicLayout from "./ModalBasicLayout";
import Box from "@mui/material/Box";

const ModalWatchTutorial = ({ modalOpen, onCloseModal }) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "calc(100% - 64px)",
          height: "100%",
          borderRadius: 4,
          backgroundColor: "transparent",
          backgroundImage: "none",
          boxShadow: "none",
        },
        zIndex: 1302,
      }}
      sxTitle={{
        p: 0,
        mb: 1,
      }}
      sxContent={{
        overflow: "hidden",
        px: 0,
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/vpPzyV25eIA?si=DEYbzhJE6ABtgsVT"
          title="Demo Buzzly AI"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Box>
    </ModalBasicLayout>
  );
};

export default ModalWatchTutorial;
