import { Controller, useFormContext } from "react-hook-form";
import Accordion from "../../../components/Containers/Accordion";
import { t } from "i18next";
import {
  ACTIVE,
  ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION,
  ALERT_DELETE_PLATFORM_CAMPAIGN_DESCRIPTION,
  ARE_YOU_SURE,
  BUTTON_DELETE,
  CAMPAIGN_TYPE_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  DEACTIVATED,
  END_DATE_PLACEHOLDER,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  FIELD_REQUIRED,
  GOOGLE,
  GOOGLE_CONVERSION_ACTIONS_PLACEHOLDER,
  IDENTITY_TIK_TOK_PLACEHOLDER,
  INSTAGRAM_ACCOUNT_PLACEHOLDER,
  INSTANT_FORM_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  META_DESTINATION_TYPE_PLACEHOLDER,
  PAGE_FACEBOOK_PLACEHOLDER,
  START_DATE_AND_END_DATE_VALIDATION,
  START_DATE_PLACEHOLDER,
  WELCOME_MESSAGE_TEMPLATE_PLACEHOLDER,
  WHATSAPP_BUSINESS_NUMBER_PLACEHOLDER,
  TYPE_BUDGET_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
  LOCATIONS_PLACEHOLDER,
  NETWORK_DISPLAY_SETTING_PLACEHOLDER,
  GOOGLE_NETWORK_SETTING_GOOGLE_SEARCH,
  GOOGLE_NETWORK_SETTING_SEARCH_NETWORK,
  GOOGLE_NETWORK_SETTING_CONTENT_NETWORK,
  BUSINESS_NAME_PLACEHOLDER,
  IMAGES_OF_CAMPAIGN_PLACEHOLDER,
  BUTTON_UPLOAD_LOGO,
  BUTTON_CHANGE_LOGO,
  ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE,
  LOGO,
} from "../../../i18n/keysTranslations";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SelectTikTokIdentityCustom from "../../../components/Form/SelectTikTokIdentityCustom";
import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  ADS_CAMPAIGN_CREATION_MODE_QUICK,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_META,
  ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_TIKTOK,
  GOOGLE_IMAGE_FORMATS,
  LABELS_TRACKING_CUSTOM_EVENTS,
  LABELS_TRACKING_ID,
  META,
  STYLES_BY_PLATFORM,
  TIKTOK,
} from "../../../utils/constants";
import SelectTrackingIDField from "../../../components/Form/SelectTrackingIDField";
import TooltipModal from "../../../components/Tooltip/TooltipModal";
import ContentCommon from "../../../components/Tooltip/ContentCommon";
import AdsGroupForm from "./AdsGroupForm";
import { capitalizeFirstLetter } from "../../../utils/string";
import { useEffect, useState } from "react";
import Switch from "../../../components/Form/Switch";
import SelectBusinessAccount from "../../../components/Meta/SelectBusinessAccount";
import SelectPageFacebook from "../../../components/Meta/SelectPageFacebook";
import SelectInstagramAccount from "../../../components/Meta/SelectInstagramAccount";
import SelectCustomEventAds from "../../../components/Form/SelectCustomEventAds";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { ConfirmAlert } from "../../../components/Alerts/Alerts";
import Select from "../../../components/Form/Select";
import {
  optionsAdsCampaignTypeBudgets,
  optionsCampaignsTypeGoogleAds,
} from "../../../utils/options";
import TextField from "../../../components/Form/TextField";
import NumberField from "../../../components/Form/NumberField";
import differenceInDays from "date-fns/differenceInDays";
import DateField from "../../../components/Form/DateField";
import addDays from "date-fns/addDays";
import SelectDestinationTypeMeta from "../../../components/Meta/SelectDestinationTypeMeta";
import SelectWhatsappBusinessNumber from "../../../components/Form/SelectWhatsappBusinessNumber";
import MessageTemplateContainer from "../../../components/Meta/MessageTemplateContainer";
import InstantFormContainer from "../../../components/Meta/InstantFormContainer";
import SelectConversionActionsGoogle from "../../../components/Google/SelectConversionActionsGoogle";
import SearchSegmentationsField from "../../../components/Form/SearchSegmentationsField";
import CheckBoxes from "../../../components/Form/CheckBoxes";
import MultiContentField from "../../../components/Form/MultiContentField";
import UploadFileButton from "../../../components/Buttons/UploadFileButton";
import { getPathDatabaseByRole } from "../../../actions/auth";
import { useDispatchApp } from "../../../lib/redux";
import CardMedia from "../../../components/Media/CardMedia";

const AdsCampaignPlatformForm = ({ platform }) => {
  const [isSelectedAdAccount, setIsSelectedAdAccount] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [pixels, setPixels] = useState([]);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const {
    currency,
    watch,
    setValue,
    getValues,
    control,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    getIsCampaignPlatformActive,
    getShowActionsActionsPlatform,
    onChangeStatusCampaign,
    formState: { errors },
  } = useFormContext();

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
  const isCampaignPlatformActivated = getIsCampaignPlatformActive(platform);

  const dispatch = useDispatchApp();
  const showActionsPlatform = getShowActionsActionsPlatform(platform);
  const { userID, pathLogoUrl, isUserPlatform } = dispatch(
    getPathDatabaseByRole()
  );
  const isModeQuick = watch("mode") === ADS_CAMPAIGN_CREATION_MODE_QUICK;
  const objective = watch("objective");
  const socialNetworkURL = watch("socialNetworkURL");
  const language = watch("language");
  const platformData = watch(`platformsData.${platform}`) || {};
  const typeBudget = platformData?.typeBudget;
  const startDate = platformData?.startDate;
  const endDate = platformData?.endDate;
  const mediaUrls = platformData.mediaUrls || [];
  const mediaData = platformData.mediaData || [];
  const logoUrl = platformData.logoUrl;

  const adsGroups = watch("adsGroups")?.filter(
    (adGroup) => adGroup.platform === platform
  );
  const pixelSelected = _.isEmpty(pixels)
    ? {}
    : pixels.find((p) => p.id === platformData?.trackingID);

  const styles = STYLES_BY_PLATFORM[platform] || {};

  const onChangeSwitch = async (value) => {
    setIsSaving(true);
    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: "platform",
      platform,
    });
    setIsSaving(false);
  };

  const onDeletePlatform = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DELETE_PLATFORM_CAMPAIGN_DESCRIPTION, {
        platform: capitalizeFirstLetter(platform),
      }),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (!result.isConfirmed) return;

    const platforms = getValues("platforms");
    const newPlatforms = platforms.filter((p) => p !== platform);
    const platformsData = { ...getValues("platformsData") };
    const adsGroups = getValues("adsGroups");

    delete platformsData[platform];
    setValue("platforms", newPlatforms);
    setValue("platformsData", platformsData);

    const newAdsGroups = adsGroups.filter(
      (adGroup) => adGroup.platform !== platform
    );
    setValue("adsGroups", newAdsGroups);
  };

  const onChangeDestinationType = (value) => {
    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== META) return;
      const ads = adGroup?.ads || [];

      ads.forEach((ad, indexAd) => {
        const currentCallToAction = ad?.callToAction;
        const callToAction =
          value === ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
            ? "VIEW_INSTAGRAM_PROFILE"
            : currentCallToAction === "VIEW_INSTAGRAM_PROFILE"
            ? ""
            : currentCallToAction;
        setValue(
          `adsGroups.[${index}].ads.[${indexAd}].callToAction`,
          callToAction
        );
      });
    });
  };

  const getShowTrackingID = () => {
    if (platform === GOOGLE) return false;
    if (
      platform === META &&
      !ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective)
    ) {
      return false;
    }
    if (
      platform === META &&
      ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
      platformData.destinationType !==
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
    ) {
      return false;
    }
    if (
      platform === TIKTOK &&
      !ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective)
    ) {
      return false;
    }

    if (
      platform === META &&
      objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
      platformData.destinationType !==
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
    ) {
      return false;
    }

    return true;
  };
  const getShowTrackingCustomEvent = () => {
    if (platform === GOOGLE) return false;
    if (
      platform === META &&
      !ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_META.includes(
        objective
      )
    ) {
      return false;
    }
    if (
      platform === META &&
      ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
      platformData.destinationType !==
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
    ) {
      return false;
    }
    if (
      platform === TIKTOK &&
      !ADS_CAMPAIGNS_OBJECTIVES_WITH_CUSTOM_EVENT_TRACKING_TIKTOK.includes(
        objective
      )
    ) {
      return false;
    }

    if (
      platform === META &&
      objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
      platformData.destinationType !==
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
    ) {
      return false;
    }

    return true;
  };

  const showTrackingID = getShowTrackingID();
  const showTrackingCustomEvent = getShowTrackingCustomEvent();

  const onChangeIsSelectedAdAccount = () => {
    if (isSelectedAdAccount) return;

    setIsSelectedAdAccount(true);
  };

  useEffect(() => {
    if (!isModeQuick) {
      setIsSelectedAdAccount(true);
    }
  }, [isModeQuick]);

  return (
    <Accordion
      title={t(ADS_CAMPAIGN_CAMPAIGN_INFORMATION_SUB_TITLE, {
        platform: capitalizeFirstLetter(t(platform)),
      })}
      variantTitle={"body1"}
      initialValue={true}
      onCallbackChangeExpanded={(value) => setIsExpanded(value)}
      iconTitle={
        <Avatar
          src={styles.logo}
          alt={`Logo ${platform}`}
          variant="square"
          sx={{
            height: 25,
            width: 25,
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      }
      actionsComponent={
        isGeneralDisabledFields ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexGrow: 1,
              mr: { xs: 0, sm: 2 },
              pl: { xs: 2, sm: 0 },
            }}
          >
            {showActionsPlatform && (
              <Controller
                name={`states.${platform}`}
                control={control}
                render={({ field }) => (
                  <Switch
                    loading={isSaving}
                    isActive={field.value === ADS_CAMPAIGN_STATUS_ACTIVE}
                    labelActive={ACTIVE}
                    labelDeactivate={DEACTIVATED}
                    onChangeIsActive={(value) => onChangeSwitch(value)}
                  />
                )}
              />
            )}
            <IconButton
              sx={{
                color: styles.color,
              }}
              onClick={(e) => {
                e?.stopPropagation();
                onDeletePlatform();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )
      }
      sx={{
        border: `${styles.backgroundColor} 4px solid`,
        color: styles.color,
        borderRadius: `${16}px !important`,
        "& > .MuiAccordionSummary-root": {
          color: styles.color,
          backgroundColor: styles.backgroundColor,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          borderBottomRightRadius: isExpanded ? 0 : 8,
          borderBottomLeftRadius: isExpanded ? 0 : 8,
        },
        ".MuiAccordionSummary-root": {
          ".MuiAccordionSummary-content": {
            m: 0,
            mr: 1,
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: styles.color,
            mb: 0,
          },
        },
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          mt: 0,
        }}
      >
        <Grid item xs={12} lg={6}>
          <SelectBusinessAccount
            variant="filled"
            disabled={
              (Boolean(platformData?.adAccountID) && isSelectedAdAccount) ||
              isGeneralDisabledFields
            }
            label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
            control={control}
            platform={platform}
            name={`platformsData.${platform}.adAccountID`}
            onChangeAction={(value) => {
              onChangeIsSelectedAdAccount();
              setValue(
                `platformsData.${platform}.integrationID`,
                value?.integrationID
              );
              setValue(`platformsData.${platform}.trackingID`, "");
              setValue(`platformsData.${platform}.trackingCustomEventID`, "");
              setValue(`platformsData.${platform}.identityTikTokID`, "");
              setValue(`platformsData.${platform}.pageID`, "");
              setValue(`platformsData.${platform}.instagramID`, "");
              setValue(`platformsData.${platform}.whatsappBusinessNumber`, {});
              setValue(`platformsData.${platform}.instantForm`, {});
              setValue(`platformsData.${platform}.conversionActions`, []);
            }}
            errors={errors}
            fullWidth={true}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
        {platform === META && (
          <Grid item xs={12} lg={6}>
            <SelectPageFacebook
              variant="filled"
              disabled={
                isCampaignPlatformCreated ||
                !platformData?.adAccountID ||
                isGeneralDisabledFields
              }
              label={t(PAGE_FACEBOOK_PLACEHOLDER)}
              name={`platformsData.${platform}.pageID`}
              integrationID={platformData?.integrationID}
              adAccountID={platformData?.adAccountID}
              control={control}
              platform={platform}
              errors={errors}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === META && (
          <Grid item xs={12} lg={6}>
            <SelectInstagramAccount
              variant="filled"
              disabled={
                isCampaignPlatformCreated ||
                !platformData?.pageID ||
                isGeneralDisabledFields
              }
              label={t(INSTAGRAM_ACCOUNT_PLACEHOLDER)}
              name={`platformsData.${platform}.instagramID`}
              integrationID={platformData?.integrationID}
              pageID={platformData?.pageID}
              adAccountID={platformData?.adAccountID}
              control={control}
              errors={errors}
              platform={platform}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === TIKTOK && (
          <Grid item xs={12} lg={6}>
            <SelectTikTokIdentityCustom
              label={t(IDENTITY_TIK_TOK_PLACEHOLDER)}
              objective={objective}
              adAccountID={platformData?.adAccountID}
              integrationID={platformData?.integrationID}
              name={`platformsData.${platform}.identityTikTokID`}
              fullWidth
              variant="filled"
              control={control}
              errors={errors}
              disabled={
                isCampaignPlatformCreated ||
                !platformData?.adAccountID ||
                isGeneralDisabledFields
              }
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === META &&
          ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE.includes(
            objective
          ) && (
            <Grid item xs={12} lg={6}>
              <SelectDestinationTypeMeta
                label={META_DESTINATION_TYPE_PLACEHOLDER}
                name={`platformsData.${platform}.destinationType`}
                objective={objective}
                variant="filled"
                fullWidth={true}
                disabled={isCampaignPlatformCreated}
                control={control}
                errors={errors}
                actionsChange={(value) => onChangeDestinationType(value)}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}
        {platformData.destinationType ===
          ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION &&
          platform === META && (
            <Grid item xs={12} lg={6}>
              <SelectWhatsappBusinessNumber
                label={t(WHATSAPP_BUSINESS_NUMBER_PLACEHOLDER)}
                name={`platformsData.${platform}.whatsappBusinessNumber`}
                integrationID={platformData?.integrationID}
                disabled={isCampaignPlatformCreated}
                fullWidth
                variant="filled"
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}
        {ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE.includes(
          platformData.destinationType
        ) && (
          <Grid item xs={12} lg={6}>
            <MessageTemplateContainer
              label={t(WELCOME_MESSAGE_TEMPLATE_PLACEHOLDER)}
              name={`platformsData.${platform}.messageTemplate`}
              disabled={isCampaignPlatformCreated}
              fullWidth
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION &&
          platformData.destinationType ===
            ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION &&
          platform === META && (
            <Grid item xs={12} lg={6}>
              <InstantFormContainer
                label={t(INSTANT_FORM_PLACEHOLDER)}
                name={`platformsData.${platform}.instantForm`}
                integrationID={platformData?.integrationID}
                pageID={platformData?.pageID}
                disabled={
                  isCampaignPlatformCreated ||
                  !platformData?.pageID ||
                  !platformData?.integrationID
                }
                fullWidth
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}
        {showTrackingID && (
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <SelectTrackingIDField
                name={`platformsData.${platform}.trackingID`}
                disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
                label={t(LABELS_TRACKING_ID[platform])}
                fullWidth
                variant="filled"
                platform={platform}
                integrationID={platformData?.integrationID}
                adAccountID={platformData?.adAccountID}
                onLoadOptions={(newPixels) => {
                  setPixels(newPixels);
                }}
                onChangeAction={() => {
                  setValue(
                    `platformsData.${platform}.trackingCustomEventID`,
                    ""
                  );
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
              <TooltipModal
                sx={{
                  mt: 0.5,
                }}
                title={t(ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION)}
                content={
                  <ContentCommon
                    text={ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION}
                  />
                }
              />
            </Box>
          </Grid>
        )}
        {showTrackingCustomEvent && (
          <Grid item xs={12} lg={6}>
            <SelectCustomEventAds
              label={t(LABELS_TRACKING_CUSTOM_EVENTS[platform])}
              name={`platformsData.${platform}.trackingCustomEventID`}
              platform={platform}
              variant="filled"
              disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
              integrationID={platformData?.integrationID}
              adAccountID={platformData?.adAccountID}
              pixelSelected={pixelSelected}
              objective={objective}
              control={control}
              errors={errors}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
        {platform === GOOGLE && (
          <>
            <Grid item xs={12} lg={6}>
              <Select
                label={t(CAMPAIGN_TYPE_PLACEHOLDER)}
                options={optionsCampaignsTypeGoogleAds}
                disabled={isCampaignPlatformCreated}
                name={`platformsData.${platform}.campaignType`}
                variant="filled"
                fullWidth
                doTranslate={true}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CheckBoxes
                name={`platformsData.${platform}.networkSettings`}
                disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
                label={NETWORK_DISPLAY_SETTING_PLACEHOLDER}
                options={[
                  {
                    key: "targetGoogleSearch",
                    label: GOOGLE_NETWORK_SETTING_GOOGLE_SEARCH,
                    disabled: true,
                  },
                  {
                    key: "targetSearchNetwork",
                    label: GOOGLE_NETWORK_SETTING_SEARCH_NETWORK,
                  },
                  {
                    key: "targetContentNetwork",
                    label: GOOGLE_NETWORK_SETTING_CONTENT_NETWORK,
                  },
                ]}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectConversionActionsGoogle
                label={t(GOOGLE_CONVERSION_ACTIONS_PLACEHOLDER)}
                disabled={isCampaignPlatformCreated}
                name={`platformsData.${platform}.conversionActions`}
                integrationID={platformData?.integrationID}
                adAccountID={platformData?.adAccountID}
                variant="filled"
                fullWidth
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SearchSegmentationsField
                label={t(LOCATIONS_PLACEHOLDER)}
                name={`platformsData.${platform}.locations`}
                type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE}
                mode="fetching"
                platform={platform}
                adAccountID={platformData?.adAccountID}
                integrationID={platformData?.integrationID}
                objective={objective}
                language={language}
                fullWidth
                variant="filled"
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                }}
              >
                {t(ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Select
                label={t(TYPE_BUDGET_PLACEHOLDER)}
                disabled={true}
                name={`platformsData.${platform}.typeBudget`}
                actionsChange={(value) => {
                  if (value === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
                    setValue(`platformsData.${platform}.startDate`, null);
                    setValue(`platformsData.${platform}.endDate`, null);
                  } else {
                    setValue(
                      `platformsData.${platform}.startDate`,
                      new Date().toISOString()
                    );
                    setValue(
                      `platformsData.${platform}.endDate`,
                      addDays(new Date(), 7).toISOString()
                    );
                  }
                }}
                fullWidth
                variant="filled"
                doTranslate={true}
                options={optionsAdsCampaignTypeBudgets}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                label={t(
                  typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
                    ? DAILY_BUDGET_PLACEHOLDER
                    : LIFETIME_BUDGET_PLACEHOLDER
                )}
                name={`platformsData.${platform}.budget`}
                disabled={isCampaignPlatformActivated}
                fullWidth
                variant="filled"
                InputProps={{
                  inputComponent: NumberField,
                }}
                helperText={
                  currency
                    ? t(VALUE_EXPRESSED_IN, {
                        currency: currency.toUpperCase(),
                      })
                    : ""
                }
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (
                      typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
                    ) {
                      const startDateTime = startDate
                        ? new Date(startDate)
                        : new Date();
                      const endDateTime = endDate
                        ? new Date(endDate)
                        : new Date();

                      const diffDays = differenceInDays(
                        endDateTime,
                        startDateTime
                      );

                      const dailyBudget = value / diffDays;

                      if (dailyBudget < 1) {
                        return t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                          value: diffDays,
                        });
                      }
                    }
                  },
                }}
              />
            </Grid>
            {typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION && (
              <>
                <Grid item xs={12} lg={6}>
                  <DateField
                    disabled={isCampaignPlatformCreated}
                    minDate={new Date()}
                    name={`platformsData.${platform}.startDate`}
                    label={t(START_DATE_PLACEHOLDER)}
                    control={control}
                    errors={errors}
                    rules={{
                      validate: (value) => {
                        if (
                          typeBudget ===
                          ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
                        ) {
                          if (!value) {
                            return t(FIELD_REQUIRED);
                          }
                          const endDateValue = getValues(
                            `platformsData.${platform}.endDate`
                          );
                          if (endDateValue) {
                            const startDate = new Date(value);
                            const endDate = new Date(endDateValue);
                            if (startDate > endDate) {
                              return t(START_DATE_AND_END_DATE_VALIDATION);
                            }
                          }
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DateField
                    disabled={isCampaignPlatformActivated}
                    name={`platformsData.${platform}.endDate`}
                    minDate={addDays(new Date(), 1)}
                    label={t(END_DATE_PLACEHOLDER)}
                    control={control}
                    errors={errors}
                    rules={{
                      validate: (value) => {
                        if (
                          typeBudget ===
                          ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
                        ) {
                          if (!value) {
                            return t(FIELD_REQUIRED);
                          }
                          const startDateValue = getValues(
                            `platformsData.${platform}.startDate`
                          );
                          if (startDateValue) {
                            const endDate = new Date(value);
                            const startDate = new Date(startDateValue);
                            if (startDate > endDate) {
                              return t(START_DATE_AND_END_DATE_VALIDATION);
                            }
                          }
                        }
                      },
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                }}
              >
                {t(ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" gutterBottom>
                {t(LOGO)}
              </Typography>
              {errors["logoURL"] && (
                <Typography variant="body2" color="error" gutterBottom>
                  {t(errors["logoURL"].message)}
                </Typography>
              )}
              {logoUrl && (
                <CardMedia
                  url={logoUrl}
                  sx={{
                    objectFit: "cover",
                    borderRadius: 3,
                    height: 110,
                    width: 110,
                    mb: 1,
                  }}
                />
              )}
              <Controller
                name={`platformsData.${platform}.logoUrl`}
                control={control}
                render={({ field }) => (
                  <UploadFileButton
                    id="upload-logo-url"
                    textUpload={BUTTON_UPLOAD_LOGO}
                    colorUpload="primary"
                    textRemove={BUTTON_CHANGE_LOGO}
                    colorRemove="primary"
                    value={field.value}
                    onChange={field.onChange}
                    pathStorage={`socialMedia/${userID}/`}
                    pathRTDB={isUserPlatform ? pathLogoUrl : null}
                    acceptedFormats={GOOGLE_IMAGE_FORMATS}
                    modeUpload="sync"
                    loading={isUploadingLogo}
                    onCallbackLoading={(loading) => setIsUploadingLogo(loading)}
                    sx={{
                      width: 110,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Accordion title={t(IMAGES_OF_CAMPAIGN_PLACEHOLDER)}>
                <MultiContentField
                  acceptedFormats={GOOGLE_IMAGE_FORMATS}
                  mediaUrls={mediaUrls}
                  showGalleryAdsPlatformsOption={false}
                  propsGalleryAdsPlatforms={{
                    socialNetworkURL,
                    integrationID: platformData?.integrationID,
                    adAccountID: platformData?.adAccountID,
                  }}
                  onChange={(contents) => {
                    const newMediaUrls = contents.map((content) => content.url);
                    const newMediaData = contents.map(
                      (content) => content.mediaData
                    );
                    setValue(`platformsData.${platform}.mediaUrls`, [
                      ...mediaUrls,
                      ...newMediaUrls,
                    ]);
                    setValue(`platformsData.${platform}.mediaData`, [
                      ...mediaData,
                      ...newMediaData,
                    ]);
                  }}
                  onDelete={(index) => {
                    setValue(
                      `platformsData.${platform}.mediaUrls`,
                      mediaUrls.filter((_, i) => i !== index)
                    );
                    setValue(
                      `platformsData.${platform}.mediaData`,
                      mediaData.filter((_, i) => i !== index)
                    );
                  }}
                />
              </Accordion>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name={`platformsData.${platform}.businessName`}
                label={t(BUSINESS_NAME_PLACEHOLDER)}
                variant="filled"
                fullWidth
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          </>
        )}
        {adsGroups.map((adGroup, index) => (
          <Grid item xs={12} key={adGroup.id}>
            <AdsGroupForm
              data={adGroup}
              currency={currency}
              adGroupNumber={index}
              platform={platform}
            />
          </Grid>
        ))}
      </Grid>
    </Accordion>
  );
};

export default AdsCampaignPlatformForm;
