import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { FIELD_PHONE_VALID, FIELD_REQUIRED } from "../../i18n/keysTranslations";
import i18next from "i18next";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/locale-data/es";

const PhoneField = ({
  control,
  rules = {},
  errors = {},
  id,
  label = "",
  variant = "standard",
  name = "",
  value = "",
  fullWidth = false,
  multiline = false,
  minRows,
  maxRows,
  helperText,
  onKeyDown,
  InputProps,
  disabled = false,
  onClick = () => {},
  actionsChange = () => {},
  doOnChange = true,
  useValue = true,
  sx,
  placeholder = "",
  autoFocus = false,
  dataTour = "",
  defaultCountry = "us",
  disableDropdown = true,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: ({ value, info }) => {
          const countryCallingCode = info?.countryCallingCode;
          const phoneFormatted = value
            ?.replace(`+${countryCallingCode}`, "")
            ?.replace(/ /g, "");
          if (!phoneFormatted) {
            return i18next.t(FIELD_REQUIRED);
          }
          if (!matchIsValidTel(value)) {
            return i18next.t(FIELD_PHONE_VALID);
          }
        },
        ...rules,
      }}
      defaultValue={value}
      render={({ field }) => (
        <MuiTelInput
          id={id}
          forceCallingCode={true}
          disableDropdown={disableDropdown}
          defaultCountry={defaultCountry.toUpperCase()}
          data-tour={dataTour}
          autoFocus={autoFocus}
          label={label}
          onClick={onClick}
          disabled={disabled}
          variant={variant}
          fullWidth={fullWidth}
          multiline={multiline}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 68 * 4.5 + 8 * 2,
              },
            },
          }}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          error={!!errors[name]}
          helperText={errors[name] ? errors[name]?.message : helperText}
          onKeyDown={onKeyDown}
          InputProps={InputProps}
          {...field}
          onChange={(value, info) => {
            if (doOnChange) {
              field.onChange({ value, info });
            }
            actionsChange(value);
          }}
          value={useValue ? field?.value?.value : value}
          sx={{
            ...sx,
            ".MuiFormHelperText-root": {
              ml: 0,
            },
          }}
        />
      )}
    />
  );
};

export default PhoneField;
