import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ADS_CAMPAIGNS_DEMO,
  BORDER_RADIUS,
  GOOGLE,
  META,
  TIKTOK,
} from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CLICKS,
  CONTEXT,
  CTR,
  DRAFT,
  FINISHED,
  LOADING_METRICS_FROM_PLATFORMS,
  OBJECTIVE,
  PLATFORMS,
  STATISTICS,
  VALUE_WITH_PERCENTAGE,
  LAST_UPDATE_DATE_SHORT,
  ACTIONS,
  BUTTON_DELETE,
  ALERT_DELETE_CAMPAIGN_TITLE,
  ALERT_DELETE_CAMPAIGN_DESCRIPTION,
  CREATED_AT,
  DATE_SHORT_2,
  CREATED_BY,
  CPA,
} from "../../../i18n/keysTranslations";
import Loader from "../../../components/Loaders/Loader";
import { getFormattedCampaign, logoPlatform } from "../../../utils/string";
import { numberCompactFormat, numberDecimal } from "../../../utils/numbers";
import { optionsAdsCampaignObjectives } from "../../../utils/options";
import Switch from "../../../components/Form/Switch";
import {
  getGeneralStatistics,
  getStatusAdsCampaign,
} from "../../../services/adsCampaigns";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatchApp } from "../../../lib/redux";
import { startDeleteAdsCampaign } from "../../../actions/adsCampaigns";
import { ConfirmAlert } from "../../../components/Alerts/Alerts";
import { getUserIsViewer } from "../../../actions/getters";
import { startGetAdsUser } from "../../../actions/user";
import TooltipMainStatisticAdsCampaign from "../../../components/Tooltip/TooltipMainStatisticAdsCampaign";

const sxCutText = {
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
};

const AdsCampaignsListItem = ({
  data = {},
  campaignID,
  onChangeStatusCampaign = () => {},
}) => {
  const platforms = data?.platforms;

  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(`/ads-campaign?campaignID=${campaignID}`)}
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        width: "100%",
        backgroundColor: "background.paperSecondary",
        borderRadius: BORDER_RADIUS,
        py: { xs: 2, sm: 2 },
        px: { xs: 2, sm: 3 },
        height: { md: "unset", lg: 282 },
        gap: { xs: 0, sm: 2 },
        cursor: "pointer",
        "&:hover": {
          filter: theme.palette.hover.main,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: { xs: 0, sm: 2 },
          width: { xs: "100%", sm: "50%" },
        }}
      >
        <NameItem
          data={data}
          onChangeStatusCampaign={onChangeStatusCampaign}
          campaignID={campaignID}
        />
        <ObjectiveItem data={data} />
        <GeneralInformation data={data} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "flex-end" },
          gap: 2,
          width: { xs: "100%", sm: "50%" },
        }}
      >
        {!isMobile && (
          <PlatformsItem platforms={platforms} campaignID={campaignID} />
        )}
        <CreatedAtAndByItem data={data} />
        <StatisticsItem data={data} />
        <ActionsItem data={data} />
      </Box>
    </Box>
  );
};

const NameItem = ({ data, onChangeStatusCampaign = () => {}, campaignID }) => {
  const [isSwitching, setIsSwitching] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const statusCampaign = getStatusAdsCampaign({
    states: data.states,
    target: "general",
  });

  const dispatch = useDispatchApp();
  const { t } = useTranslationApp();
  const isViewer = dispatch(getUserIsViewer());

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 1.5,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, ...sxCutText, wordBreak: "break-all" }}
        >
          {data.name}
        </Typography>
        {statusCampaign === ADS_CAMPAIGN_STATUS_FINISHED && (
          <Typography
            variant="body2"
            color="fields.placeholder"
            sx={{
              mt: 0.25,
            }}
          >
            {t(FINISHED)}
          </Typography>
        )}
        {statusCampaign === ADS_CAMPAIGN_STATUS_DRAFT && (
          <Typography
            variant="body2"
            color="fields.placeholder"
            sx={{
              pt: 0.5,
            }}
          >
            {t(DRAFT)}
          </Typography>
        )}
        {statusCampaign !== ADS_CAMPAIGN_STATUS_FINISHED &&
          statusCampaign !== ADS_CAMPAIGN_STATUS_DRAFT &&
          !isViewer && (
            <>
              {isSwitching && <Loader size={20} hasMarginTop={false} />}
              {!isSwitching && (
                <Switch
                  hasLabel={false}
                  isActive={statusCampaign === ADS_CAMPAIGN_STATUS_ACTIVE}
                  size="small"
                  onChangeIsActive={async () => {
                    setIsSwitching(true);
                    await onChangeStatusCampaign(campaignID);
                    setIsSwitching(false);
                  }}
                />
              )}
            </>
          )}
      </Box>
      {isMobile && <PlatformsItem platforms={data.platforms} />}
    </Box>
  );
};

const CreatedAtAndByItem = ({ data }) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery("(max-width:600px)");

  const createdBy = data.createdBy;

  const getUser = async () => {
    const response = await dispatch(startGetAdsUser(createdBy));
    if (response) {
      setUser(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SubTitleSection text={CREATED_AT} align={isMobile ? "left" : "right"} />
      <Typography variant="body1" align={isMobile ? "left" : "right"}>
        {t(DATE_SHORT_2, { date: new Date(data.creationTime) })}
      </Typography>
      <SubTitleSection text={CREATED_BY} align={isMobile ? "left" : "right"} />
      {isLoading ? (
        <Skeleton variant="text" width={100} height={20} />
      ) : (
        <Typography variant="body1" align={isMobile ? "left" : "right"}>
          {user.name}
        </Typography>
      )}
    </Box>
  );
};

const ActionsItem = ({ data }) => {
  const [isDeletingCampaign, setIsDeletingCampaign] = useState(false);

  const dispatch = useDispatchApp();
  const isViewer = dispatch(getUserIsViewer());

  const statusCampaign = getStatusAdsCampaign({
    states: data.states,
    target: "general",
  });
  const { t } = useTranslationApp();
  const isCampaignDraft = statusCampaign === ADS_CAMPAIGN_STATUS_DRAFT;

  const onDeleteDraftCampaign = async (e) => {
    e?.stopPropagation();
    const result = await ConfirmAlert({
      title: t(ALERT_DELETE_CAMPAIGN_TITLE),
      text: t(ALERT_DELETE_CAMPAIGN_DESCRIPTION),
      confirmButtonText: t(BUTTON_DELETE),
    });
    if (!result.isConfirmed) return;
    setIsDeletingCampaign(true);
    await dispatch(
      startDeleteAdsCampaign(getFormattedCampaign({ data, format: "load" }))
    );
    setIsDeletingCampaign(false);
  };

  if (!isCampaignDraft || isViewer) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        minHeight: 76,
      }}
    >
      <SubTitleSection text={ACTIONS} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {isDeletingCampaign ? (
          <Loader size={20} hasMarginTop={false} />
        ) : (
          <DeleteIcon onClick={onDeleteDraftCampaign} />
        )}
      </Box>
    </Box>
  );
};

const GeneralInformation = ({ data }) => {
  if (!data?.productContext) return <Box sx={{ height: 40 }} />;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <SubTitleSection text={CONTEXT} />
      <Typography variant="body1" sx={sxCutText}>
        {data.productContext}
      </Typography>
    </Box>
  );
};

const SubTitleSection = ({ text, align }) => {
  const { t } = useTranslationApp();
  return (
    <Typography variant="body2" color="fields.placeholder" align={align}>
      {t(text)}
    </Typography>
  );
};

const ObjectiveItem = ({ data }) => {
  const objectiveObject = optionsAdsCampaignObjectives.find(
    (objective) => objective.value === data.objective
  );
  const { t } = useTranslationApp();

  return (
    <Box
      sx={{
        mb: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      <SubTitleSection text={OBJECTIVE} />
      <Typography variant="body1">{t(objectiveObject?.label)}</Typography>
    </Box>
  );
};

const PlatformsItem = ({ platforms, campaignID }) => {
  const finalPlatforms = ADS_CAMPAIGNS_DEMO.includes(campaignID)
    ? [META]
    : platforms;
  return (
    <Box
      sx={{
        pt: 0.5,
      }}
    >
      <SubTitleSection text={PLATFORMS} align="right" />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, gap: 1 }}>
        {finalPlatforms?.map((platform) => (
          <Avatar
            key={platform}
            src={logoPlatform(platform)}
            alt={`${platform} Logo`}
            variant="square"
            sx={{
              width: platform === META ? 30 : 23,
              height: "auto",
              ".MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

const StatisticsItem = ({ data }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const { t } = useTranslationApp();

  const statistics = data?.statistics || {};
  const platforms = Object.keys(statistics);
  const status = getStatusAdsCampaign({
    states: data.states,
    target: "general",
  });

  const hasMetrics = !_.isEmpty(statistics);
  const isCampaignDraft = status === ADS_CAMPAIGN_STATUS_DRAFT;

  const generalStatistics = getGeneralStatistics(statistics);
  const hasMainActionValue = Number(generalStatistics?.costPerMainAction) > 0;

  const getMainCurrency = () => {
    if (platforms.includes(META)) return statistics?.[META]?.currency || "";
    if (platforms.includes(TIKTOK)) return statistics?.[TIKTOK]?.currency || "";
    if (platforms.includes(GOOGLE)) return statistics?.[GOOGLE]?.currency || "";
    return "";
  };

  if (isCampaignDraft) return null;

  if (status !== ADS_CAMPAIGN_STATUS_ACTIVE && !generalStatistics.impressions)
    return null;

  if (!hasMetrics || !generalStatistics.impressions)
    return (
      <Box>
        <SubTitleSection
          text={STATISTICS}
          align={isMobile ? "left" : "right"}
        />
        <Box>
          <Typography variant="body1">
            {t(LOADING_METRICS_FROM_PLATFORMS)}
          </Typography>
        </Box>
      </Box>
    );

  return (
    <Box>
      <SubTitleSection text={STATISTICS} align={isMobile ? "left" : "right"} />
      <Box
        sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 2 }}
      >
        <Box>
          <Typography variant="body1" align={isMobile ? "left" : "right"}>
            {t(CLICKS)}
          </Typography>
          <Typography variant="body1" align={isMobile ? "left" : "right"}>
            {numberCompactFormat(generalStatistics.click || 0)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" align={isMobile ? "left" : "right"}>
            {t(CTR)}
          </Typography>
          <Typography variant="body1" align={isMobile ? "left" : "right"}>
            {t(VALUE_WITH_PERCENTAGE, {
              value: generalStatistics.ctr || 0,
            })}
          </Typography>
        </Box>
        <TooltipMainStatisticAdsCampaign statistics={statistics}>
          <Box>
            <Typography variant="body1" align={isMobile ? "left" : "right"}>
              {t(CPA)}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                gap: 0.5,
              }}
            >
              <Typography variant="body1" align={isMobile ? "left" : "right"}>
                {hasMainActionValue
                  ? `$${numberDecimal(generalStatistics.costPerMainAction)}`
                  : "-"}
              </Typography>
              {hasMainActionValue && (
                <Typography variant="caption">{getMainCurrency()}</Typography>
              )}
            </Box>
          </Box>
        </TooltipMainStatisticAdsCampaign>
      </Box>
      <Typography
        variant="caption"
        align={isMobile ? "left" : "right"}
        color="fields.placeholder"
        sx={{
          display: "block",
        }}
      >
        {t(LAST_UPDATE_DATE_SHORT, {
          date: new Date(generalStatistics.lastUpdatedTime),
        })}
      </Typography>
    </Box>
  );
};

export default AdsCampaignsListItem;
