import { createContext } from "react";
import { IMAGE_FORMATS } from "../utils/constants";
import { getHeightAndWidthFromDataUrl } from "../utils/string";
import imageCompression from "browser-image-compression";
import { useDispatchApp } from "../lib/redux";
import { LaunchError } from "../utils/errors";
import {
  getKeyDatabase,
  saveUrl,
  startUploadFileAws,
} from "../services/public";

export const UploadFilesContext = createContext();
const getMaxWidthOrHeight = (width) => {
  if (width < 300) return width;
  return 1500;
};

export const UploadFilesProvider = ({ children }) => {
  const dispatch = useDispatchApp();

  const uploadFile = async ({
    urlPreview,
    file,
    pathStorage,
    pathRTDB,
    doCompression = true,
    onCallbackLoading = () => {},
    modeUpload = "sync",
  }) => {
    try {
      onCallbackLoading(true);
      let newFile = file;
      const extension = file.type.split("/")[1];
      const isImage = IMAGE_FORMATS.includes(extension);

      if (doCompression && isImage) {
        const dimensions = await getHeightAndWidthFromDataUrl(file);
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: getMaxWidthOrHeight(dimensions.width),
          useWebWorker: true,
          initialQuality: 0.8,
        };
        newFile = await imageCompression(file, options);
      }

      const url = await dispatch(
        startUploadFileAws(newFile, file.type, pathStorage)
      );

      if (!url) {
        onCallbackLoading(false);
        return;
      }

      if (pathRTDB) {
        if (modeUpload === "sync") {
          await dispatch(saveUrl(url, pathRTDB));
        } else {
          const key = dispatch(getKeyDatabase());
          const finalPath = `${pathRTDB}/${key}`;

          await dispatch(saveUrl(url, `${finalPath}/value`));
          await dispatch(saveUrl(urlPreview, `${finalPath}/preview`));
        }
      }

      onCallbackLoading(false);

      return url;
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      onCallbackLoading(false);
      return false;
    }
  };

  return (
    <UploadFilesContext.Provider
      value={{
        uploadFile,
      }}
    >
      {children}
    </UploadFilesContext.Provider>
  );
};
