import { useEffect, useState } from "react";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPathDatabaseByRole } from "../../actions/auth";
import {
  startGetAdsCampaign,
  startGetDetailedCampaignReport,
  startGetDetailedReport,
} from "../../actions/adsCampaigns";
import Loader from "../../components/Loaders/Loader";
import {
  BUTTON_FILTER,
  CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN,
  CONCLUSION,
  DATE_SHORT_2,
  EMPTY_STATISTICS_FOR_DETAILED_REPORT,
  LOADING_INFORMATION,
  RECOMMENDATIONS,
  REPORT_BREAKDOWN_PLACEHOLDER,
} from "../../i18n/keysTranslations";
import ContainerPage from "../../components/Containers/ContainerPage";
import Box from "@mui/material/Box";
import HistoricalStatisticsCampaign from "./components/HistoricalStatisticsCampaign.jsx/HistoricalStatisticsCampaign";
import Typography from "@mui/material/Typography";
import GlobalPlatformsStatisticsCampaign from "./components/GlobalPlatformsStatisticsCampaign";
import { Skeleton } from "@mui/material";
import SkeletonLoaderStatisticsGraphs from "./components/SkeletonLoaderStatisticsGraphs";
import Markdown from "../../components/Texts/Markdown";
import CurrentStateCampaign from "./components/CurrentStateCampaign";
import {
  ADS_CAMPAIGN_GENERATE_DETAILED_REPORT_ACTION,
  CHAT_CONFIGURATION_ADS_DETAILED_REPORT_ID,
} from "../../utils/constants";
import { getIsBlockedActions } from "../../actions/getters";
import CommonChatMainContainer from "../../components/CommonChat/CommonChatMainContainer";
import _ from "lodash";
import RangeDateField from "../../components/Texts/RangeDateField";
import { useForm } from "react-hook-form";
import Button from "../../components/Buttons/Button";
import Select from "../../components/Form/Select";
import { optionsAdsCampaignBreakdownsReport } from "../../utils/options";
import useFocusErrorForm from "../../hooks/useFocusErrorForm";
import differenceInWeeks from "date-fns/differenceInWeeks";
import addWeeks from "date-fns/addWeeks";

const getEndDateTime = (startDateTime) => {
  const now = new Date();
  const differenceWeeks = differenceInWeeks(now, startDateTime);
  if (differenceWeeks === 0) return addWeeks(startDateTime, 1);
  if (differenceWeeks >= 1 && differenceWeeks <= 4)
    return addWeeks(startDateTime, differenceWeeks);

  return addWeeks(startDateTime, 4);
};

const AdsCampaignStatistics = () => {
  const [campaign, setCampaign] = useState({});
  const [report, setReport] = useState({});
  const [isEmptyReport, setIsEmptyReport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState(true);
  const [isOpenChat, setIsOpenChat] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");
  const { userID } = dispatch(getPathDatabaseByRole());

  const { control, setValue, getValues, watch, formState } = useForm({
    defaultValues: {
      dateRange: {
        startDateTime: null,
        endDateTime: null,
      },
      breakdown: "weekly",
    },
  });

  const breakdown = watch("breakdown");
  useFocusErrorForm(formState);
  const { errors } = formState;

  const getInitialData = async () => {
    setIsLoading(true);
    const campaignObject = await dispatch(startGetAdsCampaign(campaignID));

    if (!campaignObject) {
      navigate("/");
    }

    setCampaign(campaignObject);

    getDetailedCampaignReport();
    const startDateTime = campaignObject.firstActiveTime
      ? new Date(campaignObject.firstActiveTime)
      : new Date();
    const newReportRange = {
      startDateTime: startDateTime,
      endDateTime: getEndDateTime(startDateTime),
    };
    setValue("dateRange", newReportRange);

    setIsLoading(false);
  };

  const getDetailedCampaignReport = async (isRegeneration) => {
    if (!isRegeneration) {
      const responseDetailedReport = await dispatch(
        startGetDetailedReport({ campaignID })
      );
      if (responseDetailedReport.ok) {
        const { report, statistics } = responseDetailedReport.data;

        setReport(report);
        setCampaign((prev) => ({
          ...prev,
          historicalStatistics: statistics,
        }));
        const newReportRange = {
          startDateTime: new Date(report.startDateTime),
          endDateTime: new Date(report.endDateTime),
        };
        setValue("dateRange", newReportRange);
        setValue("breakdown", report.breakdown);

        setIsEmptyReport(false);
        setIsGeneratingReport(false);
        return;
      }
    }

    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_DETAILED_REPORT_ACTION,
      })
    );

    if (isBlocked) {
      if (!isRegeneration) navigate(`/ads-campaign?campaignID=${campaignID}`);
      return;
    }

    setIsEmptyReport(false);
    setIsGeneratingReport(true);

    const dateRange = getValues("dateRange");

    const response = await dispatch(
      startGetDetailedCampaignReport({
        campaignID,
        breakdown: "weekly", // forced weekly to be able to switch between weekly / month in the UI
        startDateTime: new Date(dateRange.startDateTime).getTime(),
        endDateTime: new Date(dateRange.endDateTime).getTime(),
      })
    );
    if (response.ok) {
      const { report, statistics } = response.data;
      if (_.isEmpty(statistics) && _.isEmpty(report)) {
        setIsEmptyReport(true);
        setIsGeneratingReport(false);
        return;
      }
      setReport(report);
      setCampaign((prev) => ({
        ...prev,
        historicalStatistics: statistics,
      }));
      const newReportRange = {
        startDateTime: new Date(dateRange.startDateTime),
        endDateTime: new Date(dateRange.endDateTime),
      };
      setValue("dateRange", newReportRange);
    } else {
      setIsEmptyReport(true);
    }
    setIsGeneratingReport(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [campaignID, userID]);

  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }

  return (
    <ContainerPage
      mode="padding"
      hasMarginBottom={true}
      sx={{
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "flex-start", sm: "center" },
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
              }}
            >
              {campaign.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t(DATE_SHORT_2, { date: new Date() })}
            </Typography>
          </Box>

          {isGeneratingReport && (
            <Skeleton variant="rounded" width={"100%"} height={100} />
          )}
          {!isEmptyReport && !isGeneratingReport && (
            <Typography variant="body1">{report.overview}</Typography>
          )}

          {!isGeneratingReport && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: 2,
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <RangeDateField
                control={control}
                name="dateRange"
                sx={{
                  width: "100%",
                  flexGrow: {
                    xs: 1,
                    sm: 0,
                  },
                }}
                sxField={{
                  ".MuiInputBase-root": {
                    height: 50,
                  },
                }}
              />
              <Select
                name="breakdown"
                label={t(REPORT_BREAKDOWN_PLACEHOLDER)}
                variant="filled"
                fullWidth
                doTranslate={true}
                options={optionsAdsCampaignBreakdownsReport}
                control={control}
                errors={errors}
                sx={{
                  maxWidth: {
                    xs: "47%",
                    sm: 140,
                    md: 180,
                  },
                  ".MuiInputBase-root": {
                    height: 50,
                  },
                }}
              />
              <Button
                color="white"
                onClick={() => getDetailedCampaignReport(true)}
                sx={{
                  width: { xs: "47%", sm: 130 },
                  minWidth: { xs: "47%", sm: 130 },
                  minHeight: 45,
                }}
              >
                {t(BUTTON_FILTER)}
              </Button>
            </Box>
          )}
        </Box>
        {isEmptyReport && !isGeneratingReport && (
          <Typography variant="body1" align="center">
            {t(EMPTY_STATISTICS_FOR_DETAILED_REPORT)}
          </Typography>
        )}

        {!isEmptyReport && (
          <>
            {isGeneratingReport ? (
              <SkeletonLoaderStatisticsGraphs />
            ) : (
              <>
                <CurrentStateCampaign data={campaign} />
                <GlobalPlatformsStatisticsCampaign
                  data={campaign}
                  report={report}
                />
                <HistoricalStatisticsCampaign
                  data={campaign}
                  report={report}
                  breakdown={breakdown}
                />
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {t(CONCLUSION)}
                  </Typography>
                  <Markdown>{report.conclusion}</Markdown>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {t(RECOMMENDATIONS)}
                  </Typography>
                  <Markdown>{report.recommendations}</Markdown>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      {!_.isEmpty(campaign) && !_.isEmpty(report) && (
        <CommonChatMainContainer
          showChat={isOpenChat}
          onChangeShowChat={() => setIsOpenChat(false)}
          showSendFileButton={false}
          chatConfigurationID={CHAT_CONFIGURATION_ADS_DETAILED_REPORT_ID}
          chatContext={{
            campaign,
            report,
          }}
          startMode="messageInput"
          placeholderInput={CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN}
          headerTitle={CHAT_ASK_A_QUESTION_ABOUT_YOUR_CAMPAIGN}
        />
      )}
    </ContainerPage>
  );
};

export default AdsCampaignStatistics;
