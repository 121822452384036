import { useState } from "react";
import { Controller, useController } from "react-hook-form";
import {
  BUTTON_SELECT_INSTANT_FORM,
  FIELD_REQUIRED,
  FORM,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getReactHookFormNestedError } from "../../utils/errors";
import _ from "lodash";
import Button from "../Buttons/Button";
import ModalInstantFormsList from "./ModalInstantFormsList";

const InstantFormContainer = ({
  label,
  name,
  integrationID,
  pageID,
  control,
  errors = {},
  disabled,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    defaultValue: "",
  });
  const [modalTemplateOpen, setModalTemplateOpen] = useState(false);
  const error = getReactHookFormNestedError(errors, name);
  const { t } = useTranslationApp();

  const onToggleModalTemplate = () => {
    if (disabled) return;
    setModalTemplateOpen((prev) => !prev);
  };

  const isEmptyTemplate = _.isEmpty(value);
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t(FIELD_REQUIRED),
        },
      }}
      render={() => (
        <Box>
          <Typography
            variant="body1"
            gutterBottom
            color={error?.message ? "error" : "default"}
          >
            {label}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {!isEmptyTemplate && (
              <Box>
                <Typography variant="body2">{`${t(FORM)}: ${
                  value.name
                }`}</Typography>

                {!disabled && (
                  <Typography
                    variant="body2"
                    onClick={onToggleModalTemplate}
                    sx={{
                      cursor: "pointer",
                      textDecoration: disabled ? "none" : "underline",
                    }}
                  >
                    {t(BUTTON_SELECT_INSTANT_FORM)}
                  </Typography>
                )}
              </Box>
            )}
            {isEmptyTemplate && (
              <Button
                type="button"
                onClick={onToggleModalTemplate}
                disabled={disabled}
              >
                {t(BUTTON_SELECT_INSTANT_FORM)}
              </Button>
            )}
          </Box>
          {error?.message && (
            <Typography variant="body2" color="error">
              {error.message}
            </Typography>
          )}
          {modalTemplateOpen && (
            <ModalInstantFormsList
              modalOpen={modalTemplateOpen}
              onCloseModal={onToggleModalTemplate}
              integrationID={integrationID}
              pageID={pageID}
              onCallbackSelect={(data) => {
                onChange(data);
              }}
            />
          )}
        </Box>
      )}
    />
  );
};

export default InstantFormContainer;
