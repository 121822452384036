import React, { useEffect, useState } from "react";
import ModalBasicLayout from "../ModalBasicLayout";
import {
  MODAL_VERIFY_INTEGRATION_SCOPES_DESCRIPTION,
  MODAL_VERIFY_INTEGRATION_SCOPES_TITLE,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import { useSelectorApp } from "../../../lib/redux";
import _ from "lodash";
import {
  GOOGLE,
  GOOGLE_SCOPES,
  META,
  META_SCOPES,
  TIKTOK,
  TIKTOK_BUSINESS_SCOPES,
} from "../../../utils/constants";
import { getPlatformFromIntegrationKey } from "../../../actions/getters";
import { getMissingValuesBetweenArrays } from "../../../utils/array";
import VerifyIntegrationScopesItem from "./VerifyIntegrationScopesItem";
import { getUniqueID } from "../../../utils/numbers";
import Box from "@mui/material/Box";


const INTEGRATIONS_TO_WATCH = [META, GOOGLE];
const SCOPES = {
  [META]: META_SCOPES,
  [TIKTOK]: TIKTOK_BUSINESS_SCOPES,
  [GOOGLE]: GOOGLE_SCOPES,
};

const ModalVerifyIntegrationScopes = React.memo(
  function ModalVerifyIntegrationScopes() {
    const [modalOpen, setModalOpen] = useState(false);
    const [integrationsToReconnect, setIntegrationsToReconnect] = useState([]);

    const { t } = useTranslationApp();
    const integrations = useSelectorApp((state) => state?.user?.integrations);
    const integrationsKeys = Object.keys(integrations || {});
    const onCloseModal = () => setModalOpen(false);

    const getName = ({ platform, data }) => {
      if (platform === META) return data.name;
      if (platform === TIKTOK) return data.id;
      if (platform === GOOGLE) return data.id;
    };

    const getIntegrationsToReconnect = () => {
      const integrationsToReconnect = integrationsKeys.filter((key) => {
        const integrationKey = getPlatformFromIntegrationKey(key, true);
        return INTEGRATIONS_TO_WATCH.includes(integrationKey);
      });

      const integrationsToReconnectFiltered = [];

      integrationsToReconnect.forEach((integrationKey) => {
        const integrationsByPlatform = integrations[integrationKey];

        for (const integrationID in integrationsByPlatform) {
          const integration = integrationsByPlatform[integrationID];
          const platformKey = getPlatformFromIntegrationKey(
            integrationKey,
            true
          );
          const missingPermissions = getMissingValuesBetweenArrays({
            currentArray: integration.scopes,
            targetArray: SCOPES[platformKey],
          });
          if (_.isEmpty(missingPermissions)) continue;

          integrationsToReconnectFiltered.push({
            platform: platformKey,
            integrationID,
            name: getName({ platform: platformKey, data: integration }),
            permissions: missingPermissions,
          });
        }
      });
      setIntegrationsToReconnect(integrationsToReconnectFiltered);
      if (integrationsToReconnectFiltered.length > 0) setModalOpen(true);
    };

    useEffect(() => {
      if (_.isEmpty(integrations)) return;

      const hasIntegrationsToWatch = integrationsKeys.some((key) => {
        const integrationKey = getPlatformFromIntegrationKey(key, true);
        return INTEGRATIONS_TO_WATCH.includes(integrationKey);
      });
      if (!hasIntegrationsToWatch) return;

      getIntegrationsToReconnect();
      // eslint-disable-next-line
    }, [integrations]);

    useEffect(() => {
      if (integrationsToReconnect.length === 0 && modalOpen) onCloseModal();

      // eslint-disable-next-line
    }, [integrationsToReconnect]);

    return (
      <ModalBasicLayout
        modalOpen={modalOpen}
        onCloseModal={async () => {
          onCloseModal();
        }}
        maxWidth="sm"
        scroll="paper"
        title={t(MODAL_VERIFY_INTEGRATION_SCOPES_TITLE)}
        description={t(MODAL_VERIFY_INTEGRATION_SCOPES_DESCRIPTION)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          {integrationsToReconnect.map((integration) => (
            <VerifyIntegrationScopesItem
              key={getUniqueID()}
              data={integration}
            />
          ))}
        </Box>
      </ModalBasicLayout>
    );
  }
);

export default ModalVerifyIntegrationScopes;
