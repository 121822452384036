import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { useSelectorApp } from "../../lib/redux";
import RegisterForm from "./components/RegisterForm";

const Register = () => {
  const { theme } = useSelectorApp((state) => state.ui);

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Avatar
        src={theme.logoLogin}
        alt="Logo"
        variant="square"
        sx={{
          width: "auto",
          height: "auto",
          maxHeight: "60px",
          ".MuiAvatar-img": {
            maxWidth: "160px",
            objectFit: "contain",
          },
          mb: 1,
        }}
      />
      <RegisterForm />
    </Container>
  );
};

export default Register;
