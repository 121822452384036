import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  BUTTON_ADD_SELECTED_CONTENT,
  BUTTON_PREVIOUS,
  BUTTON_REMOVE_SELECTION,
  BUTTON_SELECT_THIS_CONTENT,
  LOADING_CONTENT,
  MODAL_GALLERY_ARIA_LABEL,
  MODAL_GALLERY_EMPTY_CONTENT,
} from "../../i18n/keysTranslations";
import DialogActions from "@mui/material/DialogActions";
import ModalMedia from "../Modal/ModalMedia";
import { useTranslationApp } from "../../lib/i18next";
import useNearScreen from "../../hooks/useNearScreen";
import { useDispatchApp } from "../../lib/redux";
import { useDebounced } from "../../hooks/useDebounce";
import ModalBasicLayout from "./ModalBasicLayout";
import ChipsFilterGallery from "../Gallery/ChipsFilterGallery";
import Loader from "../Loaders/Loader";
import Button from "../Buttons/Button";
import Gallery from "../Gallery/Gallery";
import { startGetGalleryContent } from "../../actions/user";
import { usePagination } from "../../hooks/usePagination";
import { getExtensionFile } from "../../utils/string";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "../../utils/constants";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";

const PAGE_SIZE = 10;

const ModalGallery = ({
  modalOpen,
  filterByFormat,
  multiple = true,
  acceptedFormats = VIDEO_FORMATS,
  onCloseModal,
  onCallbackSelectedItems,
}) => {
  const [data, setData] = useState([]);
  const [isFetchingNewContents, setIsFetchingNewContents] = useState(false);
  const [selectPreview, setSelectPreview] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState(filterByFormat);
  const [loading, setLoading] = useState(true);
  const [loadLastItem, setLoadLastItem] = useState(false);

  const { t } = useTranslationApp();

  const galleryRef = useRef(null);

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "100px",
    once: false,
  });

  const dispatch = useDispatchApp();

  const onLoadLastItem = () => setLoadLastItem(true);

  const getInitialData = async () => {
    setLoading(true);

    const response = await dispatch(
      startGetGalleryContent({
        lastKey,
        onChangeLastKey,
        limit: rowsPerPage,
        onLoadLastItem,
      })
    );
    if (response) {
      const dataFormatted = Object.keys(response)
        ?.sort((a, b) => response[b].creationTime - response[a].creationTime)
        ?.map((key) => ({
          ...response[key],
          id: key,
        }));
      setData(dataFormatted);
    }

    setLoading(false);
  };

  const getNextMedia = useDebounced(async () => {
    if (loadLastItem || isFetchingNewContents) return;

    setIsFetchingNewContents(true);
    const response = await dispatch(
      startGetGalleryContent({
        lastKey,
        onChangeLastKey,
        limit: rowsPerPage,
        onLoadLastItem,
      })
    );

    if (response) {
      const dataFormatted = Object.keys(response)
        ?.sort((a, b) => response[b].creationTime - response[a].creationTime)
        ?.map((key) => ({
          ...response[key],
          id: key,
        }));
      setData((prevState) => [...prevState, ...dataFormatted]);
    }
    setIsFetchingNewContents(false);
  }, 1000);

  const { rowsPerPage, lastKey, onChangeLastKey } = usePagination({
    rowsPerPageValue: PAGE_SIZE,
    onCallBackNextPage: getNextMedia,
  });
  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isNearScreen && !loadLastItem) getNextMedia();
    //eslint-disable-next-line
  }, [isNearScreen]);

  const dataFiltered = filter
    ? data.filter((item) => {
        const extension = getExtensionFile(item.url);
        return (
          ((filter === "image" && IMAGE_FORMATS.includes(extension)) ||
            (filter === "video" && VIDEO_FORMATS.includes(extension))) &&
          acceptedFormats.includes(extension)
        );
      })
    : data;

  const onChangeFilter = (filterSelect) => {
    if (filterSelect !== null) {
      if (filterSelect === filter) {
        setFilter(null);
      } else {
        setFilter(filterSelect);
      }
    } else {
      setFilter(filterSelect);
    }
  };

  const onFailedLoadUrl = (index) => {
    setData((prevState) => {
      const newData = [...prevState];
      const filteredData = newData.filter((item, i) => i !== index);
      return filteredData;
    });
  };

  const onSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      if (multiple) {
        setSelectedItems([...selectedItems, index]);
      } else {
        setSelectedItems([index]);
      }
    }
  };

  const onSelectPreview = (index) => {
    setSelectPreview(index);
  };

  const onSendSelectedItems = (indexes) => {
    const dataSelectedItems = [];
    (indexes || selectedItems).forEach((index) => {
      const item = dataFiltered[index];
      dataSelectedItems.push({
        url: item.url,
        from: item.from,
        id: item.id,
        userID: item.userID,
      });
    });
    onCallbackSelectedItems(dataSelectedItems);
    onCloseModal();
  };

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      fullScreen
      scroll="paper"
      aria-label={t(MODAL_GALLERY_ARIA_LABEL)}
      customComponentTitle={
        <>
          {!filterByFormat ? (
            <ChipsFilterGallery
              filterSelect={filter}
              onChangeFilter={onChangeFilter}
            />
          ) : null}
        </>
      }
      sx={{ ".MuiPaper-root": { backgroundColor: "#000" } }}
      sxContent={{
        p: 0,
        pl: 3,
        pb: 2,
        mt: 1,
        overflowX: "hidden",
        "::-webkit-scrollbar": {
          width: 0,
          height: 0,
          background: "transparent",
        },
      }}
      refContent={galleryRef}
    >
      {loading && (
        <Loader size={70} hasMessage={true} message={t(LOADING_CONTENT)} />
      )}
      {!loading && (
        <Gallery
          data={dataFiltered}
          onCloseModal={onCloseModal}
          lastItemRef={fromRef}
          loadLastItem={loadLastItem}
          onFailedLoadUrl={onFailedLoadUrl}
          onSelectItem={onSelectItem}
          selectedItems={selectedItems}
          onSelectPreview={onSelectPreview}
        />
      )}
      {!loading && dataFiltered.length === 0 && loadLastItem && (
        <Box
          sx={{
            height: "calc(100% - 64px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ml: "-12px",
          }}
        >
          <Typography variant="h4" align="center">
            {t(MODAL_GALLERY_EMPTY_CONTENT)}
          </Typography>
        </Box>
      )}

      {selectedItems.length > 0 && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <Button color="secondary" onClick={() => onSendSelectedItems()}>
            {t(BUTTON_ADD_SELECTED_CONTENT)}
          </Button>
        </DialogActions>
      )}
      {selectPreview !== null && (
        <ModalMedia
          modalOpen={selectPreview !== null}
          onCloseModal={() => {
            setSelectPreview(null);
          }}
          url={dataFiltered[selectPreview].url}
          customActions={
            <>
              <Button
                color="primary"
                sx={{ minWidth: 150 }}
                onClick={() => {
                  setSelectPreview(null);
                }}
              >
                {t(BUTTON_PREVIOUS)}
              </Button>

              <Button
                sx={{
                  minWidth: 150,
                  background: GRADIENT_PURPLE_FUCHSIA,
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                }}
                onClick={async () => {
                  if (selectedItems.includes(selectPreview)) {
                    setSelectedItems(
                      selectedItems.filter((item) => item !== selectPreview)
                    );
                  } else {
                    if (multiple) {
                      onSelectItem(selectPreview);
                    } else {
                      onSendSelectedItems([selectPreview]);
                    }
                  }
                  setSelectPreview(null);
                }}
              >
                {t(
                  selectedItems.includes(selectPreview)
                    ? BUTTON_REMOVE_SELECTION
                    : BUTTON_SELECT_THIS_CONTENT
                )}
              </Button>
            </>
          }
          disabledActions={true}
        />
      )}
    </ModalBasicLayout>
  );
};

export default ModalGallery;
