import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LineChart from "../../../../components/Charts/LineChart";
import BarChart from "../../../../components/Charts/BarChart";
import { useTranslationApp } from "../../../../lib/i18next";
import {
  AVERAGE,
  DATE_DAY_AND_MONTH_SHORT,
  MONTH_COUNT,
  VALUE_WITH_CURRENCY_MANUAL_FORMAT,
  WEEK_COUNT,
} from "../../../../i18n/keysTranslations";
import {
  getFromStatisticsAdsCampaign,
  mapTitleStatisticsByMetric,
  sumStatisticsAdsCampaign,
} from "../../../../services/adsCampaigns";
import { getUniqueID, numberDecimal } from "../../../../utils/numbers";
import { logoPlatform } from "../../../../utils/string";
import useResizeItemsWithSameHeight from "../../../../hooks/useResizeItemsWithSameHeight";

const getLabelCount = (breakdown) => {
  switch (breakdown) {
    case "weekly":
      return WEEK_COUNT;

    case "monthly":
      return MONTH_COUNT;

    default:
      return WEEK_COUNT;
  }
};

const HistoricalStatisticsCampaign = ({ data, report, breakdown }) => {
  const { from, platforms, statistics, historical } =
    getFromStatisticsAdsCampaign(data);

  return (
    <>
      {platforms.map((platform) => (
        <ComponentByPlatform
          key={getUniqueID()}
          campaign={data}
          data={from === "current" ? statistics : historical}
          total={statistics}
          report={report}
          platform={platform}
          breakdown={breakdown}
          from={from}
        />
      ))}
    </>
  );
};

const ComponentByPlatform = ({
  data,
  report,
  platform,
  from = "current",
  breakdown,
}) => {
  const refs = useResizeItemsWithSameHeight();

  const reportByPlatform = report?.reportByPlatforms?.[platform] || {};
  const startDateTime = new Date(report?.startDateTime);
  const endDateTime = new Date(report?.endDateTime);
  const { t } = useTranslationApp();
  const label = getLabelCount(breakdown);

  const getChartConfigAndDataByMetric = ({ metric, type = "bar" }) => {
    let chartConfig = {};
    let chartData = [];
    let currency;

    if (from === "current") {
      const campaignStatisticsByPlatform = data[platform];
      currency = campaignStatisticsByPlatform.currency || "USD";

      const currentChartData = {
        label: t(label, {
          value: 1,
        }),
        value: numberDecimal(campaignStatisticsByPlatform[metric]),
      };

      if (type === "line") {
        currentChartData.average =
          (metric === "cpm"
            ? reportByPlatform.avgCpm
            : reportByPlatform.avgCpc) || 0;
      }

      chartData.push(currentChartData);

      chartConfig = {
        value: {
          label: mapTitleStatisticsByMetric({ metric, currency }),
          color: "hsl(var(--chart-1))",
          hasLabelList: true,
        },
      };

      if (type === "line") {
        chartConfig.average = {
          label: t(AVERAGE),
          color: "hsl(var(--chart-3))",
          hasLabelList: false,
        };
      }
    } else {
      let dataProcessed = {};

      if (breakdown === "monthly") {
        const dataKeys = Object.keys(data);
        const batchSize = 4;
        //group by month (4 snapshots in 1, use sumStatisticsAdsCampaign)
        for (let i = 0; i < dataKeys.length; i += batchSize) {
          const batch = dataKeys.slice(i, i + batchSize);
          let snapshot = {};
          for (let j = 0; j < batchSize; j++) {
            if (!batch[j]) break;

            const dataSnapshot = data?.[batch?.[j]];

            if (j === 0) {
              snapshot.startDateTime = data?.[batch?.[j]]?.startDateTime;
            }
            if (j === batchSize - 1) {
              snapshot.endDateTime = data?.[batch?.[j]]?.endDateTime;
            }

            const platformsStatistics = Object.keys(dataSnapshot).filter(
              (key) => key !== "startDateTime" && key !== "endDateTime"
            );

            for (const platform of platformsStatistics) {
              snapshot[platform] = {
                campaign: sumStatisticsAdsCampaign({
                  current: snapshot?.[platform]?.campaign,
                  addition: dataSnapshot?.[platform]?.campaign,
                }),
              };
            }
          }

          dataProcessed[dataKeys[i]] = snapshot;
        }
      } else {
        dataProcessed = data;
      }

      Object.keys(dataProcessed).forEach((snapshotKey, index) => {
        const snapshot = dataProcessed[snapshotKey] || {};

        const { [platform]: platformStatistics } = snapshot;
        const campaignStatisticsByPlatform = platformStatistics?.campaign || {};
        currency = campaignStatisticsByPlatform?.currency || "USD";

        const currentChartData = {
          label: t(label, {
            value: index + 1,
          }),
          value: numberDecimal(campaignStatisticsByPlatform[metric]),
        };
        if (type === "line") {
          currentChartData.average =
            (metric === "cpm"
              ? reportByPlatform.avgCpm
              : reportByPlatform.avgCpc) || 0;
        }
        chartData.push(currentChartData);
      });

      chartConfig = {
        value: {
          label: mapTitleStatisticsByMetric({ metric, currency }),
          color: "hsl(var(--chart-1))",
          hasLabelList: true,
        },
      };
      if (type === "line") {
        chartConfig.average = {
          label: t(AVERAGE),
          color: "hsl(var(--chart-3))",
          hasLabelList: false,
        };
      }
    }

    const title = mapTitleStatisticsByMetric({
      metric,
      currency,
    });

    const subtitle = `${t(DATE_DAY_AND_MONTH_SHORT, {
      date: startDateTime,
    })} - ${t(DATE_DAY_AND_MONTH_SHORT, {
      date: endDateTime,
    })}`;

    return {
      chartConfig,
      chartData,
      title,
      subtitle,
      currency,
      xAxisKey: "label",
    };
  };

  const chartSpend = getChartConfigAndDataByMetric({ metric: "spend" });
  const chartImpressions = getChartConfigAndDataByMetric({
    metric: "impressions",
  });
  const chartMainAction = getChartConfigAndDataByMetric({
    metric: "mainAction",
  });
  const chartCpa = getChartConfigAndDataByMetric({
    metric: "costPerMainAction",
  });
  const chartCpm = getChartConfigAndDataByMetric({
    metric: "cpm",
    type: "line",
  });
  const chartCpc = getChartConfigAndDataByMetric({
    metric: "cpc",
    type: "line",
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar
          src={logoPlatform(platform)}
          alt={`Logo ${platform}`}
          variant="square"
          sx={{
            height: 25,
            width: 25,
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
          }}
        >
          {t(platform)}
        </Typography>
      </Box>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            mt: -1,
          }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              ref={(el) => (refs.current[0] = el)}
              chartConfig={chartSpend.chartConfig}
              chartData={chartSpend.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartSpend.xAxisKey}
              title={`${chartSpend.title} (${chartSpend.currency})`}
              subtitle={chartSpend.subtitle}
              description={reportByPlatform.spend}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              ref={(el) => (refs.current[1] = el)}
              chartConfig={chartImpressions.chartConfig}
              chartData={chartImpressions.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartImpressions.xAxisKey}
              title={chartImpressions.title}
              subtitle={chartImpressions.subtitle}
              description={reportByPlatform.impressions}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              ref={(el) => (refs.current[2] = el)}
              chartConfig={chartMainAction.chartConfig}
              chartData={chartMainAction.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartMainAction.xAxisKey}
              title={chartMainAction.title}
              subtitle={chartMainAction.subtitle}
              description={reportByPlatform.results}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <BarChart
              ref={(el) => (refs.current[3] = el)}
              chartConfig={chartCpa.chartConfig}
              chartData={chartCpa.chartData}
              hasCartesianGrid={false}
              hasLegend={false}
              xAxisKey={chartCpa.xAxisKey}
              title={`${chartCpa.title} (${chartSpend.currency})`}
              subtitle={chartCpa.subtitle}
              description={reportByPlatform.costPerMainAction}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LineChart
              ref={(el) => (refs.current[4] = el)}
              chartConfig={chartCpm.chartConfig}
              chartData={chartCpm.chartData}
              hasCartesianGrid={false}
              xAxisKey={chartCpm.xAxisKey}
              title={chartCpm.title}
              subtitle={chartCpm.subtitle}
              description={reportByPlatform.cpm}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LineChart
              ref={(el) => (refs.current[5] = el)}
              chartConfig={chartCpc.chartConfig}
              chartData={chartCpc.chartData}
              hasCartesianGrid={false}
              xAxisKey={chartCpc.xAxisKey}
              title={chartCpc.title}
              subtitle={chartCpc.subtitle}
              description={reportByPlatform.cpc}
              valueFormat={{
                translation: VALUE_WITH_CURRENCY_MANUAL_FORMAT,
                key: "value",
                options: {
                  currency: chartSpend.currency,
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HistoricalStatisticsCampaign;
