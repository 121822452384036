import { useEffect, useState } from "react";
import { LOADING_INFORMATION } from "../../i18n/keysTranslations";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADS_CAMPAIGN_MODE_EDIT,
  ADS_CAMPAIGN_MODE_PREVIEW,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGNS_DEMO,
  META,
} from "../../utils/constants";
import Box from "@mui/material/Box";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp } from "../../lib/redux";
import {
  startGetAdsCampaign,
  startUpdateAdsCampaignsInformationFromPlatforms,
} from "../../actions/adsCampaigns";
import Loader from "../../components/Loaders/Loader";
import ContainerPage from "../../components/Containers/ContainerPage";
import AdsCampaignInformation from "./components/AdsCampaignInformation";
import { getStatusAdsCampaign } from "../../services/adsCampaigns";
import { getPathDatabaseByRole } from "../../actions/auth";

const AdsCampaign = () => {
  const [campaign, setCampaign] = useState({});
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const currencyAccount = "USD";

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");
  const { userID } = dispatch(getPathDatabaseByRole());

  const getInitialData = async () => {
    setIsLoading(true);
    const response = await dispatch(startGetAdsCampaign(campaignID));

    if (response) {
      setCampaign({
        ...response,
        platforms: ADS_CAMPAIGNS_DEMO.includes(campaignID)
          ? [META]
          : response.platforms,
      });
      const status = getStatusAdsCampaign({
        states: response.states,
        target: "general",
      });
      if (status === ADS_CAMPAIGN_STATUS_DRAFT) {
        setMode(ADS_CAMPAIGN_MODE_PREVIEW);
      } else {
        setMode(ADS_CAMPAIGN_MODE_EDIT);
        if (status === ADS_CAMPAIGN_STATUS_ACTIVE) {
          getUpdatedData();
        }
      }
      setIsLoading(false);
    } else {
      navigate("/");
    }

    setIsLoading(false);
  };

  const getUpdatedData = async () => {
    await dispatch(
      startUpdateAdsCampaignsInformationFromPlatforms({
        campaignIDs: [campaignID],
      })
    );
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [campaignID, userID]);

  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }

  return (
    <ContainerPage
      mode="padding"
      hasMarginBottom={true}
      sx={{
        minHeight: "calc(100vh - 64px)",
        mb: mode === ADS_CAMPAIGN_MODE_PREVIEW ? 44 : 15,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 2,
        }}
      >
        <AdsCampaignInformation
          data={campaign}
          mode={mode}
          onChangeMode={setMode}
          currency={currencyAccount}
          campaignID={campaignID}
          onChangeData={setCampaign}
        />
      </Box>
    </ContainerPage>
  );
};

export default AdsCampaign;
