import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { useSelectorApp } from "../../../lib/redux";
import {
  META,
  TIKTOK,
  GOOGLE,
  BORDER_RADIUS,
  IDS_PLATFORM_BUTTONS,
  PLATFORMS_SUPPORTED_WITH_COMING_SOON,
  LINKEDIN,
} from "../../../utils/constants";
import {
  META as META_TRANSLATION,
  TIKTOK as TIKTOK_TRANSLATION,
  GOOGLE as GOOGLE_TRANSLATION,
  LINKEDIN as LINKEDIN_TRANSLATION,
  META_TOGGLE_DESCRIPTION,
  GOOGLE_TOGGLE_DESCRIPTION,
  TIKTOK_TOGGLE_DESCRIPTION,
  BETA,
  LINKEDIN_TOGGLE_DESCRIPTION,
  COMING_SOON,
} from "../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../lib/i18next";
import Checkbox from "@mui/material/Checkbox";
import MetaContainerIntegration from "../../../components/Meta/MetaContainerIntegration";
import TikTokBusinessContainerIntegration from "../../../components/TikTokBusiness/TikTokBusinessContainerIntegration";
import { useMediaQuery } from "@mui/material";
import GoogleContainerIntegration from "../../../components/Google/GoogleContainerIntegration";
import React from "react";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import ButtonLinkedinAction from "../../../components/Buttons/ButtonLinkedinAction";

const ToggleSelectPlatforms = ({
  name,
  control,
  rules = {},
  onChangeAdAccount = () => {},
  onChangeIntegrationID = () => {},
}) => {
  const integrations = useSelectorApp((state) => state.user.integrations);
  const metaIntegrations = integrations?.facebook || {};
  const isMetaConnected = Object.values(metaIntegrations).some(
    (integration) => integration.isActive
  );
  const tikTokBusinessIntegrations = integrations?.tiktokBusiness || {};
  const isTikTokBusinessConnected = Object.values(
    tikTokBusinessIntegrations
  ).some((integration) => integration.isActive);
  const googleIntegrations = integrations?.google || {};
  const isGoogleConnected = Object.values(googleIntegrations).some(
    (integration) => integration.isActive
  );

  const isMobileWidth = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMobileHeight = useMediaQuery("(max-height:650px)");
  const isMobile = isMobileWidth || isMobileHeight;

  const { t } = useTranslationApp();

  const commonSxContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    p: 1,
    width: "auto",
    borderRadius: BORDER_RADIUS,
  };
  const commonSxContainerPlatform = {
    maxWidth: { xs: "100%", sm: "50%" },
  };
  const commonSxTitle = {
    mb: 1,
  };
  const commonSxCheckbox = {
    ml: 0,
    p: 0,
    pt: 0.3,
  };
  const commonSxButtonPlatform = {
    width: 210,
  };

  const titlePlatformVariant = isMobile ? "body1" : "h6";

  const onClickConnectAccount = async (platform) => {
    const element = document.getElementById(IDS_PLATFORM_BUTTONS[platform]);
    element.click();
    return;
  };

  const getConfigurationPlatform = (platform) => {
    if (platform === META) {
      return {
        isConnected: isMetaConnected,
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <MetaContainerIntegration />,
        title: META_TRANSLATION,
        description: META_TOGGLE_DESCRIPTION,
        chipWidth: { xs: 210, sm: 154 },
      };
    }
    if (platform === TIKTOK) {
      return {
        isConnected: isTikTokBusinessConnected,
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <TikTokBusinessContainerIntegration />,
        title: TIKTOK_TRANSLATION,
        description: TIKTOK_TOGGLE_DESCRIPTION,
        chipWidth: { xs: 210, sm: 144 },
      };
    }
    if (platform === GOOGLE) {
      return {
        isConnected: isGoogleConnected,
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <GoogleContainerIntegration />,
        title: GOOGLE_TRANSLATION,
        description: GOOGLE_TOGGLE_DESCRIPTION,
        chipWidth: { xs: 210, sm: 134 },
      };
    }
    if (platform === LINKEDIN) {
      return {
        isConnected: false,
        id: IDS_PLATFORM_BUTTONS[platform],
        container: <ButtonLinkedinAction />,
        title: LINKEDIN_TRANSLATION,
        description: LINKEDIN_TOGGLE_DESCRIPTION,
        chipWidth: { xs: 210, sm: 134 },
        isComingSoon: true,
      };
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Box
          sx={{
            ...commonSxContainer,
            flexWrap: { xs: "nowrap", sm: "wrap" },
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignItems: "flex-start",
            p: 0,
            width: "100%",
          }}
        >
          {PLATFORMS_SUPPORTED_WITH_COMING_SOON.map((platform) => {
            const configurationPlatform = getConfigurationPlatform(platform);

            return (
              <Box
                key={platform}
                sx={{
                  ...commonSxContainer,
                  ...commonSxContainerPlatform,
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Checkbox
                  edge="start"
                  disabled={configurationPlatform.isComingSoon}
                  checked={field.value?.includes(platform)}
                  onChange={() => {
                    if (!configurationPlatform.isConnected) {
                      onClickConnectAccount(platform);
                      return;
                    }
                    field.onChange(
                      field.value?.includes(platform)
                        ? field.value.filter((v) => v !== platform)
                        : [...field.value, platform]
                    );
                  }}
                  name={field.name}
                  sx={commonSxCheckbox}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: 0, sm: 1 },
                      mb: { xs: 1, sm: 0 },
                    }}
                  >
                    <Typography
                      variant={titlePlatformVariant}
                      sx={{ ...commonSxTitle }}
                    >
                      {t(configurationPlatform.title)}
                    </Typography>
                    <Tooltip title={t(configurationPlatform.description)}>
                      <Chip
                        label={t(configurationPlatform.description)}
                        sx={{
                          maxWidth: configurationPlatform.chipWidth,
                          width: "100%",
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {React.cloneElement(configurationPlatform.container, {
                    id: configurationPlatform.id,
                    sx: {
                      ...commonSxButtonPlatform,
                    },
                    onChangeIntegration: ({ integrationID, adAccountID }) => {
                      onChangeIntegrationID({ platform, integrationID });
                      onChangeAdAccount({ platform, adAccountID });
                    },
                    onClick: () => {
                      if (configurationPlatform.isConnected) return;

                      field.onChange(
                        field.value?.includes(platform)
                          ? field.value.filter((v) => v !== platform)
                          : [...field.value, platform]
                      );
                    },
                  })}
                  {configurationPlatform.isBeta && (
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 0.5,
                        color: "GrayText",
                        fontStyle: "italic",
                      }}
                    >
                      {t(BETA)}
                    </Typography>
                  )}
                  {configurationPlatform.isComingSoon && (
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 0.5,
                        color: "GrayText",
                        fontStyle: "italic",
                      }}
                    >
                      {t(COMING_SOON)}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    />
  );
};

export default ToggleSelectPlatforms;
