import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import {
  BUTTON_BUY_PLAN,
  MOST_POPULAR,
  CUSTOM,
  BUTTON_CONTACT_US,
  BUTTON_UPGRADE_PLAN,
  VALUE_SUBSCRIPTION_PRORATED,
  VALUE_SUBSCRIPTION_AFTER_PRORATED,
  VALUE_WITH_CURRENCY,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import Button from "../Buttons/Button";
import {
  LINK_LANDING_CONTACT,
  PLAN_GROWTH,
  RECURRENT_MULTIPLIER,
} from "../../utils/constants";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../utils/colors";
import { openLink } from "../../utils/string";
import { useSelectorApp } from "../../lib/redux";

const PricingCard = ({ data, recurrent, onChangePlanSelected = () => {} }) => {
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery("(max-width:600px)");

  const isProrated = useSelectorApp((state) => state.payments.subscriptionPlan);
  const subscriptionPlanInfo = useSelectorApp(
    (state) => state.payments.subscriptionPlanInfo
  );

  const isFeaturedPlan = data.id === PLAN_GROWTH;
  const isEnterprise = data.id === "enterprise";

  const getInformationPlan = () => {
    const information = data[recurrent];
    return information;
  };
  const { price } = getInformationPlan();

  const getMainValueCard = () => {
    if (isEnterprise) return t(CUSTOM);

    if (isProrated)
      return t(VALUE_SUBSCRIPTION_PRORATED, {
        value: data.priceProrated,
        currency: "USD",
      });
    return t(VALUE_WITH_CURRENCY, {
      value: price,
      currency: "USD",
    });
  };

  const getFinalPrice = () => {
    if (isEnterprise) return 0;
    if (isProrated) return data.priceProrated;
    return price;
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
        borderRadius: 4,
        minHeight: isEnterprise ? "min-content" : 370,
      }}
    >
      <CardHeader
        title={data.name}
        action={data.id === "growth" && <StarIcon />}
        subheader={data.id === "growth" && t(MOST_POPULAR)}
        titleTypographyProps={{
          align: "center",
          fontWeight: 600,
          fontStyle: "italic",
        }}
        subheaderTypographyProps={{
          align: "center",
          color: "white",
        }}
        sx={{
          backgroundColor: "black",
          color: "white",
          minHeight: 88,
        }}
      />
      <CardContent>
        <Typography variant="subtitle1" align="center" sx={{}}>
          {t(data.description)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h3"
              variant={isMobile ? "h6" : "h5"}
              color="text.primary"
              align="center"
            >
              {getMainValueCard()}
            </Typography>
            {isProrated && subscriptionPlanInfo?.renewDate && (
              <Typography
                component="h3"
                variant={isMobile ? "body1" : "body2"}
                color="text.primary"
                align="center"
              >
                {t(VALUE_SUBSCRIPTION_AFTER_PRORATED, {
                  value: price,
                  currency: "USD",
                  date: new Date(subscriptionPlanInfo?.renewDate),
                })}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          component="ul"
          sx={{
            p: 0,
            listStyle: "none",
          }}
        >
          {data.benefits.map((benefit, index) => (
            <Typography
              component="li"
              variant="subtitle2"
              align="left"
              key={index}
            >
              {t(benefit.translation, {
                value: benefit.value * RECURRENT_MULTIPLIER[recurrent],
              })}
            </Typography>
          ))}
        </Box>
      </CardContent>
      <CardActions sx={{ mb: 2, display: "flex", alignContent: "flex-end" }}>
        <Button
          fullWidth
          variant={!isFeaturedPlan ? "outlined" : "contained"}
          color="white"
          onClick={() => {
            if (isEnterprise) return openLink(LINK_LANDING_CONTACT);
            onChangePlanSelected({
              id: data.id,
              price: getFinalPrice(),
              recurrent,
            });
          }}
          sx={{
            background: isFeaturedPlan && GRADIENT_PURPLE_FUCHSIA,
            color: isFeaturedPlan && GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
            ml: "0px !important",
            width: "100%",
          }}
        >
          {isEnterprise
            ? t(BUTTON_CONTACT_US)
            : t(isProrated ? BUTTON_UPGRADE_PLAN : BUTTON_BUY_PLAN, {
                value: data.name,
              })}
        </Button>
      </CardActions>
    </Card>
  );
};

export default PricingCard;
