import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslationApp } from "../../lib/i18next";
import ModalWatchTutorial from "../Modal/ModalWatchTutorial";
import { TUTORIAL } from "../../i18n/keysTranslations";
import YouTubeIcon from "@mui/icons-material/YouTube";

const ButtonOpenTutorial = () => {
  const [modalTutorialOpen, setModalTutorialOpen] = useState(false);
  const { t } = useTranslationApp();
  return (
    <Box
      onClick={() => setModalTutorialOpen(true)}
      className="button-help-video"
      sx={{
        mx: 0,
        mr: 1.5,
        pt: 0.5,
        cursor: "pointer",
      }}
    >
      <Box
        onClick={() => setModalTutorialOpen(true)}
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "background.paperSecondaryContrastText",
            fontWeight: 600,
          }}
        >
          {t(TUTORIAL)}
        </Typography>
        <YouTubeIcon
          sx={{
            color: "background.paperSecondaryContrastText",
          }}
        />
      </Box>
      {modalTutorialOpen && (
        <ModalWatchTutorial
          modalOpen={modalTutorialOpen}
          onCloseModal={() => setModalTutorialOpen(false)}
        />
      )}
    </Box>
  );
};

export default ButtonOpenTutorial;
