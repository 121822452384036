import {
  ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION,
  DOWNLOADS,
  ERROR,
  FIELD_TRAFFIC_URL_NOT_BE_LINK_FACEBOOK,
  FIELD_TRAFFIC_URL_NOT_BE_LINK_INSTAGRAM,
  FIELD_TRAFFIC_URL_NOT_BE_LINK_WHATSAPP,
  INTERACTIONS,
  LEADS,
  SALES,
} from "../i18n/keysTranslations";
import { errorCodeToText } from "./errors";
import AES from "crypto-js/aes";
import logoYT from "../assets/img/logo-yt.png";
import logoIG from "../assets/img/logo-ig.webp";
import logoFB from "../assets/img/logo-fb.webp";
import logoIGWhite from "../assets/img/logo-ig-white.png";
import logoTikTok from "../assets/img/logo-tiktok.webp";
import logoTikTokBusiness from "../assets/img/logo-tiktok-business.webp";
import logoCanva from "../assets/img/logo-canva.webp";
import logoMeta from "../assets/img/logo-meta.webp";
import logoMetaWhite from "../assets/img/logo-meta-white.webp";
import logoGoogle from "../assets/img/logo-google-ads.webp";
import logoLinkedIn from "../assets/img/logo-linkedin.webp";
import {
  INSTAGRAM,
  INSTAGRAM_WHITE,
  TIKTOK,
  YOUTUBE,
  FACEBOOK,
  META,
  META_WHITE,
  TIKTOK_BUSINESS,
  ALERT_ICON_TYPE_ERROR,
  CANVA,
  GOOGLE,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
  MAX_SIZE_FILES,
  MAX_SIZE_IMAGE,
  LINKEDIN,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
} from "./constants";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DownloadIcon from "@mui/icons-material/Download";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { SimpleAlert } from "../components/Alerts/Alerts";
import i18next from "i18next";
import imageCompression from "browser-image-compression";
export const removeContactInfoFromString = (message) => {
  // This regex pattern should catch more phone numbers (including international ones) but still may not cover all cases.
  const phoneNumberRegex = /(\+\d{1,3})?([.\s]?\d){5,15}/g;

  const spelledNumberRegex =
    /\b(zero|one|two|three|four|five|six|seven|eight|nine|cero|uno|dos|tres|cuatro|cinco|seis|siete|ocho|nueve)\b/gi;

  // This regex pattern is for detecting email addresses.
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  // // Simple regex pattern for URLs (http, https, www)
  //eslint-disable-next-line
  // const urlRegex = /(\b(https?:\/\/|www\.)[-A-Z0-9+&@#\/%?=_|!:,.;]*[-A-Z0-9+&@#\/%=_|])/gi;

  // // Replace detected phone numbers, email addresses and URLs with '**'
  let sanitizedMessage = message.replace(emailRegex, "**");
  sanitizedMessage = sanitizedMessage.replace(phoneNumberRegex, " ***");
  sanitizedMessage = sanitizedMessage.replace(spelledNumberRegex, " ***");
  // sanitizedMessage = sanitizedMessage.replace(urlRegex, "**");

  return sanitizedMessage;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getUUIDFromURL = (url) => {
  url = decodeURIComponent(url);

  let fragments = url.split("/");
  let uuid = fragments[fragments.length - 1];
  if (uuid.indexOf("?") !== -1) {
    uuid = uuid.split("?")[0];
  }

  return uuid;
};

export const openLink = (url) => window.open(url, "_blank");

export const getObjectError = (error) => {
  if (error?.response?.data) {
    const codeError = error?.response?.data?.code;
    const messageError = error?.response?.data?.message;
    const dataError = error?.response?.data?.data;
    const errorTranslated = errorCodeToText(codeError);
    return {
      ok: false,
      code: codeError,
      message: errorTranslated === codeError ? messageError : errorTranslated,
      data: dataError,
    };
  }

  const errorTranslated = errorCodeToText(error.code);

  return {
    ok: false,
    message: errorTranslated,
    code: error.code,
  };
};

export const encryptPassword = (password) => {
  try {
    const bytes = AES.encrypt(
      password,
      import.meta.env.VITE_KEY_ENCRYPT
    ).toString();
    return bytes;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const logoPlatform = (platform) => {
  switch (platform) {
    case TIKTOK:
      return logoTikTok;
    case TIKTOK_BUSINESS:
      return logoTikTokBusiness;
    case INSTAGRAM:
      return logoIG;
    case INSTAGRAM_WHITE:
      return logoIGWhite;
    case YOUTUBE:
      return logoYT;
    case FACEBOOK:
      return logoFB;
    case META:
      return logoMeta;
    case META_WHITE:
      return logoMetaWhite;
    case CANVA:
      return logoCanva;
    case GOOGLE:
      return logoGoogle;
    case LINKEDIN:
      return logoLinkedIn;
    default:
      break;
  }
};
export const getExtensionFile = (url = "") => {
  if (typeof url !== "string") return "";
  if (url?.includes("blob:")) {
    const urlSplit = url.split("ext=");
    const extension = urlSplit[urlSplit.length - 1];

    return extension.toLowerCase();
  }
  const finalUrl = url.split("?")[0];
  const lengthUrlSplit = finalUrl.split(".");
  const extension = lengthUrlSplit[lengthUrlSplit.length - 1].split("?")[0];
  return extension.toLowerCase();
};

export const formatUrlMedia = (url = "") => {
  if (url?.includes("blob:")) {
    return url?.split("?")[0];
  }
  return url;
};

export async function downloadContent(urlFrom) {
  try {
    const response = await fetch(urlFrom, {
      cache: "no-store",
    });

    if (response.ok) {
      const blob = await response.blob();
      let fileName = getUUIDFromURL(urlFrom);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");

      const extension = getExtensionFile(urlFrom);
      if (extension === "quicktime") {
        fileName = fileName.replace("quicktime", "mov");
      }

      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    }
    return response.ok;
  } catch (error) {
    console.error(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: error.message,
      icon: ALERT_ICON_TYPE_ERROR,
    });
  }
}

export const getFileFromUrl = async (url) => {
  try {
    const response = await fetch(url, {
      cache: "no-store",
    });
    if (response.ok) {
      const data = await response.blob();
      return data;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getHeightAndWidthFromDataUrl = (file) =>
  new Promise((resolve) => {
    const dataURL = window.URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

export const getAnalyticsByUser = (user = {}) => {
  return {
    followers: user.followers || 0,
    engagement: user.engagement || 0,
    detailedAnalytics: user.detailedAnalytics || "",
  };
};

export const getKeyMetricAdsCampaign = (data = {}) => {
  const objective = data.objective;
  const statistics = data.statistics || {};

  if (objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_DESCRIPTION_EXPLANATION) {
    return {
      value: statistics.interactions,
      label: INTERACTIONS,
      icon: ThumbUpIcon,
    };
  }
  if (objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_DESCRIPTION_EXPLANATION) {
    return {
      value: statistics.leads,
      label: LEADS,
      icon: AssignmentTurnedInIcon,
    };
  }
  if (
    objective === ADS_CAMPAIGN_OBJECTIVE_APP_PROMOTION_DESCRIPTION_EXPLANATION
  ) {
    return {
      value: statistics.downloads,
      label: DOWNLOADS,
      icon: DownloadIcon,
    };
  }
  if (objective === ADS_CAMPAIGN_OBJECTIVE_SALES_DESCRIPTION_EXPLANATION) {
    return {
      value: statistics.sales,
      label: SALES,
      icon: MonetizationOnIcon,
    };
  }
};

export const getInfluencerSocialLink = (influencer = {}) => {
  const { mainPlatform, mainAccountName } = influencer;

  if (mainPlatform === "instagram") {
    return `https://www.instagram.com/${mainAccountName}`;
  }
  if (mainPlatform === "tiktok") {
    return `https://www.tiktok.com/@${mainAccountName}`;
  }
  if (mainPlatform === "youtube") {
    return `https://www.youtube.com/c/${mainAccountName}`;
  }
};

export const getFormattedCampaign = ({ data = {}, format = "load" }) => {
  let campaignFormatted = { ...data };

  if (format === "load") {
    campaignFormatted = { ...data, adsGroups: [] };
    Object.keys(data.adsGroups || {}).forEach((key) => {
      const adGroup = data.adsGroups[key];
      const adGroupFormatted = {
        ...adGroup,
        ads: [],
      };

      Object.keys(adGroup.ads || {}).forEach((key) => {
        const ad = adGroup.ads[key];
        const titles = ad.titles
          ? ad.titles.map((title) => ({
              id:
                new Date().getTime().toString() +
                Math.random().toString(36).substring(2),
              value: title,
            }))
          : [
              {
                value: "",
                id:
                  new Date().getTime().toString() +
                  Math.random().toString(36).substring(2),
              },
            ];
        const descriptions = ad.descriptions
          ? ad.descriptions.map((description) => ({
              id:
                new Date().getTime().toString() +
                Math.random().toString(36).substring(2),
              value: description,
            }))
          : [
              {
                value: "",
                id:
                  new Date().getTime().toString() +
                  Math.random().toString(36).substring(2),
              },
            ];
        const headlines = ad.headlines
          ? ad.headlines.map((headline) => ({
              id:
                new Date().getTime().toString() +
                Math.random().toString(36).substring(2),
              value: headline,
            }))
          : [
              {
                value: "",
                id:
                  new Date().getTime().toString() +
                  Math.random().toString(36).substring(2),
              },
            ];
        const destinationUrls = ad.destinationUrls
          ? ad.destinationUrls.map((destinationUrl) => ({
              id:
                new Date().getTime().toString() +
                Math.random().toString(36).substring(2),
              value: destinationUrl,
            }))
          : [
              {
                value: "",
                id:
                  new Date().getTime().toString() +
                  Math.random().toString(36).substring(2),
              },
            ];

        const adFormatted = {
          ...ad,
          titles,
          descriptions,
          headlines,
          destinationUrls,
        };

        adGroupFormatted.ads.push(adFormatted);
      });

      campaignFormatted.adsGroups.push(adGroupFormatted);
    });
  }

  if (format === "save") {
    campaignFormatted = { ...data, adsGroups: {} };
    (data.adsGroups || []).forEach((adGroupKey, indexAdGroup) => {
      const adGroup = data.adsGroups[indexAdGroup];
      const adGroupFormatted = { ...adGroup, ads: {} };

      (adGroup.ads || []).forEach((adKey, indexAd) => {
        const ad = adGroup.ads[indexAd];
        const titles = ad.titles ? ad.titles.map((title) => title.value) : null;
        const descriptions = ad.descriptions
          ? ad.descriptions.map((description) => description.value)
          : null;
        const headlines = ad.headlines
          ? ad.headlines.map((headline) => headline.value)
          : null;
        const destinationUrls = ad.destinationUrls
          ? ad.destinationUrls.map((destinationUrl) => destinationUrl.value)
          : null;

        const adFormatted = {
          ...ad,
          titles,
          descriptions,
          headlines,
          destinationUrls,
        };

        adGroupFormatted.ads[ad.id] = adFormatted;
      });

      campaignFormatted.adsGroups[adGroup.id] = adGroupFormatted;
    });
  }

  return campaignFormatted;
};

export const normalizeUrlToCompare = ({ url = "", returnHostname = false }) => {
  try {
    if (typeof url !== "string") return url;

    let formattedUrl = url.toLowerCase().trim();

    if (!formattedUrl.startsWith("http")) {
      formattedUrl = `https://${formattedUrl}`;
    }

    if (!formattedUrl.includes("www.")) {
      formattedUrl = formattedUrl.replace("https://", "https://www.");
    }

    const urlObj = new URL(formattedUrl);

    if (returnHostname) {
      return urlObj.hostname;
    }

    return formattedUrl;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getLanguageFormatted = (language) => {
  const languageFormatted = language.split("-")[0];

  switch (languageFormatted) {
    case "en":
    case "es":
      return languageFormatted;

    default:
      return "en";
  }
};

export const compressImage = async (file) => {
  try {
    if (file.size < 2000000) return file;
    const dimensions = await getHeightAndWidthFromDataUrl(file);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight:
        dimensions.width >= 800
          ? Math.round(dimensions.width * 0.25)
          : dimensions.width,
      useWebWorker: true,
      initialQuality: 0.8,
    };
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
    SimpleAlert({
      title: "Error",
      text: "Error comprimiendo el archivo",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const isImage = (file) => {
  const ext = file.type.split("/")[1];
  if (IMAGE_FORMATS.includes(ext)) return true;
  return false;
};

export const validateFile = (file) => {
  const ext = file.type.split("/")[1];
  if (
    ext !== "pdf" &&
    !IMAGE_FORMATS.includes(ext) &&
    !VIDEO_FORMATS.includes(ext)
  ) {
    SimpleAlert({
      title: "Error",
      text: "El formato del archivo no es valido",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
  if (IMAGE_FORMATS.includes(ext) && file.size > MAX_SIZE_IMAGE) {
    SimpleAlert({
      title: "Error",
      text: "La imagen es muy grande, por favor selecciona una con un tamaño menor a 10MB",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
  if (
    (VIDEO_FORMATS.includes(ext) || ext === "pdf") &&
    file.size > MAX_SIZE_FILES
  ) {
    SimpleAlert({
      title: "Error",
      text: "El archivo es muy grande, por favor selecciona uno con un tamaño menor a 30MB",
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
  return true;
};

export const verifyDestinationURLIsABlockedDomain = ({
  destinationURL = "",
  destinationType,
}) => {
  if (
    destinationType ===
    ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION
  )
    return { ok: true };
  if (destinationURL.includes("facebook.com")) {
    return {
      ok: false,
      message: FIELD_TRAFFIC_URL_NOT_BE_LINK_FACEBOOK,
    };
  }

  if (destinationURL.includes("instagram.com")) {
    return {
      ok: false,
      message: FIELD_TRAFFIC_URL_NOT_BE_LINK_INSTAGRAM,
    };
  }

  if (destinationURL.includes("whatsapp.com")) {
    return {
      ok: false,
      message: FIELD_TRAFFIC_URL_NOT_BE_LINK_WHATSAPP,
    };
  }

  return {
    ok: true,
  };
};

export const removeAllWhiteSpaces = (str = "") => {
  return str.replace(/\s/g, "");
};

export const getDimensionsFromFileHtml = async ({ file, urlBlob }) => {
  if (!urlBlob) {
    urlBlob = URL.createObjectURL(file);
  }

  const getImageDimensions = (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  const getVideoDimensions = (videoUrl) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.src = videoUrl;
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
    });
  };

  if (file.type.startsWith("image/")) {
    return await getImageDimensions(urlBlob);
  } else if (file.type.startsWith("video/")) {
    return await getVideoDimensions(urlBlob);
  }

  return { width: 0, height: 0 };
};
