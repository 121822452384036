import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  FIELD_MAX_LENGTH,
  FIELD_MIN_LENGTH,
  FIELD_REQUIRED,
  NAME_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
  FIELD_URL_VALID,
  DESTINATION_URL_PLACEHOLDER,
  WEBSITE_URL_PLACEHOLDER,
  DATE_SHORT_LOCALIZED,
  LANGUAGE_CAMPAIGN_PLACEHOLDER,
  ADS_CAMPAIGN_TITLE,
  FIELD_URL_INSTAGRAM,
  BRAND_INSTAGRAM_PROFILE_URL_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import {
  optionsAdsCampaignObjectives,
  optionsLanguage,
} from "../../../utils/options";
import Typography from "@mui/material/Typography";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
  META,
  TIKTOK,
} from "../../../utils/constants";
import { useFormContext } from "react-hook-form";
import { useMediaQuery } from "@mui/material";
import TextField from "../../../components/Form/TextField";
import Select from "../../../components/Form/Select";
import { REGEX_URL } from "../../../utils/regex";
import { useTranslationApp } from "../../../lib/i18next";
import AdsCampaignButtonsActions from "./AdsCampaignButtonsActions";
import AdsCampaignPlatformForm from "./AdsCampaignPlatformForm";
import { verifyDestinationURLIsABlockedDomain } from "../../../utils/string";

const AdsCampaignForm = ({ campaignID, watch, control, errors }) => {
  const {
    isGeneralCampaignCreated,
    isGeneralCampaignActivated,
    isGeneralDisabledFields,
    getValues,
    setValue,
  } = useFormContext();
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const platforms = watch("platforms");
  const isMultiPlatform = platforms.length > 1;
  const platformsData = watch("platformsData") || {};
  const creationTime = getValues("creationTime");

  const date = creationTime ? new Date(creationTime) : new Date();

  const variantSubTitle = isMobile ? "h6" : "h6";
  const sxVariantSubTitle = {
    fontWeight: 900,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "flex-start",
            alignItems: { xs: "flex-start", sm: "center" },
            gap: { xs: 0, sm: 2 },
          }}
        >
          <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
            {`${t(ADS_CAMPAIGN_TITLE)} - ${t(DATE_SHORT_LOCALIZED, {
              date,
            })}`}
          </Typography>
          <AdsCampaignButtonsActions
            campaignID={campaignID}
            onChangeStatus={(value) => setValue("status", value)}
            data={getValues()}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="name"
          label={t(NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isGeneralCampaignActivated || isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            minLength: {
              value: 3,
              message: t(FIELD_MIN_LENGTH, {
                value: 3,
              }),
            },
            maxLength: {
              value: 60,
              message: t(FIELD_MAX_LENGTH, {
                value: 60,
              }),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Select
          label={t(OBJECTIVE_PLACEHOLDER)}
          options={optionsAdsCampaignObjectives.map((option) => ({
            ...option,
            chip:
              platforms.includes(TIKTOK) &&
              !isMultiPlatform &&
              option.value === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION
                ? null
                : option.chip,
          }))}
          disabled={isGeneralCampaignCreated || isGeneralDisabledFields}
          name="objective"
          actionsChange={() => {
            if (
              platforms.includes(META) &&
              platformsData[META]?.destinationType
            ) {
              setValue(`platformsData.${META}.destinationType`, "");
            }
          }}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="websiteURL"
          label={t(WEBSITE_URL_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isGeneralCampaignCreated || isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value && !REGEX_URL.test(encodeURI(value))) {
                return t(FIELD_URL_VALID);
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="destinationURL"
          label={t(DESTINATION_URL_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isGeneralCampaignCreated || isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
            },
            validate: (value) => {
              const objective = getValues("objective");
              const platformDataMeta = getValues(`platformsData.${META}`);
              const destinationType = platformDataMeta?.destinationType;

              if (
                objective === ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION &&
                platformDataMeta?.destinationType ===
                  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
              ) {
                return;
              }

              if (!value) {
                return t(FIELD_REQUIRED);
              }
              if (value && !REGEX_URL.test(encodeURI(value))) {
                return t(FIELD_URL_VALID);
              }
              const isUrlValidDomain = verifyDestinationURLIsABlockedDomain({
                destinationURL: value,
                destinationType,
              });
              if (!isUrlValidDomain.ok) {
                return t(isUrlValidDomain.message);
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          name="socialNetworkURL"
          label={t(BRAND_INSTAGRAM_PROFILE_URL_PLACEHOLDER)}
          variant="filled"
          fullWidth
          disabled={isGeneralCampaignCreated || isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value && !REGEX_URL.test(encodeURI(value))) {
                return t(FIELD_URL_VALID);
              }
              if (value && !value.includes("instagram.com")) {
                return t(FIELD_URL_INSTAGRAM);
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Select
          label={t(LANGUAGE_CAMPAIGN_PLACEHOLDER)}
          name="language"
          disabled={isGeneralCampaignActivated || isGeneralDisabledFields}
          fullWidth
          variant="filled"
          doTranslate={true}
          control={control}
          errors={errors}
          options={optionsLanguage}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>

      {platforms.map((platform) => (
        <Grid item xs={12} key={platform}>
          <AdsCampaignPlatformForm platform={platform} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AdsCampaignForm;
