import { useEffect, useState } from "react";
import LoadingButton from "./LoadingButton";
import {
  BUTTON_CONNECT_ACCOUNT,
  BUTTON_CONNECT_GOOGLE,
  ENDING_CONNECTION,
  RETRIEVING_AD_ACCOUNTS,
  RETRIEVING_GENERAL_INFORMATION,
} from "../../i18n/keysTranslations";
import {
  ALERT_ICON_TYPE_INFO,
  BUTTON_GOOGLE_ACTION_ID,
  GOOGLE,
  IS_STAGING,
} from "../../utils/constants";
import Avatar from "@mui/material/Avatar";
import { logoPlatform } from "../../utils/string";
import { useTranslationApp } from "../../lib/i18next";
import { getNameBusinessTheme } from "../../actions/getters";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { createPortal } from "react-dom";
import LinearLoaderWithTime from "../Loaders/LinearLoaderWithTime";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import IconButton from "./IconButton";
import { ConfirmAlert } from "../Alerts/Alerts";
// import AdsPlatformsLogos from "../Containers/AdsPlatformsLogos";
import Loader from "../Loaders/Loader";
import AddIcon from "@mui/icons-material/Add";
import {
  startConnectGoogleAds,
  startGetTokenGoogleAds,
} from "../../actions/user";
import AdsPlatformsLogos from "../Containers/AdsPlatformsLogos";

const ButtonGoogleAction = ({
  id = BUTTON_GOOGLE_ACTION_ID,
  text = BUTTON_CONNECT_GOOGLE,
  disabled = false,
  prevBlockClick = false,
  onPrevBlockClick = () => {},
  onClick = () => {},
  sx = {},
  logo = GOOGLE,
  showLogo = true,
  type = "button",
  mode = "verify",
  icon = <AddIcon />,
  disableRipple = false,
  showButtonIcon = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [externalPopup, setExternalPopup] = useState(null);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const integrations = useSelectorApp((state) => state?.user?.integrations);
  const googleIntegrations = integrations?.google || {};
  const isGoogleConnected = Object.values(googleIntegrations).some(
    (integration) => integration.isActive
  );
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  const onGetTokenGoogleAds = async ({ authCode, state }) => {
    setOpenBackDrop(true);
    const response = await dispatch(
      startGetTokenGoogleAds({ authCode, state, withAuth: true })
    );
    setOpenBackDrop(false);

    if (response.ok) {
      await onClick();
    }
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const authCode = searchParams.get("code");
      const state = searchParams.get("state");

      if (authCode && state) {
        externalPopup.close();
        setExternalPopup(null);
        clearInterval(timer);

        onGetTokenGoogleAds({
          authCode,
          state,
        });
      }
    }, 500);

    return () => {
      timer && clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [externalPopup]);

  if (type === "icon") {
    return (
      <>
        {openBackDrop &&
          createPortal(<BackDropLoader open={openBackDrop} />, document.body)}
        <IconButton
          id={id}
          disableRipple={disableRipple}
          sx={{ ...sx }}
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (prevBlockClick) {
              onPrevBlockClick();
              return;
            }

            if ((!isGoogleConnected && mode === "verify") || mode === "add") {
              const result = await ConfirmAlert({
                icon: ALERT_ICON_TYPE_INFO,
                confirmButtonText: t(BUTTON_CONNECT_ACCOUNT),
                html: (
                  <AdsPlatformsLogos
                    platform={GOOGLE}
                    NAME_BUSINESS={NAME_BUSINESS}
                  />
                ),
                width: !IS_STAGING ? "850px" : "550px",
              });
              if (!result.isConfirmed) return;

              dispatch(
                startConnectGoogleAds({
                  onChangeLoading: (loading) => setIsLoading(loading),
                  onChangePopupReference: (popup) => setExternalPopup(popup),
                })
              );
              return;
            }

            setIsLoading(true);
            onClick();
            setIsLoading(false);
          }}
        >
          {isLoading ? <Loader hasMarginTop={false} size={20} /> : icon}
        </IconButton>
      </>
    );
  }
  return (
    <>
      {openBackDrop &&
        createPortal(<BackDropLoader open={openBackDrop} />, document.body)}
      <LoadingButton
        id={id}
        disabled={disabled}
        loading={isLoading}
        color="primary"
        sx={{
          textTransform: "none",
          width: "100%",
          ...sx,
        }}
        onClick={async (e) => {
          e.preventDefault();
          if (prevBlockClick) {
            onPrevBlockClick();
            return;
          }

          if ((!isGoogleConnected && mode === "verify") || mode === "add") {
            const result = await ConfirmAlert({
              html: (
                <AdsPlatformsLogos
                  platform={GOOGLE}
                  NAME_BUSINESS={NAME_BUSINESS}
                />
              ),
              icon: ALERT_ICON_TYPE_INFO,
              confirmButtonText: t(BUTTON_CONNECT_ACCOUNT),
              width: !IS_STAGING ? "850px" : "550px",
            });
            if (!result.isConfirmed) return;

            dispatch(
              startConnectGoogleAds({
                onChangeLoading: (loading) => setIsLoading(loading),
                onChangePopupReference: (popup) => setExternalPopup(popup),
              })
            );
            return;
          }

          setIsLoading(true);
          await onClick();
          setIsLoading(false);
        }}
      >
        {showButtonIcon && icon}
        {showLogo && (
          <Avatar
            src={logoPlatform(logo)}
            variant="square"
            sx={{
              width: "20px",
              height: "20px",
              mr: 1,
              ".MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
        )}
        {t(text)}
      </LoadingButton>
    </>
  );
};

const BackDropLoader = ({ open }) => {
  const { t } = useTranslationApp();

  return (
    <Backdrop
      sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 100 })}
      open={open}
    >
      <Box
        sx={{
          maxWidth: 720,
          width: "100%",
        }}
      >
        <LinearLoaderWithTime
          time={30 * 1000}
          labels={[
            t(RETRIEVING_GENERAL_INFORMATION),
            t(RETRIEVING_AD_ACCOUNTS),
            t(ENDING_CONNECTION),
          ]}
        />
      </Box>
    </Backdrop>
  );
};

export default ButtonGoogleAction;
