import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { BORDER_RADIUS } from "../../../utils/constants";
import { logoPlatform } from "../../../utils/string";
import React from "react";
import { BUTTON_RECONNECT_ACCOUNT } from "../../../i18n/keysTranslations";
import useButtonActionPlatform from "../../../hooks/useButtonActionPlatform";

const VerifyIntegrationScopesItem = ({ data }) => {
  const { platform } = data;
  const theme = useTheme();
  const BUTTON_ACTIONS_COMPONENTS = useButtonActionPlatform();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: "space-between",
        alignItems: { xs: "flex-start", sm: "center" },
        gap: 1,
        border: `${theme.palette.border.grey} 1px solid`,
        p: 2,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 1,
        }}
      >
        <Avatar
          sx={{
            ".MuiAvatar-img": {
              objectFit: "contain",
            },
            width: 25,
            height: 25,
          }}
          src={logoPlatform(platform)}
          variant="square"
        />
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
          }}
        >
          {data.name}
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "auto",
          },
        }}
      >
        {React.cloneElement(BUTTON_ACTIONS_COMPONENTS[platform], {
          mode: "add",
          text: BUTTON_RECONNECT_ACCOUNT,
        })}
      </Box>
    </Box>
  );
};

export default VerifyIntegrationScopesItem;
