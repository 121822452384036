import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Controller, useFormContext } from "react-hook-form";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  AD_GROUP,
  ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE,
  AGE_RANGE_PLACEHOLDER,
  BEHAVIORS_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  START_DATE_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  DEMOGRAPHICS_PLACEHOLDER,
  FEMALES,
  FIELD_REQUIRED,
  GENDERS_PLACEHOLDER,
  INTERESTS_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  MALES,
  START_DATE_AND_END_DATE_VALIDATION,
  TYPE_BUDGET_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
  ADS_CAMPAIGN_AD_GROUP_INFORMATION_SUB_TITLE,
  ACTIVE,
  DEACTIVATED,
  NUMBER_ITEM,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  RETARGETING_EMPTY_SEGMENTATION_CLARIFICATION,
  FIELD_START_DATE_LESS_THAN_NOW,
  FIELD_END_DATE_LESS_THAN_NOW,
  LOCATIONS_TIK_TOK_EMPTY,
  KEYWORDS_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import {
  marksAge,
  optionsAdsCampaignTypeBudgets,
} from "../../../utils/options";
import { GREY_SLIDER_COLOR } from "../../../utils/colors";
import {
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  META,
  TIKTOK,
  GOOGLE,
} from "../../../utils/constants";
import addDays from "date-fns/addDays";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslationApp } from "../../../lib/i18next";
import Accordion from "../../../components/Containers/Accordion";
import Switch from "../../../components/Form/Switch";
import Select from "../../../components/Form/Select";
import TextField from "../../../components/Form/TextField";
import NumberField from "../../../components/Form/NumberField";
import DateField from "../../../components/Form/DateField";
import {
  numberRoundedToGreater,
  textNumberCompactFormat,
} from "../../../utils/numbers";
import SearchSegmentationsField from "../../../components/Form/SearchSegmentationsField";
import AdsAdForm from "./AdsAdForm";
import CheckBoxes from "../../../components/Form/CheckBoxes";
import AdsGroupFormCustomAudiencesList from "./AdsGroupFormCustomAudiencesList";
import { differenceInDays } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import KeywordsGoogleContainer from "../../../components/Google/KeywordsGoogleContainer";
import { getDuplicateItemsArray } from "../../../utils/array";

const AdsGroupForm = ({ data, currency, adGroupNumber = 0, platform }) => {
  const [isSaving, setIsSaving] = useState(false);
  const {
    watch,
    control,
    getValues,
    setValue,
    onAddAdGroup,
    onRemoveAdGroup,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    getIsCampaignPlatformActive,
    getShowActionsActionsPlatform,
    onChangeStatusCampaign,
    formState: { errors },
  } = useFormContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();
  const theme = useTheme();

  const isCampaignPlatformActivated = getIsCampaignPlatformActive(platform);
  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
  const showActionsPlatform = getShowActionsActionsPlatform(platform);

  const adsGroups = watch("adsGroups");
  const adsGroupsPlatform = adsGroups.filter(
    (adGroup) => adGroup.platform === platform
  );
  const hasOnlyOneAdGroup = adsGroupsPlatform.length === 1;
  const index = adsGroups.findIndex((adGroup) => adGroup.id === data.id);
  const BASIC_PATH = `adsGroups.[${index}]`;

  const genders = watch(`${BASIC_PATH}.genders`);
  const typeBudget = watch(`${BASIC_PATH}.typeBudget`);
  const useCampaignBudget = watch("useCampaignBudget");
  const startDate = watch(`${BASIC_PATH}.startDate`);
  const endDate = watch(`${BASIC_PATH}.endDate`);
  const objective = watch("objective");
  const language = watch("language");
  const ads = watch(`${BASIC_PATH}.ads`);
  const platformData = watch(`platformsData.${platform}`) || {};
  const customAudiences = watch(`${BASIC_PATH}.customAudiences`) || [];
  const locations = watch(`${BASIC_PATH}.locations`) || [];
  const adAccountID = platformData?.adAccountID;

  const hasCustomAudiences = customAudiences?.length > 0;
  const withoutLocationsAndTikTokAdsGroup =
    platform === TIKTOK && (locations.length === 0 || !locations);

  const variantSubTitle = isMobile ? "h6" : "h6";
  const sxVariantSubTitle = {
    fontWeight: 900,
  };

  const onChangeSwitch = async (value) => {
    setIsSaving(true);
    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: "adGroup",
      platform,
      adGroupID: index,
    });
    setIsSaving(false);
  };

  useEffect(() => {
    setValue(`${BASIC_PATH}.customAudiences`, []);
    // eslint-disable-next-line
  }, [adAccountID]);

  return (
    <>
      <Accordion
        id={data.id}
        title={`${t(AD_GROUP)} ${t(NUMBER_ITEM, { value: adGroupNumber + 1 })}`}
        variantTitle={"body1"}
        initialValue={true}
        sx={{
          border: `${theme.palette.border.grey} 1px solid`,
          borderRadius: `${4 * 4}px !important`,
          ".MuiSvgIcon-root": {
            color: "white !important",
          },
        }}
        actionsComponent={
          isGeneralDisabledFields ? null : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexGrow: 1,
                mr: { xs: 0, sm: 2 },
                pl: { xs: 2, sm: 0 },
              }}
            >
              {showActionsPlatform && (
                <Controller
                  name={`${BASIC_PATH}.status`}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      loading={isSaving}
                      isActive={field.value === ADS_CAMPAIGN_STATUS_ACTIVE}
                      labelActive={ACTIVE}
                      labelDeactivate={DEACTIVATED}
                      onChangeIsActive={(value) => onChangeSwitch(value)}
                    />
                  )}
                />
              )}
              {platform !== GOOGLE && (
                <IconButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    onAddAdGroup({
                      adGroupID: data.id,
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              )}
              {!hasOnlyOneAdGroup && (
                <IconButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    onRemoveAdGroup({ adGroupID: data.id });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          )
        }
      >
        <Grid container spacing={2}>
          {(platform === META || platform === TIKTOK) && (
            <>
              <Grid item xs={12}>
                <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
                  {t(
                    useCampaignBudget
                      ? ADS_CAMPAIGN_AD_GROUP_INFORMATION_SUB_TITLE
                      : ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE
                  )}
                </Typography>
              </Grid>
              {!useCampaignBudget && (
                <>
                  <Grid item xs={12} lg={6}>
                    <Select
                      label={t(TYPE_BUDGET_PLACEHOLDER)}
                      disabled={
                        isCampaignPlatformCreated || isGeneralDisabledFields
                      }
                      name={`${BASIC_PATH}.typeBudget`}
                      actionsChange={(value) => {
                        if (
                          value === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
                        ) {
                          setValue(
                            `${BASIC_PATH}.startDate`,
                            startOfDay(new Date())
                          );
                          setValue(
                            `${BASIC_PATH}.endDate`,
                            endOfDay(addDays(new Date(), 1))
                          );
                        }
                      }}
                      fullWidth
                      variant="filled"
                      doTranslate={true}
                      options={optionsAdsCampaignTypeBudgets}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label={t(
                        typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
                          ? DAILY_BUDGET_PLACEHOLDER
                          : LIFETIME_BUDGET_PLACEHOLDER
                      )}
                      name={`${BASIC_PATH}.budget`}
                      disabled={
                        isCampaignPlatformActivated || isGeneralDisabledFields
                      }
                      fullWidth
                      variant="filled"
                      InputProps={{
                        inputComponent: NumberField,
                      }}
                      helperText={
                        currency
                          ? t(VALUE_EXPRESSED_IN, {
                              currency: currency.toUpperCase(),
                            })
                          : ""
                      }
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (
                            typeBudget ===
                            ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION
                          ) {
                            const startDateTime = startDate
                              ? new Date(startDate)
                              : new Date();
                            const endDateTime = endDate
                              ? new Date(endDate)
                              : new Date();

                            const diffDays = differenceInDays(
                              endDateTime,
                              startDateTime
                            );

                            const dailyBudget = Number(value) / diffDays;

                            if (dailyBudget < 1.5) {
                              return t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                                value: numberRoundedToGreater(diffDays * 1.5),
                              });
                            }
                          }
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          {((typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION &&
            !useCampaignBudget) ||
            useCampaignBudget) && (
            <>
              <Grid item xs={12} lg={6}>
                <DateField
                  disabled={
                    (isCampaignPlatformCreated || isGeneralDisabledFields) &&
                    startDate
                  }
                  minDate={startOfDay(new Date())}
                  name={`${BASIC_PATH}.startDate`}
                  label={t(START_DATE_PLACEHOLDER)}
                  actionsChange={(value) => {
                    setValue(
                      `${BASIC_PATH}.startDate`,
                      value ? startOfDay(value) : null
                    );
                  }}
                  control={control}
                  errors={errors}
                  rules={{
                    validate: (value) => {
                      if (!value) {
                        return t(FIELD_REQUIRED);
                      }
                      const startDate = new Date(value);
                      const startOfDayValue = startOfDay(new Date());

                      if (
                        startDate.getTime() < startOfDayValue.getTime() &&
                        !isCampaignPlatformCreated
                      ) {
                        return t(FIELD_START_DATE_LESS_THAN_NOW);
                      }

                      const endDateValue = getValues(`${BASIC_PATH}.endDate`);
                      if (endDateValue) {
                        const endDate = new Date(endDateValue);
                        if (startDate.getTime() > endDate.getTime()) {
                          return t(START_DATE_AND_END_DATE_VALIDATION);
                        }
                      }
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateField
                  disabled={
                    isCampaignPlatformActivated || isGeneralDisabledFields
                  }
                  name={`${BASIC_PATH}.endDate`}
                  minDate={addDays(new Date(), 1)}
                  actionsChange={(value) => {
                    setValue(
                      `${BASIC_PATH}.endDate`,
                      value ? endOfDay(value) : null
                    );
                  }}
                  label={t(END_DATE_PLACEHOLDER)}
                  control={control}
                  errors={errors}
                  rules={{
                    validate: (value) => {
                      if (!value) {
                        return t(FIELD_REQUIRED);
                      }
                      const endDate = new Date(value);
                      const now = new Date();
                      if (endDate.getTime() < now.getTime()) {
                        return t(FIELD_END_DATE_LESS_THAN_NOW);
                      }
                      const startDateValue = getValues(
                        `${BASIC_PATH}.startDate`
                      );
                      if (startDateValue) {
                        const endDate = new Date(value);
                        const startDate = new Date(startDateValue);
                        if (startDate.getTime() > endDate.getTime()) {
                          return t(START_DATE_AND_END_DATE_VALIDATION);
                        }
                      }
                    },
                  }}
                />
              </Grid>
            </>
          )}
          {platform !== GOOGLE && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
                  {t(ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE)}
                </Typography>
              </Box>
            </Grid>
          )}
          {platform === META && (
            <Grid item xs={12}>
              <AdsGroupFormCustomAudiencesList BASIC_PATH={BASIC_PATH} />
            </Grid>
          )}
          {(platform === TIKTOK || platform === META) && (
            <Grid item xs={12} lg={6}>
              <Typography variant="body1">
                {t(AGE_RANGE_PLACEHOLDER)}
              </Typography>
              <Controller
                name={`${BASIC_PATH}.ageRange`}
                control={control}
                render={({ field }) => (
                  <Slider
                    sx={{
                      width: "calc(100% - 24px)",
                      ml: 1,
                      ".MuiSlider-track": {
                        height: 2,
                      },
                      ".MuiSlider-rail": {
                        backgroundColor: GREY_SLIDER_COLOR,
                        height: 4,
                      },
                      ".MuiSlider-mark": {
                        backgroundColor: "transparent",
                      },
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={textNumberCompactFormat}
                    step={1}
                    marks={marksAge}
                    min={18}
                    max={64}
                    valueLabelFormat={textNumberCompactFormat}
                    {...field}
                    disabled={
                      isCampaignPlatformActivated || isGeneralDisabledFields
                    }
                  />
                )}
              />
            </Grid>
          )}
          {(platform === TIKTOK || platform === META) && (
            <Grid item xs={12} lg={6}>
              <CheckBoxes
                name={`${BASIC_PATH}.genders`}
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                label={GENDERS_PLACEHOLDER}
                options={[
                  {
                    key: "male",
                    label: MALES,
                  },
                  {
                    key: "female",
                    label: FEMALES,
                  },
                ]}
                control={control}
                errors={errors}
                rules={{
                  validate: () => {
                    if (!Object.values(genders).includes(true)) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
          )}
          {platform !== GOOGLE && (
            <Grid item xs={12} lg={6}>
              <SearchSegmentationsField
                label={t(LOCATIONS_PLACEHOLDER)}
                name={`${BASIC_PATH}.locations`}
                type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE}
                mode="fetching"
                platform={platform}
                adAccountID={platformData?.adAccountID}
                integrationID={platformData?.integrationID}
                objective={objective}
                language={language}
                fullWidth
                variant="filled"
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                helperText={
                  withoutLocationsAndTikTokAdsGroup
                    ? t(LOCATIONS_TIK_TOK_EMPTY)
                    : ""
                }
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value:
                      platform === TIKTOK ||
                      (platform === META && !hasCustomAudiences),
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}
          {(platform === TIKTOK || platform === META) && (
            <Grid item xs={12} lg={6}>
              <SearchSegmentationsField
                label={t(INTERESTS_PLACEHOLDER)}
                name={`${BASIC_PATH}.interests`}
                type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE}
                mode="fetching"
                platform={platform}
                adAccountID={platformData?.adAccountID}
                integrationID={platformData?.integrationID}
                objective={objective}
                language={language}
                fullWidth
                variant="filled"
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                helperText={
                  hasCustomAudiences
                    ? t(RETARGETING_EMPTY_SEGMENTATION_CLARIFICATION)
                    : ""
                }
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}

          {(platform === TIKTOK || platform === META) && (
            <Grid item xs={12} lg={6}>
              <SearchSegmentationsField
                label={t(BEHAVIORS_PLACEHOLDER)}
                name={`${BASIC_PATH}.behaviors`}
                type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE}
                mode={platform === TIKTOK ? "fetching" : "load"}
                groupBy={["path", 0]}
                platform={platform}
                adAccountID={platformData?.adAccountID}
                integrationID={platformData?.integrationID}
                objective={objective}
                language={language}
                fullWidth
                variant="filled"
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                helperText={
                  hasCustomAudiences
                    ? t(RETARGETING_EMPTY_SEGMENTATION_CLARIFICATION)
                    : ""
                }
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}

          {platform === META && (
            <Grid item xs={12} lg={6}>
              <SearchSegmentationsField
                label={t(DEMOGRAPHICS_PLACEHOLDER)}
                name={`${BASIC_PATH}.demographics`}
                type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_DEMOGRAPHICS_TYPE}
                mode={platform === TIKTOK ? "fetching" : "load"}
                groupBy={["path", 0]}
                platform={platform}
                adAccountID={platformData?.adAccountID}
                integrationID={platformData?.integrationID}
                objective={objective}
                language={language}
                fullWidth
                variant="filled"
                disabled={
                  isCampaignPlatformActivated || isGeneralDisabledFields
                }
                helperText={
                  hasCustomAudiences
                    ? t(RETARGETING_EMPTY_SEGMENTATION_CLARIFICATION)
                    : ""
                }
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Grid>
          )}

          {platform === GOOGLE && (
            <Grid item xs={12} lg={6}>
              <KeywordsGoogleContainer
                label={t(KEYWORDS_PLACEHOLDER)}
                name={`${BASIC_PATH}.keywords`}
                variant="filled"
                disabled={isCampaignPlatformActivated}
                fullWidth
                adAccountID={platformData?.adAccountID}
                integrationID={platformData?.integrationID}
                language={language}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: false,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (!value || value.length === 0) {
                      return t(FIELD_REQUIRED);
                    }
                    const duplicate = getDuplicateItemsArray(value);
                    if (duplicate.length > 0) {
                      return t(FIELD_REQUIRED);
                    }
                  },
                }}
              />
            </Grid>
          )}
          {ads.map((ad) => (
            <Grid item xs={12} key={ad.id} sx={{ mt: 4 }}>
              <AdsAdForm data={ad} platform={platform} />
            </Grid>
          ))}
        </Grid>
      </Accordion>
    </>
  );
};

export default AdsGroupForm;
