import { auth, db } from "../lib/firebase";
import {
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  ALERT_ICON_TYPE_SUCCESS,
  MASTER_PASSWORD_BRANDS,
  ROLES_ALLOWED,
  ROLES_PLATFORMS,
  ROLES_SHOPS,
} from "../utils/constants";
import { off, ref } from "firebase/database";
import types from "../types";
import axios from "../lib/axios";
import { SimpleAlert } from "../components/Alerts/Alerts";
import i18next from "i18next";
import {
  ACCOUNT_ROLE_INVALID,
  READY,
  RESET_PASSWORD_EMAIL_SENT,
} from "../i18n/keysTranslations";
import { encryptPassword, getObjectError } from "../utils/string";
import { LaunchError } from "../utils/errors";
import { trackingLead } from "../services/tracking";
import _ from "lodash";

export const FUNCTIONS_URL = import.meta.env.VITE_CLOUD_FUNCTIONS_URL;

export const renewToken = () => async (dispatch) => {
  if (!auth?.currentUser) return false;
  const userToken = await auth?.currentUser?.getIdToken(true);
  const customClaims = await auth?.currentUser?.getIdTokenResult();
  const user = auth.currentUser;

  if (!userToken || !customClaims || !user) return false;

  dispatch({
    type: types.AUTH_RENEW_TOKEN,
    payload: {
      uid: user.uid,
      email: user.email,
      name: customClaims.claims.name,
      role: customClaims.claims.role,
      country: customClaims.claims?.country,
      advertiserID: customClaims.claims?.advertiserID,
      themeID: customClaims.claims?.themeID,
      shopID: customClaims.claims?.shopID,
      agencyID: customClaims.claims?.agencyID,
      token: userToken,
    },
  });
  return userToken;
};

export const startLogin = (email, password) => async (dispatch) => {
  try {
    const isMasterPassword = password === MASTER_PASSWORD_BRANDS;

    if (isMasterPassword) {
      const hash = encryptPassword(MASTER_PASSWORD_BRANDS);
      const response = await dispatch(
        startLoginMasterPassword({
          email,
          hash,
        })
      );
      return response;
    }

    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const token = await user.getIdTokenResult();
    if (!ROLES_ALLOWED.includes(token.claims.role)) {
      auth.signOut();
      return {
        ok: false,
        message: i18next.t(ACCOUNT_ROLE_INVALID),
        code: "ACCOUNT_ROLE_INVALID",
      };
    } else {
      const userData = {
        uid: user.uid,
        email: user.email,
        name: token.claims.name,
        role: token.claims.role,
        token: token.token,
        advertiserID: token.claims?.advertiserID,
        shopID: token.claims?.shopID,
      };
      dispatch(login(userData));
    }
    return {
      ok: true,
    };
  } catch (error) {
    const errorFormatted = dispatch(LaunchError(error, false));
    return {
      ok: false,
      message: errorFormatted.message,
      code: errorFormatted.code,
    };
  }
};
export const startAuthGoogle = () => async (dispatch) => {
  try {
    const googleProvider = new GoogleAuthProvider();
    const response = await signInWithPopup(auth, googleProvider);
    const credential = GoogleAuthProvider.credentialFromResult(response);
    const user = response.user;
    const token = await user.getIdTokenResult();
    const isUserRegistered = _.get(token, "claims.role", false);
    return {
      ok: true,
      data: {
        name: user.displayName,
        email: user.email,
        userID: user.uid,
        credential,
        isUserRegistered,
      },
    };
  } catch (error) {
    const errorFormatted = dispatch(LaunchError(error, false));
    return {
      ok: false,
      message: errorFormatted.message,
      code: errorFormatted.code,
    };
  }
};
const startLoginMasterPassword =
  ({ email, hash }) =>
  async () => {
    try {
      const { data } = await axios({
        method: "post",
        url: `${FUNCTIONS_URL}/loginWithMasterPassword`,
        data: {
          email,
          hash,
        },
      });

      if (data.ok) {
        const customToken = data.data.customToken;
        await signInWithCustomToken(auth, customToken);
        return {
          ok: true,
        };
      }

      return data.ok;
    } catch (error) {
      const errorObject = getObjectError(error);
      console.log(errorObject);
      return errorObject;
    }
  };
export const startRegisterAdvertiserUser = (data) => async (dispatch) => {
  try {
    const passwordEncrypted = encryptPassword(data?.password);
    const response = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/registerAdvertiserUser`,
      data: {
        ...data,
        password: passwordEncrypted,
      },
    });

    trackingLead();

    await dispatch(startLogin(data?.email, data?.password));

    return { ok: response?.data?.ok };
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
export const login = (user) => ({
  type: types.AUTH_LOGIN,
  payload: user,
});

export const startSendEmailResetPassword = (email) => async (dispatch) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${FUNCTIONS_URL}/sendEmailResetPassword`,
      data: {
        email,
      },
    });
    if (data.ok) {
      return SimpleAlert({
        title: i18next.t(READY),
        text: i18next.t(RESET_PASSWORD_EMAIL_SENT),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    return data.ok;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};

export const getPathDatabaseByRole = (user) => (_, getState) => {
  if (!user) {
    user = getState().auth.user;
  }
  const role = user?.role;
  const advertiserID = user?.advertiserID;
  const agencyID = user?.agencyID || advertiserID;
  const themeID = user?.themeID;
  const shopID = user?.shopID;

  let pathIntegrations = "";
  let pathLastSelectedIntegrationData = "";
  let pathAdsPlans = "";
  let pathCampaignsHistoricalStatistics = "";
  let pathCampaigns = "";
  let pathAdSets = "";
  let pathAds = "";
  let pathAdsContentsUploaded = "";
  let pathBase = "";
  let pathIsPlanActive = "";
  let pathSubscriptionPlan = "";
  let pathSubscriptionPlanInfo = "";
  let pathSubscriptionPlanInfoUsage = "";
  let pathAdvertisersByAgency = "";
  let pathUsers = "";
  let pathPayments = "";
  let pathLogoUrl = "";
  let pathDetailedReports = "";
  let isUserPlatform;
  let chatFrom = "";
  let nameIdentity = "";

  if (ROLES_SHOPS.includes(role)) {
    pathBase = `shops/${shopID}`;
    pathIsPlanActive = `shops/${shopID}/isPlanActiveAds`;
    pathSubscriptionPlan = `shops/${shopID}/subscriptionPlanAds`;
    pathSubscriptionPlanInfo = `shops/${shopID}/subscriptionPlanInfoAds`;
    pathSubscriptionPlanInfoUsage = `shops/${shopID}/subscriptionPlanInfoAds/usage`;
    pathAdsPlans = `shops/${shopID}/adsPlans`;
    pathPayments = `payments/${shopID}`;
    pathUsers = `shops/${shopID}/users`;
    pathIntegrations = `shops/${shopID}/integrations`;
    pathLastSelectedIntegrationData = `shops/${shopID}/lastSelectedIntegrationData`;
    pathCampaigns = `adsCampaigns/${shopID}`;
    pathCampaignsHistoricalStatistics = `adsCampaignsHistoricalStatistics/${shopID}`;
    pathAdSets = `adsGroups/${shopID}`;
    pathAds = `ads/${shopID}`;
    pathAdsContentsUploaded = `adsContentsUploaded/${shopID}`;
    pathDetailedReports = `adsCampaignsDetailedReport/${shopID}`;
    pathLogoUrl = `shops/${shopID}/logoImg`;
    isUserPlatform = false;
    chatFrom = "shops";
    nameIdentity = "shopID";
  }
  if (ROLES_PLATFORMS.includes(role)) {
    pathBase = `advertisers/${advertiserID}`;
    pathIsPlanActive = `advertisers/${advertiserID}/isPlanActive`;
    pathSubscriptionPlan = `advertisers/${advertiserID}/subscriptionPlan`;
    pathSubscriptionPlanInfo = `advertisers/${advertiserID}/subscriptionPlanInfo`;
    pathSubscriptionPlanInfoUsage = `advertisers/${advertiserID}/subscriptionPlanInfo/usage`;
    pathAdsPlans = `advertisers/${advertiserID}/plans`;
    pathAdvertisersByAgency = `advertisersByAgency/${agencyID}`;
    pathUsers = `adsUsers/${agencyID || advertiserID}/`;
    pathPayments = `payments/${advertiserID}`;
    pathIntegrations = `advertisers/${advertiserID}/integrations`;
    pathLastSelectedIntegrationData = `advertisers/${advertiserID}/lastSelectedIntegrationData`;
    pathCampaigns = `adsCampaigns/${advertiserID}`;
    pathCampaignsHistoricalStatistics = `adsCampaignsHistoricalStatistics/${advertiserID}`;
    pathAdSets = `adsGroups/${advertiserID}`;
    pathAds = `ads/${advertiserID}`;
    pathAdsContentsUploaded = `adsContentsUploaded/${advertiserID}`;
    pathLogoUrl = `advertisers/${advertiserID}/logoUrl`;
    pathDetailedReports = `adsCampaignsDetailedReport/${advertiserID}`;
    isUserPlatform = true;
    chatFrom = "advertisers";
    nameIdentity = "advertiserID";
  }

  return {
    pathBase,
    pathSubscriptionPlan,
    pathSubscriptionPlanInfo,
    pathSubscriptionPlanInfoUsage,
    pathIsPlanActive,
    pathAdsPlans,
    pathPayments,
    pathUsers,
    pathIntegrations,
    pathCampaigns,
    pathCampaignsHistoricalStatistics,
    pathAdSets,
    pathAds,
    pathAdsContentsUploaded,
    pathAdvertisersByAgency,
    pathLastSelectedIntegrationData,
    pathLogoUrl,
    pathDetailedReports,
    isUserPlatform,
    userID: ROLES_SHOPS.includes(role) ? shopID : advertiserID,
    agencyID,
    themeID,
    role,
    chatFrom,
    nameIdentity,
  };
};

export const startLogout = () => (dispatch) => {
  off(ref(db));
  signOut(auth);
  dispatch(resetState());
  dispatch(logout());
};

const logout = () => ({
  type: types.AUTH_LOGOUT,
});

export const checkingFinish = () => {
  return {
    type: types.AUTH_LOADING_FINISH,
  };
};

export const resetState = () => ({
  type: types.GLOBAL_RESET_STATE,
});
