import { useTranslationApp } from "../../lib/i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  FORMS,
  MODAL_INSTANT_FORMS_EMPTY_TEXT,
} from "../../i18n/keysTranslations";
import Loader from "../Loaders/Loader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const InstantFormsList = ({
  instantForms = [],
  loadLastItem,
  selectedItem = {},
  onChangeSelectedItem,
  lastItemRef,
}) => {
  const { t } = useTranslationApp();

  const instantFormsSorted = instantForms.sort(
    (a, b) => b.creationTime - a.creationTime
  );

  if (instantForms.length === 0) {
    return (
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">
          {t(MODAL_INSTANT_FORMS_EMPTY_TEXT)}
        </Typography>
      </Box>
    );
  }
  return (
    <FormControl
      sx={{
        display: "flex",
        gap: 1.5,
        flexDirection: "column",
      }}
    >
      <FormLabel>{t(FORMS)}</FormLabel>
      <RadioGroup
        value={selectedItem?.id}
        onChange={(e) => onChangeSelectedItem(e.target.value)}
      >
        {instantFormsSorted.map((instantForm, index) => {
          if (index === instantForms.length - 1) {
            return (
              <Box key={instantForm.id}>
                <InstantFormListItem
                  instantForm={instantForm}
                  selectedItem={selectedItem}
                  onChangeSelectedItem={onChangeSelectedItem}
                />
                {!loadLastItem && <Loader size={20} />}
                <div ref={lastItemRef} />
              </Box>
            );
          }
          return (
            <InstantFormListItem
              key={instantForm.id}
              instantForm={instantForm}
              selectedItem={selectedItem}
              onChangeSelectedItem={onChangeSelectedItem}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const InstantFormListItem = ({ instantForm }) => {
  return (
    <FormControlLabel
      value={instantForm.id}
      control={<Radio />}
      label={instantForm.name}
    />
  );
};
