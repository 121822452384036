import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { getReactHookFormNestedError } from "../../utils/errors";

const OTPField = ({
  control,
  rules = {},
  length = 6,
  errors = {},
  label = "",
  subLabel = "",
  variant = "standard",
  name = "",
  value = "",
  disabled = false,
  sx,
}) => {
  const error = getReactHookFormNestedError(errors, name);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field }) => (
        <>
          {label && (
            <Typography variant="body1" color={error ? "error" : "default"}>
              {label}
            </Typography>
          )}
          {subLabel && (
            <Typography variant="body2" color="textSecondary">
              {subLabel}
            </Typography>
          )}
          <MuiOtpInput
            {...field}
            length={length}
            TextFieldsProps={{
              disabled,
              placeholder: "-",
              variant,
              sx: {
                ...sx,
                ".MuiInputBase-input": {
                  py: 2,
                },
              },
            }}
          />
          {error && (
            <Typography variant="caption" color="error">
              {error?.message}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export default OTPField;
