import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslationApp } from "../../lib/i18next";
import SendIcon from "@mui/icons-material/Send";
import { BOX_SHADOW_ACTIONS_MODAL } from "../../utils/colors";
import { InputBase } from "@mui/material";
import LoadingButton from "../Buttons/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { FIELD_MAX_LENGTH } from "../../i18n/keysTranslations";
import { getIsBlockedActions } from "../../actions/getters";
import { ADS_CAMPAIGN_CHAT_BOT_ACTION } from "../../utils/constants";
import { useDispatchApp } from "../../lib/redux";

const CommonChatMessageInput = ({
  value = "",
  onSendMessage,
  disabled = false,
  placeholderInput,
  hasMessages,
  onExpandChat = () => {},
}) => {
  const [message, setMessage] = useState(value);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [file, setFile] = useState(null);
  const [showAlertUsage, setShowAlertUsage] = useState(true);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onChangeMessage = (message) => {
    setMessage(message);
  };

  const onClickSendMessage = async () => {
    if (message.length > 1000) {
      setError(t(FIELD_MAX_LENGTH, { value: 1000 }));
      return false;
    }
    if (!message && file === null) return false;

    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_CHAT_BOT_ACTION,
        showUsageConfirmationAlert: showAlertUsage,
      })
    );
    if (isBlocked) {
      return;
    }

    if (showAlertUsage) {
      setShowAlertUsage(false);
    }

    let currentMessage = message;
    let currentFile = file;

    onChangeMessage("");
    setFile(null);

    const messageFormatted = currentMessage.replace("&nbsp;", " ").trim();

    const response = await onSendMessage(messageFormatted, currentFile);
    if (!response) {
      onChangeMessage(currentMessage);
      setFile(currentFile);
    }
  };

  return (
    <Box
      sx={{
        boxShadow: BOX_SHADOW_ACTIONS_MODAL,
        mr: "-12px",
        px: 2,
        pt: 2,
        zIndex: 10,
      }}
      onKeyDown={(e) => {
        if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.shiftKey) {
          e.preventDefault();
          onClickSendMessage();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: "center",
          gap: 1,
          mb: 1,
        }}
      >
        {hasMessages && (
          <IconButton onClick={onExpandChat}>
            <ArrowUpwardIcon />
          </IconButton>
        )}
        <InputBase
          value={message}
          disabled={disabled}
          placeholder={t(placeholderInput)}
          multiline
          minRows={1}
          maxRows={1}
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            padding: "8px 16px",
          }}
          onChange={(e) => {
            const value = e.target.value;
            onChangeMessage(value);
          }}
        />
        <LoadingButton
          loading={isSending}
          color="secondary"
          disabled={(message === "" && file === null) || disabled}
          onClick={async (e) => {
            setIsSending(true);
            await onClickSendMessage(e);
            setIsSending(false);
          }}
          sx={{
            width: {
              xs: "100%",
              sm: "auto",
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "white !important",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <SendIcon />
        </LoadingButton>
      </Box>
      {error && (
        <Typography variant="caption" sx={{ color: "error.main", mt: 1 }}>
          {error}
        </Typography>
      )}
      {file && (
        <Box
          sx={{
            px: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: "2px",
            pb: "-2px",
            mb: 1,
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{ mb: "2px", color: "text.primary" }}
            >
              {file.name}
            </Typography>
          </Box>
          <IconButton sx={{ p: 0 }} onClick={() => setFile(null)}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CommonChatMessageInput;
