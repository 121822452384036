import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PricingCard from "./PricingCard";
import Box from "@mui/material/Box";
import {
  MONTHLY,
  QUARTERLY,
  SEMIYEARLY,
  YEARLY,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import Typography from "@mui/material/Typography";
import { BORDER_RADIUS } from "../../utils/constants";

const PricingCardsContainer = ({
  recurrent,
  onChangeRecurrent = () => {},
  plans = [],
  onChangePlanSelected = () => {},
}) => {
  const { t } = useTranslationApp();

  const grid = () => {
    if (plans.length === 3) {
      return {
        xs: 1,
      };
    }
  };

  const commonSxTab = (recurrentValue) => ({
    padding: { xs: "12px 8px", sm: "16px 36px", md: "12px 48px" },
    cursor: "pointer",
    background: recurrent === recurrentValue ? "white" : "transparent",
    color: recurrent === recurrentValue ? "black" : "white",
    textAlign: "center",
    width: {
      xs: "25%",
      sm: "unset",
    },
  });
  const commonSxTextTab = {
    fontSize: {
      xs: "0.7rem",
      sm: "1rem",
    },
  };

  return (
    <Container
      maxWidth="xlg"
      sx={{
        mt: 3,
        px: {
          md: 4,
          xs: 3,
        },
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mb: {
            xs: 2,
            sm: 5,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            border: "1px solid white",
            width: { xs: "100%", sm: "min-content" },
            borderRadius: BORDER_RADIUS,
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            onClick={() => onChangeRecurrent("monthly")}
            sx={{
              ...commonSxTab("monthly"),
              borderTopLeftRadius: BORDER_RADIUS * 3,
              borderBottomLeftRadius: BORDER_RADIUS * 3,
            }}
          >
            <Typography variant="body1" sx={commonSxTextTab}>
              {t(MONTHLY)}
            </Typography>
          </Box>
          <Box
            onClick={() => onChangeRecurrent("quarterly")}
            sx={{
              ...commonSxTab("quarterly"),
            }}
          >
            <Typography variant="body1" sx={commonSxTextTab}>
              {t(QUARTERLY)}
            </Typography>
          </Box>
          <Box
            onClick={() => onChangeRecurrent("semiyearly")}
            sx={{
              ...commonSxTab("semiyearly"),
            }}
          >
            <Typography variant="body1" sx={commonSxTextTab}>
              {t(SEMIYEARLY)}
            </Typography>
          </Box>
          <Box
            onClick={() => onChangeRecurrent("yearly")}
            sx={{
              ...commonSxTab("yearly"),
              borderTopRightRadius: BORDER_RADIUS * 3,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: BORDER_RADIUS * 3,
            }}
          >
            <Typography variant="body1" sx={commonSxTextTab}>
              {t(YEARLY)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
        {plans.map((plan) => (
          <Grid
            item
            key={plan.id}
            {...grid()}
            sx={{
              width: {
                xs: "100%",
                sm: "unset",
              },
            }}
          >
            <PricingCard
              data={plan}
              recurrent={recurrent}
              onChangePlanSelected={onChangePlanSelected}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingCardsContainer;
