import { useEffect, useRef, useState } from "react";
import SelectSubscription from "../../components/Modal/ModalPaySubscription/components/SelectSubscription";
import { useNavigate } from "react-router-dom";
import { useSelectorApp } from "../../lib/redux";
import ModalBasicLayout from "../../components/Modal/ModalBasicLayout";

const Paywall = () => {
  const [isVerifyingPlan, setIsVerifyingPlan] = useState(true);
  const refContainer = useRef();
  const navigate = useNavigate();

  const isLoading = useSelectorApp((state) => state.payments.isLoading);
  const subscriptionPlan = useSelectorApp(
    (state) => state.payments.subscriptionPlan
  );

  useEffect(() => {
    if (isLoading) return;

    if (subscriptionPlan) {
      navigate("/");
      return;
    }

    setIsVerifyingPlan(false);

    // eslint-disable-next-line
  }, [isLoading, subscriptionPlan]);

  if (isVerifyingPlan) {
    return null;
  }

  return (
    <ModalBasicLayout
      modalOpen={true}
      fullScreen
      showCloseButton={false}
      sxContent={{
        py: 0,
        px: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: { xs: "baseline", lg: "center" },
        width: "100%",
      }}
      sxTitle={{
        px: 0,
        py: 0,
      }}
    >
      <SelectSubscription
        sx={{
          width: "100%",
        }}
        onCallbackFinish={() => {
          navigate("/");
        }}
        refContainer={refContainer}
      />
    </ModalBasicLayout>
  );
};

export default Paywall;
