import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Controller, useController } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getReactHookFormNestedError } from "../../utils/errors";
import {
  END_DATE_PLACEHOLDER,
  START_DATE_PLACEHOLDER,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { useEffect } from "react";
import addDays from "date-fns/addDays";
import { addMonths, differenceInDays } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { ADS_CAMPAIGNS_DEMO } from "../../utils/constants";

const DATES_DEMO = {
  start: 1728536400000,
  end: 1731646800000,
};

const RangeDateField = ({
  control,
  name,
  errors = {},
  fullWidth = true,
  variant = "filled",
  sx = {},
  sxField = {},
}) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });

  const [params] = useSearchParams();
  const campaignID = params.get("campaignID");
  const isCampaignDemo = ADS_CAMPAIGNS_DEMO.includes(campaignID);

  const { t } = useTranslationApp();
  const error = getReactHookFormNestedError(errors, name);
  const now = new Date();

  const startDateTime = value.startDateTime || null;
  const endDateTime = value.endDateTime || null;
  const maxMonthsDifference = 3;

  const getMaxAndMinStartDate = () => {
    return {
      minDate: isCampaignDemo ? new Date(DATES_DEMO.start) : null,
      maxDate: isCampaignDemo ? new Date(DATES_DEMO.end) : now,
    };
  };
  const getMaxAndMinEndDate = () => {
    const maxDateByMonths = addMonths(startDateTime, maxMonthsDifference);
    const minDate = startDateTime;
    const maxDate =
      now.getTime() > maxDateByMonths.getTime() ? maxDateByMonths : now;

    return {
      minDate,
      maxDate: isCampaignDemo ? new Date(DATES_DEMO.end) : maxDate,
    };
  };

  const { minDate: minStartDate, maxDate: maxStartDate } =
    getMaxAndMinStartDate();
  const { minDate: minEndDate, maxDate: maxEndDate } = getMaxAndMinEndDate();

  const hasDateRange = startDateTime && endDateTime;

  useEffect(() => {
    if (!hasDateRange) return;
    if ((startDateTime && !endDateTime) || (!startDateTime && endDateTime))
      return;

    if (startDateTime > endDateTime) {
      const nextWeek = addDays(startDateTime, 7);
      const isBeforeNow = nextWeek.getTime() < now.getTime();
      if (isBeforeNow) {
        onChange({ ...value, endDateTime: nextWeek });
      } else {
        const differenceDays = differenceInDays(now, startDateTime);
        onChange({
          ...value,
          endDateTime: addDays(startDateTime, differenceDays),
        });
      }
    }

    if (maxEndDate?.getTime() < endDateTime?.getTime()) {
      onChange({ ...value, endDateTime: maxEndDate });
    }
    //eslint-disable-next-line
  }, [startDateTime, endDateTime]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        ...sx,
      }}
    >
      <Controller
        name="dateRange"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            onChange={(date) => {
              field.onChange({
                ...field.value,
                startDateTime: date,
              });
            }}
            label={t(START_DATE_PLACEHOLDER)}
            value={startDateTime}
            minDate={minStartDate}
            maxDate={maxStartDate}
            errors={errors}
            fullWidth={fullWidth}
            variant={variant}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={fullWidth}
                variant={variant}
                error={!!error}
                sx={sxField}
              />
            )}
          />
        )}
      />
      <Controller
        name="dateRange"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            onChange={(date) => {
              field.onChange({
                ...field.value,
                endDateTime: date,
              });
            }}
            label={t(END_DATE_PLACEHOLDER)}
            value={endDateTime}
            minDate={minEndDate}
            maxDate={maxEndDate}
            disabled={!startDateTime}
            errors={errors}
            fullWidth={fullWidth}
            variant={variant}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={fullWidth}
                variant={variant}
                error={!!error}
                sx={sxField}
              />
            )}
          />
        )}
      />
    </Box>
  );
};

export default RangeDateField;
